import {
	MenuFoldOutlined,
	MenuUnfoldOutlined,
	CommentOutlined,
	DatabaseOutlined,
	DashboardOutlined,
	ApartmentOutlined,
	ContactsOutlined,
	MessageOutlined,
	MailOutlined,
	LogoutOutlined,
	AlertOutlined,
	ArrowLeftOutlined,
	UserOutlined,
	TagOutlined,
	WalletOutlined,
	BarChartOutlined,
	TeamOutlined,
	ProfileOutlined,
	CreditCardOutlined,
	FileTextOutlined,
	HistoryOutlined,
	ApiOutlined,
} from '@ant-design/icons'
import { Layout, Menu, Button, Avatar, App, Alert, MenuProps } from 'antd'
import React, { ReactNode, useEffect, useMemo, useState } from 'react'
import { useNavigate, useLocation, Link } from 'react-router-dom'
import { Typography } from 'antd'
import query from '../../utils/query'
import { useAccountContext } from '../../hooks/context/AccountContext'
import { useGlobalContext } from '../../hooks/context/GlobalContext'
import useTryCatch from '../../hooks/useTryCatch'
import AccountRoleTag from '../Admin/Users/UsersTable/components/AccountRoleTag/AccountRoleTag'
import { BROKER_ROLES } from '@aiapp/types/account'
import styles from './DashboardLayout.module.scss'

import quantumLogo from '../../assets/whiteLogoTransparent.webp'

const { Title } = Typography
const { Header, Sider, Content } = Layout

type Props = {
	children: ReactNode
}

type MenuItem = {
	key: string
	icon: ReactNode
	label: string
	isDisabled?: (roles?: string[], usingUserId?: string) => boolean
	path?: string
	children?: {
		key: string
		icon: ReactNode
		label: string
		path: string
		isDisabled?: (roles?: string[], usingUserId?: string) => boolean
		disabled?: boolean
	}[]
}

const baseMenuItems: MenuItem[] = [
	{
		key: 'dashboard',
		icon: <DashboardOutlined />,
		label: 'Dashboard',
		path: '/',
		isDisabled: (roles?: string[], usingUserId?: string) =>
			isMenuItemDisabled('dashboard', roles, usingUserId),
	},
	{
		key: 'personaAi',
		icon: <TeamOutlined />,
		label: 'Persona AI',
		children: [
			{
				key: 'personaAi.personas',
				icon: <TeamOutlined />,
				label: 'Personas',
				path: '/personas',
			},
			{
				key: 'personaAi.integrations',
				icon: <ApiOutlined />,
				label: 'Integrations',
				path: '/personas/integrations',
			},
			{
				key: 'personaAi.conversations',
				icon: <MessageOutlined />,
				label: 'Conversations',
				path: '/personas/conversations',
				isDisabled: (roles?: string[], usingUserId?: string) =>
					isMenuItemDisabled('personaAi.conversations', roles, usingUserId),
			},
		],
	},
	{
		key: 'contexts',
		icon: <DatabaseOutlined />,
		label: 'Contexts',
		path: '/contexts',
		isDisabled: (roles?: string[], usingUserId?: string) =>
			isMenuItemDisabled('contexts', roles, usingUserId),
	},
	{
		key: 'leads',
		icon: <ContactsOutlined />,
		label: 'Leads',
		path: '/leads/groups',
		isDisabled: (roles?: string[], usingUserId?: string) =>
			isMenuItemDisabled('leads', roles, usingUserId),
	},
	{
		key: 'billing',
		icon: <WalletOutlined />,
		label: 'Billing',
		isDisabled: (roles?: string[], usingUserId?: string) =>
			isMenuItemDisabled('billing', roles, usingUserId),
		children: [
			{
				key: 'billing.overview',
				icon: <ProfileOutlined />,
				label: 'Overview',
				path: '/billing/overview',
			},
			{
				key: 'billing.paymentMethods',
				icon: <CreditCardOutlined />,
				label: 'Cards',
				path: '/billing/payment-methods',
			},
			{
				key: 'billing.history',
				icon: <HistoryOutlined />,
				label: 'History',
				path: '/billing/history',
			},
			{
				key: 'billing.company',
				icon: <FileTextOutlined />,
				label: 'Company',
				path: '/billing/company',
			},
		],
	},
]

const brokerItem: MenuItem = {
	key: 'broker',
	icon: <ApartmentOutlined />,
	label: 'Broker',
	children: [
		{
			key: 'broker.overview',
			icon: <BarChartOutlined />,
			label: 'Overview',
			path: '/broker/overview',
			isDisabled: () => true,
		},
		{
			key: 'broker.users',
			icon: <TeamOutlined />,
			label: 'Users',
			path: '/broker/users',
		},
		{
			key: 'broker.reflinks',
			icon: <TagOutlined />,
			label: 'Reflinks',
			path: '/broker/reflinks',
		},
	],
}

const adminItem: MenuItem = {
	key: 'admin',
	icon: <AlertOutlined />,
	label: 'Admin',
	children: [
		{
			key: 'admin.users',
			icon: <TeamOutlined />,
			label: 'Users',
			path: '/admin/users',
		},
		{
			key: 'admin.mails',
			icon: <MailOutlined />,
			label: 'Mails',
			path: '/admin/mails',
			isDisabled: () => true,
		},
	],
}

const onboardingItem: MenuItem = {
	key: 'admin.users',
	icon: <TeamOutlined />,
	label: 'Users',
	path: '/admin/users',
}

const chatbotItem: MenuItem = {
	key: 'chatbot',
	icon: <CommentOutlined />,
	label: 'Chatbot',
	path: '/chats',
}

const DashboardLayout = ({ children }: Props) => {
	const navigate = useNavigate()
	const location = useLocation()

	const { message } = App.useApp()
	const tryCatch = useTryCatch(message)

	const accountContext = useAccountContext()
	const globalContext = useGlobalContext()

	const emailAddress = accountContext.useSubscribe((context) => context.email)
	const roles = accountContext.useSubscribe((context) => context.roles)
	const usingUserId = accountContext.useSubscribe((context) => context.usingUserId)
	const pageTitle = globalContext.useSubscribe((context) => context.pageTitle)

	const isBroker = accountContext.useSubscribe((context) =>
		context.roles.some((role) => BROKER_ROLES.includes(role as any)),
	)
	const isOnboarding = accountContext.useSubscribe((context) =>
		context.roles.includes('onboarding'),
	)
	const isAdmin = accountContext.useSubscribe((context) => context.roles.includes('admin'))

	const [menuItems, setMenuItems] = useState<MenuItem[]>(baseMenuItems)
	const [collapsed, setCollapsed] = useState(false)

	useEffect(() => {
		if (isBroker && !usingUserId) {
			setMenuItems((menuItems) => [brokerItem, ...menuItems])
		} else {
			if (menuItems.some((item) => item.label === 'Broker')) {
				setMenuItems((menuItems) => menuItems.filter((item) => item.label !== 'Broker'))
			}
		}

		if (isOnboarding && !usingUserId) {
			setMenuItems((menuItems) => [onboardingItem, ...menuItems])
		} else {
			if (menuItems.some((item) => item.label === 'Users')) {
				setMenuItems((menuItems) => menuItems.filter((item) => item.label !== 'Users'))
			}
		}

		if (isAdmin && !usingUserId) {
			setMenuItems((menuItems) => [adminItem, ...menuItems, chatbotItem])
		} else {
			if (menuItems.some((item) => item.label === 'Admin' || item.label === 'Chatbot')) {
				setMenuItems((menuItems) =>
					menuItems.filter((item) => item.label !== 'Admin' && item.label !== 'Chatbot'),
				)
			}
		}
	}, [isAdmin, isOnboarding, isBroker, usingUserId])

	const getCurrentPageTitle = useMemo(() => {
		if (pageTitle) {
			return pageTitle
		}

		const numberRegex = /\d/
		for (const pathname of location.pathname.split('/').reverse()) {
			if (pathname.length < 2 || numberRegex.test(pathname)) {
				continue
			}
			return pathnameToPageTitle(pathname)
		}
		return 'Dashboard'
	}, [location.pathname, pageTitle])

	const onLogout = async () => {
		await tryCatch(async () => {
			await query('/account/logout', 'POST', {
				withCredentials: true,
				useCache: true,
			})
		})

		accountContext.logout()
		navigate('/')
	}

	const preparedMenuItems = useMemo(() => {
		const preparedItems: MenuProps['items'] = menuItems.map((menuItem) => ({
			key: menuItem.key,
			label: menuItem.label,
			icon: menuItem.icon,
			onClick: menuItem.path ? () => navigate(menuItem.path!) : undefined,
			disabled: menuItem.isDisabled ? menuItem.isDisabled(roles, usingUserId) : false,
			children: menuItem.children?.map((childrenItem) => ({
				key: childrenItem.key,
				label: childrenItem.label,
				icon: childrenItem.icon,
				onClick: () => navigate(childrenItem.path),
				disabled: childrenItem.isDisabled ? childrenItem.isDisabled(roles, usingUserId) : false,
			})),
		}))

		if (isAdmin || isOnboarding || isBroker) {
			preparedItems.splice(1, 0, {
				type: 'divider',
			})
		}

		return preparedItems
	}, [isAdmin, menuItems])

	return (
		<div className={styles.layout}>
			{(isAdmin || isOnboarding || isBroker) && usingUserId && (
				<div className={styles.userPreviewWrapper}>
					<Alert
						className={styles.userPreviewInfo}
						message={
							<span>
								Active user preview{' '}
								<Link to={isBroker ? '/broker/users' : '/admin/users'}>(back to your account)</Link>
							</span>
						}
						type='info'
					/>
				</div>
			)}

			<Layout>
				<Sider trigger={null} collapsible collapsedWidth={100} collapsed={collapsed}>
					<Title onClick={() => navigate('/')} level={collapsed ? 3 : 2} className={styles.title}>
						<img
							className={`${styles.logo} ${collapsed ? styles.collapsedLogo : styles.fullSizeLogo}`}
							src={quantumLogo}
							alt='Quantum Neuron logo'
						/>
					</Title>

					<Menu
						theme='dark'
						mode='inline'
						title='Quantum Neuron'
						selectedKeys={[pathnameToMenuKey(location.pathname, roles)]}
						defaultOpenKeys={[pathnameToMenuKey(location.pathname, roles).split('.')[0]]}
						items={preparedMenuItems}
					/>
				</Sider>

				<Layout>
					<Header className={styles.header}>
						<div className={styles.leftSize}>
							<Button
								type='text'
								icon={
									collapsed ? (
										<MenuUnfoldOutlined className={styles.icon} />
									) : (
										<MenuFoldOutlined className={styles.icon} />
									)
								}
								onClick={() => setCollapsed(!collapsed)}
								className={styles.headerIcon}
							/>
							<Button
								type='text'
								icon={<ArrowLeftOutlined className={styles.icon} />}
								onClick={() => navigate(-1)}
								className={styles.headerIcon}
							/>
							<Title className={styles.navTitle} level={4}>
								{getCurrentPageTitle}
							</Title>
						</div>

						<div className={styles.rightSide}>
							<div className={styles.accountRoles}>
								{roles.map((role, index) => (
									<AccountRoleTag key={`${index}-${role}`} role={role} />
								))}
							</div>

							<div className={styles.emailAddress}>{emailAddress}</div>
							{isAdmin && <span className={styles.adminRole}>(Admin)</span>}
							<Avatar className={styles.userAvatar} size={32} icon={<UserOutlined />} />
							<Button className={styles.logoutButton} type='text' onClick={onLogout}>
								Logout <LogoutOutlined className={styles.logoutIcon} />
							</Button>
						</div>
					</Header>

					<Content className={styles.content}>{children}</Content>
				</Layout>
			</Layout>
		</div>
	)
}

// TODO: Do it better
const pathnameToMenuKey = (pathname: string, roles: string[]): string => {
	if (pathname.includes('offers')) {
		if (roles.includes('admin') || roles.includes('onboarding')) {
			return 'admin.users'
		}
		if (roles.includes('broker')) {
			return 'broker.users'
		}
	}

	if (pathname.includes('admin')) {
		if (pathname.includes('admin/users')) {
			return 'admin.users'
		}
		if (pathname.includes('admin/mails')) {
			return 'admin.mails'
		}
	}

	if (pathname.includes('broker')) {
		if (pathname.includes('broker/overview')) {
			return 'broker.overview'
		}
		if (pathname.includes('broker/users') || pathname.includes('offers')) {
			return 'broker.users'
		}
		if (pathname.includes('broker/reflinks')) {
			return 'broker.reflinks'
		}
	}

	if (pathname.includes('billing')) {
		if (pathname.includes('billing/overview')) {
			return 'billing.overview'
		}
		if (pathname.includes('billing/history')) {
			return 'billing.history'
		}
		if (pathname.includes('billing/payment-methods')) {
			return 'billing.paymentMethods'
		}
		if (pathname.includes('billing/company')) {
			return 'billing.company'
		}
	}

	if (pathname.includes('personas/conversation')) {
		return 'personaAi.conversations'
	}
	if (pathname.includes('personas/integration')) {
		return 'personaAi.integrations'
	}
	if (pathname.includes('persona')) {
		return 'personaAi.personas'
	}
	if (pathname.includes('leads')) {
		return 'leads'
	}
	if (pathname.includes('context')) {
		return 'contexts'
	}
	if (pathname.includes('chat')) {
		return 'chatbot'
	}
	return 'dashboard' // Dashboard
}

const pathnameToPageTitle = (pathname: string) => {
	if (pathname === 'context') {
		return 'Role - Knowledge base'
	}
	if (pathname === 'playground') {
		return 'Role - Playground'
	}
	if (pathname === 'connections') {
		return 'Role - Connections'
	}
	const firstLetter = pathname.charAt(0).toUpperCase()
	return firstLetter + pathname.slice(1)
}

const ONBOARDING_DISALLOW_MENU_ITEMS = ['billing']
const BROKER_DISALLOW_MENU_ITEMS = ['dashboard', 'leads', 'billing', 'personaAi.conversations']

const isMenuItemDisabled = (key: string, roles: string[] = [], usingUserId?: string): boolean => {
	if (usingUserId) {
		const isAdmin = roles.some((role) => role === 'admin')
		const isOnboarding = roles.some((role) => role === 'onboarding')
		const isBroker = roles.some((role) => BROKER_ROLES.includes(role as any))

		if (isAdmin) {
			return false
		}
		if (isOnboarding) {
			return ONBOARDING_DISALLOW_MENU_ITEMS.includes(key)
		}
		if (isBroker) {
			return BROKER_DISALLOW_MENU_ITEMS.includes(key)
		}
		return true
	}
	return false
}

export default DashboardLayout
