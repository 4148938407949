import { ReactNode } from 'react'
import styles from './FormRow.module.scss'

type Props = {
	children: ReactNode
	columns?: number[]
	className?: string
}

const FormRow = ({ children, columns = [1, 1], className = '' }: Props) => {
	return (
		<div
			style={{ gridTemplateColumns: columns.map((column) => `${column}fr`).join(' ') }}
			className={`${styles.formRow} ${className}`}
		>
			{children}
		</div>
	)
}

export default FormRow
