import { useAccountContext } from '../hooks/context/AccountContext'
import useAsyncEffect from '../hooks/useAsyncEffect'
import query from '../utils/query'
import { Account } from '../types/Account'
import { getCookies } from '@aiapp/utils'
import useTryCatch from '../hooks/useTryCatch'
import { App } from 'antd'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const SessionManager = () => {
	const cookies = getCookies()
	const navigate = useNavigate()
	const accountContext = useAccountContext()

	const { message } = App.useApp()
	const tryCatch = useTryCatch(message)

	const accountId = accountContext.useSubscribe((account) => account.id)
	const activeAccount = accountContext.useSubscribe((account) => account.active)
	const accountLoaded = accountContext.useSubscribe((account) => account.loaded)

	useAsyncEffect(async () => {
		if (cookies['hasRefresh'] === '1' && !accountId) {
			await tryCatch(
				async () => {
					const account = await query<Account>('/account/refresh', 'POST', {
						withCredentials: true,
					})
					accountContext.updateAccount(account)
				},
				undefined,
				{ hideMessage: true },
			)
		}
		accountContext.loaded()
	}, [accountId, cookies['hasRefresh']])

	useEffect(() => {
		if (accountLoaded && activeAccount === false) {
			navigate('/onboarding')
		}
	}, [activeAccount, accountLoaded])

	return <></>
}

export default SessionManager
