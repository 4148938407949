import Joi from 'joi'
import { usingUserIdSchema, uuidIdSchema } from '../../global'
import { assistantRoleBaseSchema } from '../assistantRole'

const helpdeskRoleSchema = {
	...assistantRoleBaseSchema,
	type: Joi.string().valid('helpdesk').required(),
}

export const createHelpdeskRoleSchema = {
	assistantId: helpdeskRoleSchema.assistantId.required(),
	name: helpdeskRoleSchema.name.required(),
	additionalInformation: helpdeskRoleSchema.additionalInformation.optional(),
	naturalizationBooster: helpdeskRoleSchema.naturalizationBooster.optional(),
	redirectConditions: helpdeskRoleSchema.redirectConditions.optional(),

	type: helpdeskRoleSchema.type.required(),
	disabledEmoji: helpdeskRoleSchema.disabledEmoji.required(),
	preferredEmoji: helpdeskRoleSchema.preferredEmoji.optional(),
	followupInterval: helpdeskRoleSchema.followupInterval.optional(),
	maxFollowups: helpdeskRoleSchema.maxFollowups.optional(),
	conversationStyle: helpdeskRoleSchema.conversationStyle.required(),
	responseLength: helpdeskRoleSchema.responseLength.required(),
	...usingUserIdSchema,
}

export const updateHelpdeskRoleSchema = {
	id: uuidIdSchema.required(),
	assistantId: helpdeskRoleSchema.assistantId.required(),
	name: helpdeskRoleSchema.name.optional(),
	additionalInformation: helpdeskRoleSchema.additionalInformation.optional(),
	naturalizationBooster: helpdeskRoleSchema.naturalizationBooster.optional(),
	redirectConditions: helpdeskRoleSchema.redirectConditions.optional(),

	type: helpdeskRoleSchema.type.optional(),
	disabledEmoji: helpdeskRoleSchema.disabledEmoji.optional(),
	preferredEmoji: helpdeskRoleSchema.preferredEmoji.optional(),
	followupInterval: helpdeskRoleSchema.followupInterval.optional(),
	maxFollowups: helpdeskRoleSchema.maxFollowups.optional(),
	conversationStyle: helpdeskRoleSchema.conversationStyle.optional(),
	responseLength: helpdeskRoleSchema.responseLength.optional(),
}
