import { Progress } from 'antd'
import Loading from '../../../../components/Loading/Loading'
import React from 'react'
import { PublicAnalytics } from '@aiapp/types/analytics'
import styles from './Header.module.scss'

type Props = {
	analytics: PublicAnalytics | undefined
	loading: boolean
}

const CONVERSATIONS_LIMIT = 30 as const

const Header = ({ analytics, loading }: Props) => {
	return (
		<div>
			<div className={styles.quoteWrapper}>
				<div className={styles.quote}>
					<span>
						❝Those who can imagine anything, can create the <b>impossible</b>.❞
					</span>
					<span className={styles.quoteSignature}>― Alan Turing</span>
				</div>
			</div>

			<div className={styles.currentConversationContainer}>
				{!loading && !!analytics ? (
					<>
						<div className={styles.currentConversation}>
							<div>
								<div className={styles.currentConversationHeading}>Conversations:</div>
								<Progress
									size={200}
									type='dashboard'
									percent={
										+((analytics.conversationsAmount * 100) / CONVERSATIONS_LIMIT).toFixed(2)
									}
									strokeColor={{ '0%': '#87d068', '50%': '#ffe58f', '100%': '#ffccc7' }}
								/>
							</div>

							<div className={styles.currentConversationTextWrapper}>
								<div className={styles.currentConversationText}>
									{analytics.conversationsAmount}
								</div>
								<div className={styles.currentConversationLimit}>/ {CONVERSATIONS_LIMIT} limit</div>
							</div>
						</div>
					</>
				) : (
					<Loading size={64} />
				)}
			</div>
		</div>
	)
}

export default Header
