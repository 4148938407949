import React, { ReactNode, useState } from 'react'
import { Link } from 'react-router-dom'
import { Button, Layout, App, Tooltip, Tag, Pagination, PaginationProps } from 'antd'
import { DeleteOutlined, SyncOutlined } from '@ant-design/icons'
import { formatDate } from '@aiapp/utils/dates'
import { Conversation, GetUserConversationsResponse } from '@aiapp/types/assistant/conversation'
import useTryCatch from '../../../hooks/useTryCatch'
import useAsyncEffect from '../../../hooks/useAsyncEffect'
import query from '../../../utils/query'
import Loading, { InlineLoading } from '../../../components/Loading/Loading'
import CardTitle from '../../../components/CardTitle/CardTitle'
import EmptyData from '../../../components/EmptyData/EmptyData'
import CardItem from '../../../components/CardItem/CardItem'
import { useAccountContext } from '../../../hooks/context/AccountContext'
import styles from './Conversations.module.scss'

const { Header } = Layout

const Conversations = () => {
	const { message } = App.useApp()
	const tryCatch = useTryCatch(message)

	const accountContext = useAccountContext()
	const usingUserId = accountContext.useSubscribe((account) => account.usingUserId)

	const [conversations, setConversations] = useState<Conversation[]>([])
	const [conversationsCount, setConversationsCount] = useState<number>(0)
	const [selectedConversationIds, setSelectedConversationIds] = useState<string[]>([])
	const [loading, setLoading] = useState<boolean>(true)

	const [pagination, setPagination] = useState<{ page: number; perPage: number }>({
		page: 1,
		perPage: 8,
	})

	const onLoadConversation = async () => {
		setLoading(true)
		await tryCatch(async () => {
			const resConversations = await query<GetUserConversationsResponse>(
				'/assistant/conversation/all',
				'GET',
				{
					withCredentials: true,
					params: {
						page: pagination.page,
						perPage: pagination.perPage,
						usingUserId,
					},
				},
			)
			setConversations(resConversations.conversations)
			setConversationsCount(resConversations.count)
		})
		setLoading(false)
	}

	useAsyncEffect(onLoadConversation, [pagination])

	const deleteConversations = async (conversationIds: string[]) => {
		for (const conversationId of conversationIds) {
			await tryCatch(
				async () => {
					await query('/assistant/conversation/delete', 'POST', {
						data: { id: conversationId },
						withCredentials: true,
					})

					message.open({
						type: 'success',
						content: 'Conversation was deleted',
					})
				},
				undefined,
				{ message: 'Error while deleting conversation' },
			)
		}

		setSelectedConversationIds([])
		await onLoadConversation()
	}

	const selectConversation = (conversationId: string) => {
		const hasSelectedAssistantId = selectedConversationIds.some((id) => id === conversationId)

		if (hasSelectedAssistantId) {
			setSelectedConversationIds((ids) => ids.filter((id) => id !== conversationId))
		} else {
			setSelectedConversationIds([...selectedConversationIds, conversationId])
		}
	}

	const setPaginationHandler = (page: number, pageSize: number) => {
		setPagination({
			page,
			perPage: pageSize,
		})
	}

	if (loading) {
		return <Loading />
	}

	return (
		<div className={styles.container}>
			<Header className={styles.header}>
				<div className={styles.headerElements}>
					<div className={styles.conversationsAmount}>
						<b>{conversationsCount}</b> {conversationsCount > 1 ? 'conversations' : 'conversation'}
					</div>
				</div>

				<div className={styles.headerElements}>
					{!!selectedConversationIds.length && (
						<>
							<Button
								icon={<DeleteOutlined />}
								danger
								onClick={() => deleteConversations(selectedConversationIds)}
							>
								Delete selected{' '}
								{selectedConversationIds.length > 1 ? 'conversations' : 'conversation'} (
								{selectedConversationIds.length})
							</Button>
						</>
					)}

					<Tooltip trigger={['hover', 'focus']} title='Refresh'>
						<Button
							className={styles.headerRefresh}
							onClick={onLoadConversation}
							shape='circle'
							icon={<SyncOutlined />}
						/>
					</Tooltip>
				</div>
			</Header>

			<div className={styles.conversationsContainer}>
				{!!conversations.length && (
					<div className={styles.conversations}>
						{conversations.map((conversation) => (
							<CardItem
								key={conversation.id}
								itemId={conversation.id}
								selectedItems={selectedConversationIds}
								selectItem={() => selectConversation(conversation.id)}
								cardTitle={
									<CardTitle
										id={conversation.id}
										name={`User ID: ${conversation.userProviderId!}`}
										onDelete={(id: string) => deleteConversations([id])}
									/>
								}
								labels={[
									{
										label: 'Provider',
										value: conversation.integration?.provider,
									},
									{
										label: 'Status',
										value: getConversationStatus(conversation),
									},
									{
										label: 'Integration',
										value: conversation.integration?.id ? (
											<Link
												to={`/personas/integration?integrationId=${conversation.integration.id}`}
											>
												{conversation.integration?.name}
											</Link>
										) : (
											<span className={styles.dangerText}>No integration</span>
										),
									},
									{
										label: 'Last message read',
										value:
											conversation.integration?.provider === 'gmail'
												? conversation.read
													? 'Yes'
													: 'No'
												: undefined,
									},
									{
										label: 'Last read date',
										value:
											conversation.integration?.provider === 'gmail' &&
											conversation.lastReadAt &&
											`${formatDate(conversation.lastReadAt, true)}`,
									},
									{
										label: 'Next response date',
										value:
											conversation.responseAt && `${formatDate(conversation.responseAt, true)}`,
									},
									{
										label: 'Last user activity date',
										value:
											conversation.lastUserActivityAt &&
											formatDate(conversation.lastUserActivityAt, true),
									},
									{
										label: 'Last activity date',
										value: formatDate(conversation.lastActivityAt, true),
									},
									{
										label: 'Created date',
										value: formatDate(conversation.createdAt),
									},
								]}
							>
								<Button
									href={`/personas/conversation?conversationId=${conversation.id}`}
									onClick={(e) => {
										e.stopPropagation()
									}}
								>
									Open
								</Button>
							</CardItem>
						))}
					</div>
				)}

				{!conversations.length && <EmptyData description='Empty conversation list' />}

				{!!conversations.length && (
					<div className={styles.pagination}>
						<Pagination
							showTotal={showTotal}
							onChange={setPaginationHandler}
							pageSize={pagination.perPage}
							current={pagination.page}
							total={conversationsCount}
						/>
					</div>
				)}
			</div>
		</div>
	)
}

const showTotal: PaginationProps['showTotal'] = (total) => `Total ${total} items`

export const getConversationStatus = (conversation: Conversation): ReactNode | string => {
	if (conversation.ended) {
		return <Tag color='#5cb85c'>Completed</Tag>
	}
	if (conversation.redirected) {
		return <Tag color='#f0ad4e'>Redirected</Tag>
	}
	if (conversation.userActivityTimeout) {
		return <Tag color='#292b2c'>After 24h window</Tag>
	}
	if (conversation.error) {
		return <Tag color='#d9534f'>Error</Tag>
	}
	if (conversation.processing) {
		return (
			<Tag color='#007bff'>
				<InlineLoading iconClassName={styles.loading} color='#fff' size={10} />
				<span>Responding</span>
			</Tag>
		)
	}
	if (conversation.responseAt) {
		return <Tag>Waiting to response time</Tag>
	}
	return <Tag>Waiting for user response</Tag>
}

export default Conversations
