import Joi from 'joi'
import { idSchema, urlSchema, uuidIdSchema } from '../global'
import { INTEGRATION_PROVIDERS } from '../../types/integration'
import {
	CONVERSATION_STYLES,
	FOLLOWUP_INTERVAL,
	RESPONSE_LENGTHS,
} from '@aiapp/types/assistant/assistantRole'

export const assistantRoleBaseSchema = {
	assistantId: uuidIdSchema,
	name: Joi.string().min(3).max(120),
	additionalInformation: Joi.string().allow('').max(5000),
	disabledEmoji: Joi.boolean().required(),
	preferredEmoji: Joi.string().allow(''),
	followupInterval: Joi.number()
		.valid(...FOLLOWUP_INTERVAL)
		.allow(null),
	maxFollowups: Joi.number().integer().allow(null),
	conversationStyle: Joi.string().valid(...CONVERSATION_STYLES),
	responseLength: Joi.string().valid(...RESPONSE_LENGTHS),
	naturalizationBooster: Joi.boolean(),
	redirectConditions: Joi.array()
		.items(
			Joi.object({
				condition: Joi.string().min(10).max(500).required(),
				message: Joi.string().min(3).max(500).allow(null).optional(),
			}).required(),
		)
		.max(5)
		.allow(null),
}

export const GoalUrlSchema = Joi.object({
	url: urlSchema.required(),
	context: Joi.string().min(6).max(500).required(),
})

export const getAssistantRoleRequestSchema = {
	id: uuidIdSchema.required(),
}
export const getAssistantRolesRequestSchema = {
	assistantId: uuidIdSchema.required(),
}
export const getAssistantRolesByIntegrationRequestSchema = {
	integrationId: uuidIdSchema.required(),
}
export const getAssistantRolesByLeadsGroupRequestSchema = {
	leadsGroupId: uuidIdSchema.required(),
}

export const deleteAssistantRoleRequestSchema = {
	id: uuidIdSchema.required(),
}

export const updateAssistantRoleContextRequestSchema = {
	id: uuidIdSchema.required(),
	contextIds: Joi.array().items(idSchema.optional()).max(15).optional(),
}

export const updateAssistantRoleLeadsGroupsRequestSchema = {
	id: uuidIdSchema.required(),
	leadsGroupIds: Joi.array().items(uuidIdSchema.optional()).max(5).optional(),
}

export const updateAssistantRoleIntegrationRequestSchema = {
	id: uuidIdSchema.required(),
	integrations: Joi.array()
		.items(
			Joi.object({
				id: uuidIdSchema.required(),
				provider: Joi.string()
					.valid(...INTEGRATION_PROVIDERS)
					.required(),
			}).max(15),
		)
		.required(),
}
