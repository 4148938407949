import styles from './RoleHeader.module.scss'

type Props = {
	avatar: string
	name: string
	description: string
}

const RoleHeader = ({ avatar, name, description }: Props) => {
	return (
		<div className={styles.roleHeader}>
			<div className={styles.roleHeaderContent}>
				<img className={styles.avatar} alt='Role avatar' src={avatar} />
				<div>
					<div className={styles.roleName}>{name}</div>
					<div className={styles.roleDescription}>{description}</div>
				</div>
			</div>
		</div>
	)
}

export default RoleHeader
