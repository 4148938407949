import { loginSchema } from '@aiapp/schemas/account'
import { Link, useNavigate } from 'react-router-dom'
import { useAccountContext } from '../../hooks/context/AccountContext'
import { LockOutlined, UserOutlined } from '@ant-design/icons'
import { useEffect, useState } from 'react'
import query from '../../utils/query'
import { Account } from '../../types/Account'
import useTryCatch, { DEFAULT_ERROR_MESSAGE } from '../../hooks/useTryCatch'
import { App, Button, Form, Input } from 'antd'
import { formValidate } from '../../utils/validation'
import LoginWrapper from '../../components/LoginWrapper/LoginWrapper'
import styles from './Login.module.scss'

type LoginRequest = {
	email: string
	password: string
}

const Login = () => {
	const navigate = useNavigate()
	const accountContext = useAccountContext()

	const { message } = App.useApp()
	const tryCatch = useTryCatch(message)

	const accountId = accountContext.useSubscribe((account) => account.id)
	const activeAccount = accountContext.useSubscribe((account) => account.active)

	const [loginLoading, setLoginLoading] = useState<boolean>(false)

	useEffect(() => {
		if (accountId && activeAccount) {
			navigate('/')
		}
	}, [accountId, activeAccount])

	const checkFormValidate = (values: LoginRequest): boolean => {
		const validate = formValidate(values, loginSchema)
		return validate ? !Object.keys(validate).length : true
	}

	const onSubmit = async (values: LoginRequest) => {
		if (!checkFormValidate(values)) {
			return
		}

		setLoginLoading(true)
		await tryCatch(
			async () => {
				const response = await query<Account>('/account/login', 'POST', {
					data: values,
					withCredentials: true,
				})
				accountContext.updateAccount(response)
			},
			(err: any) => {
				setLoginLoading(false)
				if (err.message.trim() === 'Account is inactive') {
					accountContext.updateAccount({
						loaded: true,
						active: false,
					})
				} else {
					message.open({
						type: 'error',
						content: err.message ?? DEFAULT_ERROR_MESSAGE,
					})
				}
			},
			{ hideMessage: true },
		)
	}

	return (
		<LoginWrapper width={500}>
			<Form
				autoComplete='true'
				onFinish={onSubmit}
				labelCol={{ span: 4 }}
				wrapperCol={{ span: 14 }}
				layout='vertical'
			>
				<Form.Item
					className={styles.formItem}
					rules={[
						{ required: true, message: 'Proszę wypełnić adres e-mail' },
						{ type: 'email', message: 'Niewłaściwy format adresu e-mail' },
						{ message: 'Adres e-mail musi mieć minimum 3 znaki', min: 3 },
						{
							message: 'Adres e-mail może mieć maksymalnie 250 znaków',
							max: 250,
						},
					]}
					label='E-mail'
					name='email'
					hasFeedback
				>
					<Input
						autoFocus
						maxLength={250}
						prefix={<UserOutlined />}
						size='large'
						placeholder='Adres e-mail'
					/>
				</Form.Item>

				<Form.Item
					rules={[
						{ required: true, message: 'Proszę wprowadzić hasło' },
						{ message: 'Hasło musi mieć minimum 8 znaków', min: 8 },
					]}
					className={styles.formItem}
					label='Hasło'
					name='password'
					hasFeedback
				>
					<Input.Password
						prefix={<LockOutlined />}
						size='large'
						type='password'
						placeholder='Hasło'
					/>
				</Form.Item>

				<div className={styles.buttons}>
					<Button
						loading={loginLoading}
						size='large'
						type='primary'
						htmlType='submit'
						className={styles.submitButton}
					>
						Zaloguj się
					</Button>
					<div>
						lub <Link to='/signup'>zarejestruj się teraz!</Link>
					</div>
				</div>
			</Form>
		</LoginWrapper>
	)
}

export default Login
