import Joi from 'joi'
import { createAccountSchema } from '@aiapp/schemas/account'
import { formValidate } from '../../utils/validation'
import { Link, useNavigate } from 'react-router-dom'
import { useAccountContext } from '../../hooks/context/AccountContext'
import { useEffect, useState } from 'react'
import { CreateAccountRequest, TokenInfo } from '@aiapp/types/account'
import query from '../../utils/query'
import useTryCatch, { DEFAULT_ERROR_MESSAGE } from '../../hooks/useTryCatch'
import { App, Button, Form, Input } from 'antd'
import { LockOutlined, MailOutlined, UserOutlined, PhoneOutlined } from '@ant-design/icons'
import FormRow from '../../components/FormRow/FormRow'
import LoginWrapper from '../../components/LoginWrapper/LoginWrapper'
import useQuery from '../../hooks/useQuery'
import useAsyncEffect from '../../hooks/useAsyncEffect'
import { Reflink } from '@aiapp/types/reflink'
import styles from './Signup.module.scss'
import Loading from '../../components/Loading/Loading'

type SignupRequest = CreateAccountRequest & {
	repeatPassword: string
}

const Signup = () => {
	const navigate = useNavigate()
	const accountContext = useAccountContext()

	const reflinkCode = useQuery().get('ref')
	const brokerQuery = useQuery().get('broker')

	const { message } = App.useApp()
	const tryCatch = useTryCatch(message)

	const accountId = accountContext.useSubscribe((account) => account.id)
	const activeAccount = accountContext.useSubscribe((account) => account.active)

	const [reflinkDetails, setReflinkDetails] = useState<Reflink>()
	const [signupLoading, setSignupLoading] = useState<boolean>(false)
	const [loading, setLoading] = useState<boolean>(true)

	useEffect(() => {
		if (accountId && activeAccount) {
			navigate('/')
		}
	}, [accountId, activeAccount])

	useAsyncEffect(async () => {
		if (!reflinkCode) {
			setLoading(false)
			return
		}

		setLoading(true)
		await tryCatch(async () => {
			const reflinkDetails = await query<Reflink>('/reflink/get', 'GET', {
				params: {
					code: reflinkCode,
				},
			})

			if (!reflinkDetails.used) {
				setReflinkDetails(reflinkDetails)
			}
		})
		setLoading(false)
	}, [reflinkCode])

	const checkFormValidate = (values: SignupRequest): boolean => {
		const validate = formValidate(values, signupSchema)
		return validate ? !Object.keys(validate).length : true
	}

	const onSubmit = async (values: SignupRequest) => {
		if (!checkFormValidate(values)) {
			return
		}

		setSignupLoading(true)
		await tryCatch(
			async () => {
				const response = await query<TokenInfo>('/account/signup', 'POST', {
					data: {
						email: values.email,
						password: values.password,
						firstName: values.firstName,
						lastName: values.lastName,
						phone: values.phone,
						brokerEmail: values.brokerEmail,
						reflinkCode: reflinkDetails?.code,
					},
				})
				accountContext.updateAccount(response)
			},
			(err: any) => {
				setSignupLoading(false)
				if (err.message.trim() === 'Account is inactive') {
					accountContext.updateAccount({
						loaded: true,
						active: false,
					})
				} else {
					message.open({
						type: 'error',
						content: err.message ?? DEFAULT_ERROR_MESSAGE,
					})
				}
			},
			{ hideMessage: false },
		)
	}

	if (loading) {
		return <Loading size={40} />
	}

	return (
		<LoginWrapper width={650}>
			<Form
				autoComplete='true'
				onFinish={onSubmit}
				labelCol={{ span: 4 }}
				wrapperCol={{ span: 14 }}
				initialValues={{ brokerEmail: brokerQuery ?? undefined }}
				layout='vertical'
			>
				<Form.Item
					className={styles.formItem}
					rules={[
						{ required: true, message: 'Proszę wypełnić adres e-mail' },
						{ type: 'email', message: 'Niewłaściwy format adresu e-mail' },
						{ message: 'Adres e-mail musi mieć minimum 3 znaki', min: 3 },
						{
							message: 'Adres e-mail może mieć maksymalnie 250 znaków',
							max: 250,
						},
					]}
					label='Adres e-mail'
					name='email'
					hasFeedback
				>
					<Input
						autoFocus
						maxLength={250}
						prefix={<MailOutlined />}
						size='large'
						placeholder='Adres e-mail'
					/>
				</Form.Item>

				<FormRow>
					<Form.Item
						className={styles.formItem}
						rules={[
							{ required: true, message: 'Proszę uzupełnić imię' },
							{ message: 'Imię musi mieć minimum 3 znaki', min: 3 },
							{
								message: 'Imię może mieć maksymalnie 100 znaków',
								max: 100,
							},
						]}
						label='Imię'
						name='firstName'
					>
						<Input maxLength={100} prefix={<UserOutlined />} size='large' placeholder='Adam' />
					</Form.Item>

					<Form.Item
						className={styles.formItem}
						rules={[
							{ required: true, message: 'Proszę uzupełnić nazwisko' },
							{ message: 'Nazwisko musi mieć minimum 3 znaki', min: 3 },
							{
								message: 'Nazwisko może mieć maksymalnie 100 znaków',
								max: 100,
							},
						]}
						label='Nazwisko'
						name='lastName'
					>
						<Input maxLength={100} prefix={<UserOutlined />} size='large' placeholder='Kowalski' />
					</Form.Item>
				</FormRow>

				<Form.Item
					className={styles.formItem}
					rules={[
						{ required: true, message: 'Proszę uzupełnić numer telefonu' },
						{ message: 'Numer telefonu musi mieć minimum 9 znaków', min: 9 },
						{
							message: 'Numer telefonu może mieć maksymalnie 20 znaków',
							max: 20,
						},
					]}
					label='Numer telefonu'
					name='phone'
				>
					<Input
						maxLength={20}
						prefix={<PhoneOutlined />}
						size='large'
						placeholder='+48 100 200 300'
					/>
				</Form.Item>

				<FormRow>
					<Form.Item
						className={styles.formItem}
						rules={[
							{ required: true, message: 'Proszę wprowadzić hasło' },
							{ message: 'Hasło musi mieć minimum 8 znaków', min: 8 },
						]}
						label='Hasło'
						name='password'
						hasFeedback
					>
						<Input.Password
							prefix={<LockOutlined />}
							size='large'
							type='password'
							placeholder='Hasło'
						/>
					</Form.Item>

					<Form.Item
						className={styles.formItem}
						name='repeatPassword'
						label='Potwierdź hasło'
						dependencies={['password']}
						hasFeedback
						rules={[
							{
								required: true,
								message: 'Proszę potwierdzić hasło',
							},
							({ getFieldValue }) => ({
								validator(_, value) {
									if (!value || getFieldValue('password') === value) {
										return Promise.resolve()
									}
									return Promise.reject(new Error('Podane hasła się różnią!'))
								},
							}),
						]}
					>
						<Input.Password
							prefix={<LockOutlined />}
							size='large'
							type='password'
							placeholder='Powtórz hasło'
						/>
					</Form.Item>
				</FormRow>

				{!reflinkDetails?.id && (
					<Form.Item
						className={styles.formItem}
						rules={[
							{ type: 'email', message: 'Nieprawidłowy format maila brokera' },
							{ message: 'Mail brokera musi mieć minimum 3 znaki', min: 3 },
							{
								message: 'Mail brokera może mieć maksymalnie 250 znaków',
								max: 250,
							},
						]}
						label='Mail brokera'
						name='brokerEmail'
						hasFeedback
					>
						<Input
							disabled={!!brokerQuery}
							maxLength={250}
							size='large'
							placeholder='Mail brokera'
						/>
					</Form.Item>
				)}

				<div className={styles.buttons}>
					<Button
						loading={signupLoading}
						size='large'
						type='primary'
						htmlType='submit'
						className={styles.submitButton}
					>
						Zarejestruj się
					</Button>
					<div>
						lub <Link to='/login'>zaloguj się teraz!</Link>
					</div>
				</div>
			</Form>
		</LoginWrapper>
	)
}

const signupSchema = {
	...createAccountSchema,
	repeatPassword: Joi.string().valid(Joi.ref('password')).required(),
}

export default Signup
