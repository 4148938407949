import React, { useMemo, useState } from 'react'
import { App, Button, Tooltip } from 'antd'
import {
	CopyOutlined,
	FileOutlined,
	RedoOutlined,
	DownloadOutlined,
	InfoCircleOutlined,
	DeleteOutlined,
	StopOutlined,
	SendOutlined,
	LikeOutlined,
	DislikeOutlined,
} from '@ant-design/icons'
import { messageRole } from '@aiapp/utils/consts'
import query from '../../../../../utils/query'
import useTryCatch from '../../../../../hooks/useTryCatch'
import { downloadFileFromUrl } from '../../../../../utils/helpers'
import { useChatContext } from '../../../../../hooks/context/ChatContext'
import { attachedFilesToIds, messageContentIsInStatus } from '@aiapp/utils/assistant'
import { InlineLoading } from '../../../../Loading/Loading'
import { PublicMessage } from '../../../../../types/Chat'
import {
	ConversationMessageRating,
	RatedConversationMessage,
} from '@aiapp/types/assistant/ratedConversationMessage'
import useAsyncEffect from '../../../../../hooks/useAsyncEffect'
import { useAccountContext } from '../../../../../hooks/context/AccountContext'
import styles from './MessageTooltip.module.scss'

type Props = {
	chatMessage: PublicMessage
	showTooltip: boolean
	position: 'top' | 'bottom'
	onResendMessage?: () => Promise<void>
	onDeleteAssistantMessage?: () => Promise<void>
	onCancelAssistantMessage?: () => Promise<void>
	onSetMessageInfo: (message: PublicMessage) => void
	attachFilesToMessage: (files: Array<string | File>) => Promise<string[]>
}

const MessageTooltip = ({
	chatMessage,
	showTooltip,
	position,
	onResendMessage,
	onDeleteAssistantMessage,
	onCancelAssistantMessage,
	onSetMessageInfo,
	attachFilesToMessage,
}: Props) => {
	const { message } = App.useApp()
	const tryCatch = useTryCatch(message)

	const accountContext = useAccountContext()
	const isAdmin = accountContext.useSubscribe((context) => context.roles.includes('admin'))

	const chatContext = useChatContext()
	const isAssistant = chatContext.useSubscribe((context) => context.chat?.isAssistant)
	const isPlayground = chatContext.useSubscribe(
		(context) => context.currentChatSession?.provider === 'playground',
	)
	const chatFiles = chatContext.useSubscribe((context) => context.chatFiles)

	const [messageRating, setMessageRating] = useState<ConversationMessageRating | undefined | null>(
		undefined,
	)

	const [copyTextButtonText, setCopyTextButtonText] = useState<string>('Copy text')
	const [downloadingFileLoading, setDownloadingFileLoading] = useState<boolean>(false)
	const [publishMessageLoading, setPublishMessageLoading] = useState<boolean>(false)
	const [cancelMessageLoading, setCancelMessageLoading] = useState<boolean>(false)
	const [deleteMessageLoading, setDeleteMessageLoading] = useState<boolean>(false)
	const [retryMessageLoading, setRetryMessageLoading] = useState<boolean>(false)

	const loadConversationMessageRating = async () => {
		await tryCatch(async () => {
			const conversationMessageRating = await query<RatedConversationMessage | undefined>(
				'/assistant/message/rating',
				'GET',
				{
					withCredentials: true,
					params: {
						conversationMessageId: chatMessage.id,
					},
				},
			)
			if (conversationMessageRating) {
				setMessageRating(conversationMessageRating.rating)
			} else {
				setMessageRating(null)
			}
		})
	}

	useAsyncEffect(async () => {
		if (
			!showTooltip ||
			messageRating !== undefined ||
			chatMessage.role === messageRole.USER ||
			!isAssistant
		) {
			return
		}
		await loadConversationMessageRating()
	}, [isAssistant, showTooltip, messageRating, chatMessage.role])

	const copyText = async () => {
		await navigator.clipboard.writeText(chatMessage.content)

		setCopyTextButtonText('Copied!')
		setTimeout(() => setCopyTextButtonText('Copy text'), 2500)
	}

	const downloadFilesHandler = async () => {
		const messageFileIds = attachedFilesToIds(chatMessage.attachedFileIds)

		if (!messageFileIds.length || downloadingFileLoading) {
			return
		}

		setDownloadingFileLoading(true)

		for (const fileId of messageFileIds) {
			const fileData = chatFiles.find((file) => file.id === fileId)

			if (fileData) {
				downloadFileFromUrl(fileData.url, fileData.name)
			} else {
				message.open({
					type: 'error',
					content: 'Error while downloading file',
				})
			}
		}

		setDownloadingFileLoading(false)
	}

	const attachFileIdsFromMessage = async () => {
		if (!attachedFilesToIds(chatMessage.attachedFileIds).length) {
			return
		}
		await attachFilesToMessage(attachedFilesToIds(chatMessage.attachedFileIds))
	}

	const publishProviderMessageHandler = async () => {
		if (!isAssistant || !!chatMessage.sentByProvider || publishMessageLoading) {
			return
		}

		setPublishMessageLoading(true)
		await tryCatch(async () => {
			await query('/integration/publish', 'POST', {
				withCredentials: true,
				data: {
					conversationMessageId: chatMessage.id,
				},
			})
		})
		setPublishMessageLoading(false)
	}

	const rateAssistantMessageHandler = async (rating: ConversationMessageRating | undefined) => {
		if (!isAssistant) {
			return
		}

		await tryCatch(async () => {
			if (rating) {
				await query('/assistant/message/rate', 'POST', {
					withCredentials: true,
					data: {
						conversationMessageId: chatMessage.id,
						rating,
					},
				})
			} else {
				await query('/assistant/message/rating/delete', 'POST', {
					withCredentials: true,
					data: {
						conversationMessageId: chatMessage.id,
					},
				})
			}

			setMessageRating(rating)
		})
	}

	const cancelAssistantMessageHandler = async () => {
		if (!onCancelAssistantMessage || deleteMessageLoading) {
			return
		}

		setCancelMessageLoading(true)
		await onCancelAssistantMessage()
		setCancelMessageLoading(false)
	}

	const deleteAssistantMessageHandler = async () => {
		if (!onDeleteAssistantMessage || deleteMessageLoading) {
			return
		}

		setDeleteMessageLoading(true)
		await onDeleteAssistantMessage()
		setDeleteMessageLoading(false)
	}

	const retryMessageHandler = async () => {
		if (!onResendMessage || retryMessageLoading) {
			return
		}

		setRetryMessageLoading(true)
		await onResendMessage()
		setRetryMessageLoading(false)
	}

	return useMemo(() => {
		const isMessageStatus = messageContentIsInStatus(chatMessage.content)

		const showCopyText = !isMessageStatus
		const showFileAction = !!attachedFilesToIds(chatMessage.attachedFileIds).length
		const showRetryMessage =
			!!onResendMessage &&
			((!isAssistant && isMessageStatus) || chatMessage.sentByProvider === false)
		const showCancelAssistantMessage =
			!!onCancelAssistantMessage &&
			!isPlayground &&
			!!isAssistant &&
			!isMessageStatus &&
			!chatMessage.sentByProvider
		const showPublishMessage =
			!!isAssistant && !isPlayground && !isMessageStatus && chatMessage.sentByProvider === undefined
		const showRatingIcons = !!isAssistant && !isMessageStatus
		const showDeleteAssistantMessage =
			!!onDeleteAssistantMessage && isAdmin && !!isAssistant && !chatMessage.sentByProvider

		return (
			<div
				className={`
					${styles.messageTooltip} 
					${showTooltip ? styles.showTooltip : styles.hideTooltip}
					${position === 'top' ? styles.topPosition : styles.bottomPosition}
					${chatMessage.role === messageRole.USER ? styles.userTooltip : styles.assistantTooltip}
				`}
			>
				{showCopyText && (
					<Button
						disabled={chatMessage.content.length <= 1}
						onClick={copyText}
						className={`${styles.tooltipButton} ${
							copyTextButtonText === 'Copied!' ? styles.success : ''
						}`}
						type='text'
					>
						<span>{copyTextButtonText}</span>
						<CopyOutlined />
					</Button>
				)}
				{showFileAction && (
					<Button onClick={attachFileIdsFromMessage} className={styles.tooltipButton} type='text'>
						<span>
							{attachedFilesToIds(chatMessage.attachedFileIds).length > 1
								? 'Attach files'
								: 'Attach file'}
						</span>
						<FileOutlined />
					</Button>
				)}
				{chatMessage.role === messageRole.ASSISTANT && (
					<>
						{showFileAction && (
							<Button
								disabled={downloadingFileLoading}
								onClick={downloadFilesHandler}
								className={styles.tooltipButton}
								type='text'
							>
								{downloadingFileLoading ? <InlineLoading size={16} /> : <DownloadOutlined />}
							</Button>
						)}
						{showRetryMessage && (
							<Tooltip mouseEnterDelay={0.3} title='Retry message'>
								<Button
									disabled={retryMessageLoading}
									onClick={retryMessageHandler}
									className={styles.tooltipButton}
									type='text'
								>
									{retryMessageLoading ? <InlineLoading size={16} /> : <RedoOutlined />}
								</Button>
							</Tooltip>
						)}
						{showDeleteAssistantMessage && (
							<Tooltip mouseEnterDelay={0.3} title='Delete Persona message'>
								<Button
									danger
									disabled={deleteMessageLoading}
									onClick={deleteAssistantMessageHandler}
									className={styles.tooltipButton}
									type='text'
								>
									{deleteMessageLoading ? (
										<InlineLoading size={16} />
									) : (
										<DeleteOutlined color='#d9534f' />
									)}
								</Button>
							</Tooltip>
						)}
						{showCancelAssistantMessage && (
							<Tooltip mouseEnterDelay={0.3} title='Cancel Persona message'>
								<Button
									disabled={cancelMessageLoading}
									onClick={cancelAssistantMessageHandler}
									className={styles.tooltipButton}
									type='text'
								>
									{cancelMessageLoading ? <InlineLoading size={16} /> : <StopOutlined />}
								</Button>
							</Tooltip>
						)}
						{showPublishMessage && (
							<Tooltip mouseEnterDelay={0.3} title='Publish message now'>
								<Button
									disabled={publishMessageLoading}
									onClick={publishProviderMessageHandler}
									className={styles.tooltipButton}
									type='text'
								>
									{publishMessageLoading ? <InlineLoading size={16} /> : <SendOutlined />}
								</Button>
							</Tooltip>
						)}
						{showRatingIcons && (
							<>
								<Button
									onClick={() =>
										rateAssistantMessageHandler(messageRating !== 'like' ? 'like' : undefined)
									}
									className={`${styles.tooltipButton} ${
										messageRating === 'like' ? styles.success : ''
									}`}
									type='text'
								>
									<LikeOutlined />
								</Button>
								<Button
									onClick={() =>
										rateAssistantMessageHandler(messageRating !== 'dislike' ? 'dislike' : undefined)
									}
									className={`${styles.tooltipButton} ${
										messageRating === 'dislike' ? styles.danger : ''
									}`}
									type='text'
								>
									<DislikeOutlined />
								</Button>
							</>
						)}
						<Tooltip mouseEnterDelay={0.3} title='Message information'>
							<Button
								onClick={() => onSetMessageInfo(chatMessage)}
								className={styles.tooltipButton}
								type='text'
							>
								<InfoCircleOutlined />
							</Button>
						</Tooltip>
					</>
				)}
			</div>
		)
	}, [
		showTooltip,
		position,
		chatMessage.role,
		chatMessage.content,
		chatMessage.attachedFileIds,
		chatMessage.sentByProvider,
		!!isAssistant,
		isAdmin,
		messageRating,
		isPlayground,
		copyTextButtonText,
		downloadingFileLoading,
		publishMessageLoading,
		deleteMessageLoading,
		retryMessageLoading,
		cancelMessageLoading,
	])
}

export default MessageTooltip
