import Joi from 'joi'
import { ASSISTANT_GENDERS, ASSISTANT_STATUSES, PERSONALITY_TYPES } from '../../types/assistant'
import { emailSchema, usingUserIdSchema, uuidIdSchema } from '../global'
import { LANGUAGES } from '../../types/global'

export const assistantBaseSchema = {
	firstname: Joi.string().min(3).max(50),
	gender: Joi.string()
		.valid(...ASSISTANT_GENDERS)
		.required(),
	companyName: Joi.string().min(3).max(50),
	companyDescription: Joi.string().min(3).max(2500),
	personality: Joi.object({
		type: Joi.string()
			.valid(...PERSONALITY_TYPES)
			.required(),
		description: Joi.string().when('type', {
			is: 'custom',
			then: Joi.string().max(2000).required(),
			otherwise: Joi.string().forbidden(),
		}),
	}),
	additionalContext: Joi.string().allow('').max(5000),
	workHours: Joi.array().items(Joi.string().required()).allow(null),
	timezone: Joi.string(),
	defaultLanguage: Joi.string().valid(...LANGUAGES),
	multilingual: Joi.boolean(),
	humanEmails: Joi.array().items(emailSchema.min(3).max(250).required()).min(1),
}

export const createAssistantSchema = {
	firstname: assistantBaseSchema.firstname.required(),
	gender: assistantBaseSchema.gender.required(),
	companyName: assistantBaseSchema.companyName.required(),
	companyDescription: assistantBaseSchema.companyDescription.required(),
	personality: assistantBaseSchema.personality.required(),
	additionalContext: assistantBaseSchema.additionalContext.optional(),
	workHours: assistantBaseSchema.workHours.optional(),
	timezone: assistantBaseSchema.timezone.required(),
	defaultLanguage: assistantBaseSchema.defaultLanguage.required(),
	multilingual: assistantBaseSchema.multilingual.optional(),
	humanEmails: assistantBaseSchema.humanEmails.required(),
}

export const createAssistantRequestSchema = Joi.object({
	...createAssistantSchema,
	...usingUserIdSchema,
}).required()

export const updateAssistantRequestSchema = Joi.object({
	id: uuidIdSchema.required(),
	firstname: assistantBaseSchema.firstname.optional(),
	gender: assistantBaseSchema.gender.optional(),
	companyName: assistantBaseSchema.companyName.optional(),
	companyDescription: assistantBaseSchema.companyDescription.optional(),
	personality: assistantBaseSchema.personality.optional(),
	additionalContext: assistantBaseSchema.additionalContext.optional(),
	workHours: assistantBaseSchema.workHours.optional(),
	timezone: assistantBaseSchema.timezone.optional(),
	defaultLanguage: assistantBaseSchema.defaultLanguage.optional(),
	multilingual: assistantBaseSchema.multilingual.optional(),
	humanEmails: assistantBaseSchema.humanEmails.optional(),
}).required()

export const getAssistantRequestSchema = {
	id: uuidIdSchema.required(),
}

export const deleteAssistantRequestSchema = {
	id: uuidIdSchema.required(),
}

export const changeAssistantStatusRequestSchema = {
	id: uuidIdSchema.required(),
	status: Joi.string()
		.valid(...ASSISTANT_STATUSES)
		.required(),
}
