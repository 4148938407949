import { Statistic } from 'antd'
import React from 'react'
import { PublicAnalytics } from '@aiapp/types/analytics'
import { getCostValue } from '@aiapp/utils'
import styles from './AdminStatistics.module.scss'

type Props = {
	analytics: PublicAnalytics | undefined
	loading: boolean
}

const AdminStatistics = ({ analytics, loading }: Props) => {
	return (
		<div className={styles.statistics}>
			<Statistic
				title='Real lifetime cost'
				value={getCostValue(analytics?.realLifetimeCost ?? 0, false)}
				loading={loading}
			/>

			<Statistic title='Total used tokens' value={analytics?.totalUsedTokens} loading={loading} />
			<Statistic title='Prompt used tokens' value={analytics?.promptUsedTokens} loading={loading} />
			<Statistic
				title='Completion used tokens'
				value={analytics?.completionUsedTokens}
				loading={loading}
			/>
			<Statistic
				title='Embedding used tokens'
				value={analytics?.embeddingUsedTokens}
				loading={loading}
			/>
		</div>
	)
}

export default AdminStatistics
