import { Progress, Skeleton } from 'antd'
import React from 'react'
import { PublicAnalytics } from '@aiapp/types/analytics'
import { roundNumber } from '@aiapp/utils'
import styles from './ConversationsStatistics.module.scss'

type Props = {
	analytics: PublicAnalytics | undefined
	loading: boolean
}

const ConversationsStatistics = ({ analytics, loading }: Props) => {
	return (
		<div className={styles.statistics}>
			<Skeleton loading={loading} active>
				<div className={styles.progressWrapper}>
					<div className={styles.progressHeading}>Completed conversations</div>
					<Progress
						type='circle'
						percent={roundNumber(analytics?.endedConversationsPercent, 0)}
						strokeColor={{
							'0%': '#108ee9',
							'100%': '#87d068',
						}}
					/>
					{!!analytics?.endedConversationsAmount && (
						<div className={styles.progressLabel}>
							{analytics.endedConversationsAmount} / {analytics.conversationsAmount}
						</div>
					)}
				</div>

				<div className={styles.progressWrapper}>
					<div className={styles.progressHeading}>Redirected conversations</div>
					<Progress
						type='circle'
						percent={roundNumber(analytics?.redirectedConversationsPercent, 0)}
						strokeColor={{
							'0%': '#87d068',
							'50%': '#ffe58f',
							'100%': '#ffccc7',
						}}
					/>
					{!!analytics?.redirectedConversationsAmount && (
						<div className={styles.progressLabel}>
							{analytics.redirectedConversationsAmount} / {analytics.conversationsAmount}
						</div>
					)}
				</div>

				<div className={styles.progressWrapper}>
					<div className={styles.progressHeading}>Read mail conversations</div>
					<Progress
						type='circle'
						percent={roundNumber(analytics?.readGmailConversationsPercent, 0)}
						strokeColor={{
							'0%': '#108ee9',
							'100%': '#87d068',
						}}
					/>
					{!!analytics?.everReadGmailConversationsAmount && (
						<div className={styles.progressLabel}>
							{analytics.everReadGmailConversationsAmount} / {analytics.conversationsGmailAmount}
						</div>
					)}
				</div>

				<div className={styles.progressWrapper}>
					<div className={styles.progressHeading}>Mail conversations with response</div>
					<Progress
						type='circle'
						percent={roundNumber(analytics?.responseGmailConversationsPercent, 0)}
						strokeColor={{
							'0%': '#108ee9',
							'100%': '#87d068',
						}}
					/>
					{!!analytics?.responseGmailConversationsAmount && (
						<div className={styles.progressLabel}>
							{analytics.responseGmailConversationsAmount} / {analytics.conversationsGmailAmount}
						</div>
					)}
				</div>
			</Skeleton>
		</div>
	)
}

export default ConversationsStatistics
