import { App, Button, Form, Input } from 'antd'
import { useState } from 'react'
import { NewLeadsGroup, LeadsGroup, UpdateLeadsGroupRequest } from '@aiapp/types/leadsGroup'
import TextArea from 'antd/es/input/TextArea'
import useAsyncEffect from '../../../hooks/useAsyncEffect'
import query from '../../../utils/query'
import useTryCatch from '../../../hooks/useTryCatch'
import Loading from '../../../components/Loading/Loading'
import styles from './LeadsGroupForm.module.scss'

type Props = {
	onSubmit: (data: UpdateLeadsGroupRequest | NewLeadsGroup) => Promise<void>
	onCancel: () => void
	leadsGroupId?: string
}

const LeadsGroupForm = ({ onSubmit, leadsGroupId, onCancel }: Props) => {
	const { message } = App.useApp()
	const tryCatch = useTryCatch(message)

	const [leadsGroup, setLeadsGroup] = useState<LeadsGroup>()
	const [loading, setLoading] = useState<boolean>(true)

	useAsyncEffect(async () => {
		if (!leadsGroupId) {
			setLoading(false)
			return
		}

		setLoading(true)
		await tryCatch(
			async () => {
				const resLeadsGroup = await query<LeadsGroup>('/leadsGroup/get', 'GET', {
					params: { id: leadsGroupId },
					withCredentials: true,
				})
				setLeadsGroup(resLeadsGroup)
				setLoading(false)
			},
			undefined,
			{ message: 'Fetch leads group data error. Try again and refresh page.' },
		)
	}, [leadsGroupId])

	const onFinish = async (formData: Partial<LeadsGroup>) => {
		if (leadsGroupId) {
			const updateData: UpdateLeadsGroupRequest = {
				id: leadsGroupId,
				...formData,
			}
			await onSubmit(updateData)
		} else {
			await onSubmit(formData as NewLeadsGroup)
		}
	}

	if (loading) {
		return <Loading size={32} />
	}

	return (
		<Form
			autoComplete='true'
			initialValues={leadsGroup ?? {}}
			onFinish={onFinish}
			labelCol={{ span: 4 }}
			wrapperCol={{ span: 14 }}
			layout='vertical'
		>
			<Form.Item
				rules={[
					{ required: true, message: 'Please fill group name' },
					{ message: 'Group name must have a minimum of 3 characters', min: 3 },
					{ message: 'Group name can have a maximum of 120 characters', max: 120 },
				]}
				className={styles.formItem}
				label='Name'
				name='name'
			>
				<Input
					count={{
						show: true,
					}}
					maxLength={120}
					size='large'
					className={styles.formInput}
					placeholder='E-mail leads'
				/>
			</Form.Item>

			<Form.Item
				rules={[{ message: 'Group description can have a maximum of 2000 characters', max: 2000 }]}
				className={styles.formItem}
				label='Description'
				name='description'
			>
				<TextArea
					className={styles.formTextarea}
					size='large'
					allowClear
					count={{
						show: true,
					}}
					maxLength={2000}
					defaultValue=''
					autoSize={{ minRows: 2, maxRows: 6 }}
					placeholder='Leads group description'
				/>
			</Form.Item>

			<Form.Item noStyle>
				<div className={styles.buttons}>
					<Button size='large' htmlType='submit' type='primary'>
						{leadsGroup?.id ? 'Update' : 'Create'}
					</Button>
					<Button size='large' onClick={onCancel}>
						Cancel
					</Button>
				</div>
			</Form.Item>
		</Form>
	)
}

export default LeadsGroupForm
