import { Input } from 'antd'
import React, { ChangeEventHandler, FocusEventHandler, InputHTMLAttributes } from 'react'
import styles from './InlineTextInput.module.scss'

type Props = {
	value: InputHTMLAttributes<HTMLInputElement>['value']
	onChange: ChangeEventHandler<HTMLInputElement>
	onBlur: FocusEventHandler<HTMLInputElement>
	className?: string
	autoFocus?: boolean
}

const InlineTextInput = ({ value, onChange, onBlur, autoFocus, className = '' }: Props) => {
	return (
		<Input
			type='text'
			className={`${styles.inlineInput} ${className}`}
			value={value}
			onChange={onChange}
			onBlur={onBlur}
			autoFocus={autoFocus}
		/>
	)
}

export default InlineTextInput
