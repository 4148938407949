import { Form } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import styles from './Forms.module.scss'

const CompanyOffer = () => {
	return (
		<Form.Item
			rules={[
				{
					required: true,
					message: 'Please fill company offer description',
				},
				{ message: 'Company offer description must have a minimum of 3 characters', min: 3 },
				{
					message: 'Company offer description can have a maximum of 3000 characters',
					max: 3000,
				},
			]}
			className={styles.formItem}
			label='Company offer'
			name='companyOffer'
		>
			<TextArea
				className={styles.formTextarea}
				size='large'
				allowClear
				count={{
					show: true,
				}}
				maxLength={3000}
				autoSize={{ minRows: 2, maxRows: 30 }}
				placeholder='Company offer description'
			/>
		</Form.Item>
	)
}

export default CompanyOffer
