import React, { PropsWithChildren } from 'react'
import { createStateContext } from '../createStateContext'
import { AssistantRoleContextInterface, FAQ } from '../../types/AssistantContext'
import { AssistantRole, AssistantRoleIntegration } from '@aiapp/types/assistant/assistantRole'
import { AssistantRoleStep } from '../../containers/Personas/Roles/Role/utils'

const assistantRoleInitialState: AssistantRoleContextInterface = {
	validStep: false,
	data: {},
}

const AssistantRoleContext = createStateContext(assistantRoleInitialState, (setStore) => ({
	setData(assistantRole: AssistantRole) {
		setStore((store) => {
			return {
				...store,
				data: assistantRole,
			}
		})
	},
	updateData(data: Partial<AssistantRole>) {
		setStore((store) => {
			return {
				...store,
				data: {
					...store.data,
					...data,
				} as Partial<AssistantRole>,
			}
		})
	},
	setIntegrations(integrations: AssistantRoleIntegration[]) {
		setStore((store) => {
			return {
				...store,
				data: {
					...store.data,
					connections: {
						...(store.data.connections ?? {}),
						integrations,
					},
				},
			}
		})
	},
	setLeadsGroupIds(leadsGroupIds: string[]) {
		setStore((store) => {
			return {
				...store,
				data: {
					...store.data,
					connections: {
						...(store.data.connections ?? {}),
						leadsGroupIds,
					},
				},
			}
		})
	},
	setContextFile(file?: File) {
		setStore((store) => {
			return {
				...store,
				contextFile: file,
			}
		})
	},
	setContextUrl(url?: string) {
		setStore((store) => {
			return {
				...store,
				contextUrl: url,
			}
		})
	},
	setContextFAQs(FAQs?: FAQ[]) {
		setStore((store) => {
			return {
				...store,
				contextFAQs: FAQs,
			}
		})
	},
	setStep(step: AssistantRoleStep) {
		setStore((store) => {
			return {
				...store,
				step,
			}
		})
	},
	setValidStep(validStep: boolean) {
		setStore((store) => {
			return {
				...store,
				validStep: validStep,
			}
		})
	},
}))

export const AssistantRoleContextProvider = ({ children }: PropsWithChildren<{}>) => (
	<AssistantRoleContext.Provider>{children}</AssistantRoleContext.Provider>
)

export const useAssistantRoleContext = AssistantRoleContext.useContext
