import { FailureObject } from '../global/failure'
import { UsingUserId } from '../global'

export interface ConversationMessage {
	id: string
	conversationId: string
	role: ConversationMessageRole
	content: string
	isComment?: boolean
	sentByProvider?: boolean
	providerMessageId?: string
	rootMessageId?: string
	category?: ConversationMessageCategory
	salesStage?: SalesStage
	error?: FailureObject
	publishAt?: Date
	sentAt?: Date
	createdAt: Date
}

export const CONVERSATION_MESSAGE_ROLES = ['user', 'assistant'] as const
export type ConversationMessageRole = (typeof CONVERSATION_MESSAGE_ROLES)[number]

const BASE_CONVERSATION_MESSAGE_CATEGORIES = [
	'UNRELATED',
	'MISSING_INFORMATION',
	'REMIND',
	'UNACCEPTABLE',
	'UNSUPPORTED',
	'CONDITION_REDIRECT',
] as const
const ROLES_CONVERSATION_MESSAGE_CATEGORIES = [
	'SELLER_OFFERS',
	'LEAD_GENERATION',
	'APPOINTMENT_SETTER',
	'HELPDESK',
	'HELPDESK_UPSELL',
	'EXTERNAL_ASSISTANT',
	'INTERNAL_ASSISTANT',
] as const
const GOAL_REACHED_CONVERSATION_MESSAGE_CATEGORIES = [
	'SELLING_OFFERS_GOAL_REACHED',
	'LEAD_GENERATION_GOAL_REACHED',
	'APPOINTMENT_GOAL_REACHED',
] as const
export const CONVERSATION_MESSAGE_CATEGORIES = [
	...BASE_CONVERSATION_MESSAGE_CATEGORIES,
	...ROLES_CONVERSATION_MESSAGE_CATEGORIES,
	...GOAL_REACHED_CONVERSATION_MESSAGE_CATEGORIES,
] as const

export type ConversationMessageCategory = (typeof CONVERSATION_MESSAGE_CATEGORIES)[number]

export const SALES_STAGES = ['RELATIONSHIP', 'PROBLEM', 'SELL'] as const
export type SalesStage = (typeof SALES_STAGES)[number]

export type GetConversationMessagesRequest = Pick<ConversationMessage, 'conversationId'>

export type CancelConversationMessageRequest = Pick<ConversationMessage, 'id'>
export type DeleteConversationMessageRequest = Pick<ConversationMessage, 'id'>
export type CreateAssistantMessageRequest = {
	conversationId: string
	content: string
}
export type CreateUserMessageRequest = {
	integrationId?: string
	conversationId?: string
	assistantRoleId?: string
	content: string
} & UsingUserId
