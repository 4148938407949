import FormRow from '../../../../../../components/FormRow/FormRow'
import { Checkbox, Form, Input } from 'antd'
import styles from './Forms.module.scss'

type Props = {
	disabledEmoji: boolean | undefined
}

const EmojiConfig = ({ disabledEmoji }: Props) => {
	return (
		<FormRow columns={[5, 1]}>
			<Form.Item
				rules={[{ message: 'Preferred emoji should have a maximum of 5-6 emoji', max: 20 }]}
				tooltip='Leave it blank so that the Persona selects emojis for the context of the message.'
				className={styles.formItem}
				label='Preferred emoji'
				name='preferredEmoji'
			>
				<Input
					className={styles.formInput}
					size='large'
					disabled={disabledEmoji}
					allowClear
					showCount
					maxLength={20}
					placeholder='Enter emoji separated by commas (e.g. 🥰,💄,🥳)'
				/>
			</Form.Item>

			<Form.Item name='disabledEmoji' className={styles.formItem} valuePropName='checked'>
				<Checkbox className={styles.formCheckbox}>Do not use emoji</Checkbox>
			</Form.Item>
		</FormRow>
	)
}

export default EmojiConfig
