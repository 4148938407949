import { Button, Form, Input } from 'antd'
import FormRow from '../../../../../../components/FormRow/FormRow'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import styles from './Forms.module.scss'

const RedirectConditions = () => {
	return (
		<Form.Item
			required
			tooltip='Define the conditions for which the conversation should be redirected to a human.'
			className={styles.formItem}
			label='Redirect conditions'
		>
			<Form.List name='redirectConditions'>
				{(fields, { add, remove }) => (
					<>
						{fields.map(({ key, name, ...restField }) => (
							<div key={key} className={styles.fieldList}>
								<FormRow columns={[1, 1]}>
									<Form.Item
										{...restField}
										rules={[
											{ required: true, message: 'Please fill redirect condition' },
											{
												message: 'Redirect condition must have a minimum of 10 characters',
												min: 10,
											},
											{
												message: 'Redirect condition can have a maximum of 500 characters',
												max: 500,
											},
										]}
										className={styles.formItem}
										name={[name, 'condition']}
									>
										<Input
											maxLength={500}
											count={{
												show: true,
											}}
											size='large'
											className={styles.formInput}
											placeholder='Condition description'
										/>
									</Form.Item>

									<Form.Item
										{...restField}
										rules={[
											{
												message: 'Response message must have a minimum of 3 characters',
												min: 6,
											},
											{
												message: 'Response message can have a maximum of 500 characters',
												max: 500,
											},
										]}
										className={styles.formItem}
										name={[name, 'message']}
									>
										<Input
											size='large'
											count={{
												show: true,
											}}
											maxLength={500}
											allowClear
											className={styles.formInput}
											placeholder='Response message'
										/>
									</Form.Item>
								</FormRow>

								<MinusCircleOutlined
									className={styles.listRemoveIcon}
									onClick={() => remove(name)}
								/>
							</div>
						))}
						<Form.Item className={styles.formItem}>
							<Button
								size='large'
								type='dashed'
								disabled={fields.length === 5}
								onClick={() => {
									fields.length < 5 && add()
								}}
								block
								icon={<PlusOutlined />}
							>
								Add new condition
							</Button>
						</Form.Item>
					</>
				)}
			</Form.List>
		</Form.Item>
	)
}

export default RedirectConditions
