import { Button, Dropdown, MenuProps } from 'antd'
import { AppstoreOutlined } from '@ant-design/icons'
import React, { MutableRefObject, useMemo } from 'react'
import { useChatContext } from '../../../../hooks/context/ChatContext'
import { useEditorContext } from '../../../../hooks/context/EditorContext'
import styles from './ChatTools.module.scss'

import textToolGroup from '../../../../assets/toolsIcons/textToolGroup.webp'
import textToImage from '../../../../assets/toolsIcons/textToImage.webp'
import textToLogo from '../../../../assets/toolsIcons/textToLogo.webp'
import textToMaterialTexture from '../../../../assets/toolsIcons/textToMaterialTexture.webp'
import imageToVideo from '../../../../assets/toolsIcons/imageToVideo.webp'
import imageInterpreter from '../../../../assets/toolsIcons/imageInterpreter.webp'
import imagesRestoration from '../../../../assets/toolsIcons/imagesRestoration.webp'
import imageResize from '../../../../assets/toolsIcons/imageResize.webp'
import faceSwap from '../../../../assets/toolsIcons/faceSwap.webp'
import removeBackground from '../../../../assets/toolsIcons/removeBackground.webp'
import textToMusic from '../../../../assets/toolsIcons/textToMusic.webp'
import textToSpeech from '../../../../assets/toolsIcons/textToSpeech.webp'
import speechToText from '../../../../assets/toolsIcons/speechToText.webp'
import { useGlobalContext } from '../../../../hooks/context/GlobalContext'

type Props = {
	chatToolsRef: MutableRefObject<any>
}

type ToolGroup = {
	name: string
	icon: string
	items: ToolItem[]
}

type ToolItem = {
	name: string
	icon: string
	onClick: () => void
}

const ChatTools = ({ chatToolsRef }: Props) => {
	const editorContext = useEditorContext()
	const chatContext = useChatContext()
	const globalContext = useGlobalContext()

	const showChatTour = globalContext.useSubscribe((context) => context.showChatTour)

	const textTools: ToolItem[] = [
		{
			name: 'Image to text',
			icon: imageInterpreter,
			onClick: () => editorContext.setToolType('IMAGE_TO_TEXT'),
		},
		{
			name: 'Speech to text',
			icon: speechToText,
			onClick: () => editorContext.setToolType('SPEECH_TO_TEXT'),
		},
	]

	const imageTools: ToolItem[] = [
		{
			name: 'Generate images',
			icon: textToImage,
			onClick: () => editorContext.setToolType('TEXT_TO_IMAGE'),
		},
		{
			name: 'Generate logotypes or icons',
			icon: textToLogo,
			onClick: () => editorContext.setToolType('TEXT_TO_LOGO_OR_ICON'),
		},
		{
			name: 'Generate material textures',
			icon: textToMaterialTexture,
			onClick: () => editorContext.setToolType('TEXT_TO_TEXTURE'),
		},
		{
			name: 'Image upgrader',
			icon: imagesRestoration,
			onClick: () => editorContext.setToolType('IMAGE_RESTORATION'),
		},
		{
			name: 'Image resizer',
			icon: imageResize,
			onClick: () => editorContext.setToolType('IMAGE_RESIZER'),
		},
		{
			name: 'Face swap',
			icon: faceSwap,
			onClick: () => editorContext.setToolType('FACE_SWAP'),
		},
		{
			name: 'Background remover',
			icon: removeBackground,
			onClick: () => editorContext.setToolType('BACKGROUND_REMOVER'),
		},
	]

	const videoTools: ToolItem[] = [
		{
			name: 'Image to video',
			icon: imageToVideo,
			onClick: () => editorContext.setToolType('IMAGE_TO_VIDEO'),
		},
	]

	const audioTools: ToolItem[] = [
		{
			name: 'Text to Speech',
			icon: textToSpeech,
			onClick: () => editorContext.setToolType('TEXT_TO_SPEECH'),
		},
		{
			name: 'Text to Music',
			icon: textToMusic,
			onClick: () => editorContext.setToolType('TEXT_TO_MUSIC'),
		},
	]

	const toolGroups: ToolGroup[] = [
		{
			name: 'Text tools',
			icon: textToolGroup,
			items: textTools,
		},
		{
			name: 'Image tools',
			icon: textToImage,
			items: imageTools,
		},
		{
			name: 'Video tools',
			icon: imageToVideo,
			items: videoTools,
		},
		{
			name: 'Audio tools',
			icon: textToSpeech,
			items: audioTools,
		},
	]

	const toolsItems: MenuProps['items'] = useMemo(
		() =>
			toolGroups.map((group, groupIndex) => ({
				key: groupIndex,
				disabled: showChatTour,
				label: (
					<Button className={`${styles.toolButton} ${styles.toolGroupButton}`} type='text'>
						{group.name}
					</Button>
				),
				popupClassName: styles.toolMenu,
				icon: <img className={styles.toolIcon} alt={`${group.name} icon`} src={group.icon} />,
				children: group.items.map((tool, toolIndex) => ({
					key: `${groupIndex}.${toolIndex}`,
					disabled: showChatTour,
					onClick: tool.onClick,
					label: (
						<Button className={styles.toolButton} type='text'>
							{tool.name}
						</Button>
					),
					icon: <img className={styles.toolIcon} alt={`${tool.name} icon`} src={tool.icon} />,
				})),
			})),
		[showChatTour],
	)

	return (
		<Dropdown
			onOpenChange={(open) => chatContext.setChatContext({ blurMessages: open })}
			placement='topLeft'
			menu={{ items: toolsItems, className: styles.toolGroupMenu }}
		>
			<Button ref={chatToolsRef} className={styles.showTools} type='text' onClick={() => {}}>
				<AppstoreOutlined className={styles.toolsIcon} />
			</Button>
		</Dropdown>
	)
}

export default ChatTools
