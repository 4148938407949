import {
	ConversationStyle,
	FollowupInterval,
	MaxFollowups,
	ResponseLength,
	WarmupLevel,
} from '../assistantRole'

export type LeadGenerationRole = {
	type: 'leadGeneration'
	companyOffer: string
	promotionsDescription?: string
	disabledEmoji: boolean
	preferredEmoji?: string
	followupInterval?: FollowupInterval
	maxFollowups?: MaxFollowups
	conversationStyle: ConversationStyle
	responseLength: ResponseLength
	warmupLevel: WarmupLevel

	goal: LeadGenerationGoal
	lead: string[]
}

export const LEAD_GENERATION_GOALS = ['lead'] as const
export type LeadGenerationGoal = (typeof LEAD_GENERATION_GOALS)[number]
