import React, { PropsWithChildren, useEffect } from 'react'
import { createStateContext } from '../createStateContext'
import GlobalContextInterface from '../../types/GlobalContext'
import { useAccountContext } from './AccountContext'
import { useLocation } from 'react-router-dom'

const globalInitialState: GlobalContextInterface = {
	language: 'en',
	showChatTour: false,
}

const GlobalContext = createStateContext(globalInitialState, (setStore) => ({
	updatePageTitle(title: string | undefined) {
		setStore((store) => {
			return {
				...store,
				pageTitle: title,
			}
		})
	},
	setShowChatTour(show: boolean) {
		setStore((store) => ({ ...store, showChatTour: show }))
	},
}))

const UpdateGlobalContextState = () => {
	const location = useLocation()

	const accountContext = useAccountContext()
	const globalContext = useGlobalContext()

	const pageTitle = globalContext.useSubscribe((context) => context.pageTitle)
	const showWelcomeModal = accountContext.useSubscribe(
		(account) => account.modals.chatTour?.show ?? false,
	)

	useEffect(() => {
		if (pageTitle) {
			globalContext.updatePageTitle(undefined)
		}
	}, [location.pathname])

	useEffect(() => {
		globalContext.setShowChatTour(showWelcomeModal)
	}, [showWelcomeModal])

	return <></>
}

export const GlobalContextProvider = ({ children }: PropsWithChildren<{}>) => (
	<GlobalContext.Provider>
		<UpdateGlobalContextState />
		{children}
	</GlobalContext.Provider>
)

export const useGlobalContext = GlobalContext.useContext
