import { MutableRefObject } from 'react'
import { TourProps } from 'antd'

const getSteps = (
	chatHeaderRef: MutableRefObject<any>,
	chatSessionsRef: MutableRefObject<any>,
	chatToolsRef: MutableRefObject<any>,
	chatChatAttachmentRef: MutableRefObject<any>,
): TourProps['steps'] => [
	{
		title: 'Nagłówek',
		description: (
			<span>
				W górnej części chatu znajduje się <b>nazwa chatu</b>, <b>ikonka opcji</b> prowadząca do
				ustawień chatu i <b>ikonka chowająca sekcję sesji</b>.
			</span>
		),
		target: () => chatHeaderRef.current,
		nextButtonProps: {
			children: <span>Dalej</span>,
		},
	},
	{
		title: 'Sesje',
		description: (
			<span>
				Lista sesji przechowująca <b>historie wiadomości</b>. Lista umożliwia tworzenie{' '}
				<b>nowych sesji</b>, <b>zmianę ich nazw</b>, <b>usuwanie sesji</b> i{' '}
				<b>połączenie historii wiadomości z wybranych sesji</b> do aktualnej.
			</span>
		),
		target: () => chatSessionsRef.current,
		placement: 'left',
		nextButtonProps: {
			children: <span>Dalej</span>,
		},
		prevButtonProps: {
			children: <span>Cofnij</span>,
		},
	},
	{
		title: 'Narzędzia',
		description: (
			<span>
				Lista dostępnych narzędzi w chacie.{' '}
				<b>Domyślnie chatbot ma dostęp tylko do załączonego kontekstu</b>, dane narzędzia mogą
				zostać wykorzystane dopiero po <b>wyborze z listy</b>.
			</span>
		),
		target: () => chatToolsRef.current,
		nextButtonProps: {
			children: <span>Dalej</span>,
		},
		prevButtonProps: {
			children: <span>Cofnij</span>,
		},
	},
	{
		title: 'Załączniki',
		description: (
			<span>
				Umożliwia <b>załączanie pliku do nowej wiadomości</b> lub do{' '}
				<b>aktualnego kontekstu chatu</b> - działa, podobnie jak w zakładce &quot;Contexts&quot;.
				<br />
				Możesz to osiągnąć także poprzez przeciągnięcie pliku na chat.
			</span>
		),
		target: () => chatChatAttachmentRef.current,
		nextButtonProps: {
			children: <span>Koniec</span>,
		},
		prevButtonProps: {
			children: <span>Cofnij</span>,
		},
	},
]

export default getSteps
