import { App, Button, Form, Input } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import React, { useEffect, useState } from 'react'
import { AssistantRole } from '@aiapp/types/assistant/assistantRole'
import CardItem from '../../../../components/CardItem/CardItem'
import CardTitle from '../../../../components/CardTitle/CardTitle'
import { UserOutlined } from '@ant-design/icons'
import { formatDate } from '@aiapp/utils/dates'
import useAsyncEffect from '../../../../hooks/useAsyncEffect'
import query from '../../../../utils/query'
import useTryCatch from '../../../../hooks/useTryCatch'
import Loading from '../../../../components/Loading/Loading'
import { StartConversationsFormData } from '../Leads'
import { formValidate } from '../../../../utils/validation'
import Joi from 'joi'
import styles from './StartLeadsConversationsForm.module.scss'

type Props = {
	provider: 'gmail' | 'whatsapp'
	groupId: string
	onSubmit: (
		startConversationsData: StartConversationsFormData,
		selectedAssistantRoleId: string,
	) => Promise<void>
	onCancel: () => void
}

const StartLeadsConversationsForm = ({ provider, groupId, onSubmit, onCancel }: Props) => {
	const { message } = App.useApp()
	const tryCatch = useTryCatch(message)

	const [startConversationsFormData, setStartConversationsFormData] =
		useState<StartConversationsFormData>({
			provider,
			messageContent: '',
			reminderMessageContent: '',
		})

	const [assistantRoles, setAssistantRoles] = useState<AssistantRole[]>([])
	const [selectedAssistantRoleId, setSelectedAssistantRoleId] = useState<string>()
	const [isValidForm, setIsValidForm] = useState<boolean>(false)

	const [onSubmitLoading, setOnSubmitLoading] = useState<boolean>(false)
	const [loading, setLoading] = useState<boolean>(true)

	useAsyncEffect(async () => {
		await tryCatch(
			async () => {
				const assistantRoles = await query<AssistantRole[]>(
					'/assistant/roles/byLeadsGroup',
					'GET',
					{
						params: { leadsGroupId: groupId },
						withCredentials: true,
					},
				)
				setAssistantRoles(assistantRoles)
			},
			undefined,
			{ message: 'Load data error' },
		)
		setLoading(false)
	}, [groupId])

	useEffect(() => {
		checkFormValidate()
	}, [startConversationsFormData])

	const selectAssistantRoleId = (assistantRoleId: string) => {
		if (assistantRoleId === selectedAssistantRoleId) {
			setSelectedAssistantRoleId(undefined)
		}
		setSelectedAssistantRoleId(assistantRoleId)
	}

	const onStartNewConversations = async () => {
		if (!isValidForm || !selectedAssistantRoleId) {
			return
		}
		setOnSubmitLoading(true)
		await onSubmit(startConversationsFormData, selectedAssistantRoleId)
	}

	const updateStartConversationsFormData = (
		_value: Record<string, any>,
		values: StartConversationsFormData,
	) => {
		setStartConversationsFormData((formData) => ({ ...formData, ...values }))
	}

	const checkFormValidate = () => {
		const validate = formValidate(startConversationsFormData, startLeadsConversationFormSchema)
		setIsValidForm(validate ? !Object.keys(validate).length : true)
	}

	if (loading) {
		return <Loading />
	}

	return (
		<Form
			autoComplete='true'
			initialValues={startConversationsFormData}
			onValuesChange={updateStartConversationsFormData}
			labelCol={{ span: 4 }}
			wrapperCol={{ span: 14 }}
			layout='vertical'
		>
			<div className={styles.formContent}>
				<Form.Item
					rules={[
						{ required: true, message: 'Please fill message content' },
						{ message: 'Message content must have a minimum of 10 characters', min: 10 },
						{ message: 'Message content can have a maximum of 2000 characters', max: 2000 },
					]}
					className={styles.formItem}
					label='Message content'
					name='messageContent'
				>
					<TextArea
						className={styles.formTextarea}
						size='large'
						allowClear
						count={{
							show: true,
						}}
						maxLength={2000}
						autoSize={{ minRows: 2, maxRows: 6 }}
						placeholder='Start conversation message'
					/>
				</Form.Item>

				<Form.Item
					rules={[
						{ required: true, message: 'Please fill reminder message content' },
						{ message: 'Reminder message content must have a minimum of 10 characters', min: 10 },
						{ message: 'Reminder message can have a maximum of 2000 characters', max: 2000 },
					]}
					tooltip='The default content of the conversation reminder message. Used when the user does not respond to the first Persona message.'
					className={styles.formItem}
					label='Reminder message content'
					name='reminderMessageContent'
				>
					<TextArea
						className={styles.formTextarea}
						size='large'
						allowClear
						count={{
							show: true,
						}}
						maxLength={2000}
						autoSize={{ minRows: 2, maxRows: 6 }}
						placeholder='Default reminder message'
					/>
				</Form.Item>

				{provider === 'gmail' && (
					<>
						<Form.Item
							rules={[
								{ required: true, message: 'Please fill e-mail subject' },
								{ message: 'Subject must have a minimum of 10 characters', min: 5 },
								{ message: 'Subject can have a maximum of 2000 characters', max: 200 },
							]}
							className={styles.formItem}
							label='Mail subject'
							name='subject'
						>
							<Input
								count={{
									show: true,
								}}
								maxLength={200}
								size='large'
								className={styles.formInput}
								placeholder='Mail subject'
							/>
						</Form.Item>

						<Form.Item
							tooltip='Enter mail signature in HTML format'
							className={styles.formItem}
							label='Mail signature'
							name='signature'
						>
							<TextArea
								className={styles.formTextarea}
								size='large'
								allowClear
								autoSize={{ minRows: 2, maxRows: 6 }}
								placeholder='Mail signature'
							/>
						</Form.Item>
					</>
				)}

				<div className={styles.heading}>Available Persona Roles</div>
				<div className={styles.assistantRoles}>
					{!assistantRoles.length && (
						<div>No available Persona roles. Connect this lead group to Persona role.</div>
					)}
					{assistantRoles.map((assistantRole) => (
						<CardItem
							key={assistantRole.id}
							itemId={assistantRole.id}
							selectedItems={selectedAssistantRoleId ? [selectedAssistantRoleId] : []}
							selectItem={() => selectAssistantRoleId(assistantRole.id)}
							cardTitle={
								<CardTitle
									id={assistantRole.id}
									name={
										<span>
											<UserOutlined className={styles.roleIcon} />
											{assistantRole.name}
										</span>
									}
								/>
							}
							labels={[
								{
									label: 'Role',
									value: assistantRole.type,
								},
								{
									label: 'Created date',
									value: formatDate(assistantRole.createdAt),
								},
							]}
						>
							<Button
								className={
									assistantRole.id === selectedAssistantRoleId ? styles.selectedButton : ''
								}
								onClick={(e) => {
									e.stopPropagation()
									selectAssistantRoleId(assistantRole.id)
								}}
							>
								{assistantRole.id === selectedAssistantRoleId ? 'Selected' : 'Select'}
							</Button>
						</CardItem>
					))}
				</div>
			</div>

			<Form.Item noStyle>
				<div className={styles.buttons}>
					<Button
						disabled={!isValidForm || !selectedAssistantRoleId}
						size='large'
						loading={onSubmitLoading}
						onClick={onStartNewConversations}
						type='primary'
					>
						Start conversations
					</Button>
					<Button loading={onSubmitLoading} size='large' onClick={onCancel}>
						Cancel
					</Button>
				</div>
			</Form.Item>
		</Form>
	)
}

const startLeadsConversationFormSchema = Joi.object({
	provider: Joi.string().valid('gmail', 'whatsapp').required(),
	messageContent: Joi.string().min(10).max(2000).required(),
	reminderMessageContent: Joi.string().min(10).max(2000).required(),

	// for emails
	subject: Joi.string().when('provider', {
		is: 'gmail',
		then: Joi.string().min(5).max(200).required(),
		otherwise: Joi.string().optional(),
	}),
	signature: Joi.string().when('provider', {
		is: 'gmail',
		then: Joi.string().required().max(20000).optional(),
		otherwise: Joi.string().optional(),
	}),
}).required()

export default StartLeadsConversationsForm
