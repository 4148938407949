import { App, Segmented } from 'antd'
import { useEffect, useState } from 'react'
import { Lead as LeadInterface } from '@aiapp/types/leadsGroup/lead'
import { PublicProgressStep } from '@aiapp/types/progressSteps'
import { socket } from '../../../../global/SocketManager'
import CsvFileUploader from './CsvFileUploader/CsvFileUploader'
import LeadForm from './LeadForm/LeadForm'
import styles from './Lead.module.scss'

type Props = {
	leadData?: Partial<LeadInterface>
	onCreateNewLead: (data: Partial<LeadInterface>) => Promise<void>
	onUpdateLead: (leadId: string, data: Partial<LeadInterface>) => Promise<void>
	onSubmitCsv: (csvFile: File) => Promise<string | undefined>
	onClose: (refresh?: boolean) => Promise<void>
}

const TABS = ['Form', 'CSV'] as const
type Tab = (typeof TABS)[number]

const Lead = ({ leadData, onCreateNewLead, onUpdateLead, onSubmitCsv, onClose }: Props) => {
	const { message } = App.useApp()

	const [tab, setTab] = useState<Tab>('Form')

	const [csvProgressStepId, setCsvProgressStepId] = useState<string>()
	const [csvProgressStep, setCsvProgressStep] = useState<PublicProgressStep>()

	const updateCsvProgressStep = async (progressStep?: PublicProgressStep) => {
		if (csvProgressStepId !== progressStep?.id) {
			return
		}

		setCsvProgressStep(progressStep)

		if (progressStep?.status === 'succeeded') {
			if (progressStep.completeContent?.length) {
				message.open({
					type: 'success',
					content: `Added ${progressStep.completeContent} new records`,
				})
			}

			setCsvProgressStep(undefined)
			await onClose(true)
		}

		if (progressStep?.status === 'failed' || progressStep?.status === 'canceled') {
			message.open({
				type: 'error',
				content: 'Error parsing CSV file',
			})

			setCsvProgressStep(undefined)
			await onClose(true)
		}
	}

	useEffect(() => {
		socket.on('updatedProgressStep', updateCsvProgressStep)
		return () => {
			socket.off('updatedProgressStep', updateCsvProgressStep)
		}
	}, [csvProgressStepId])

	const onFinish = async (formData: Partial<LeadInterface>) => {
		if (leadData?.id) {
			await onUpdateLead(leadData.id, formData)
		} else {
			await onCreateNewLead(formData)
		}
	}

	const uploadCsv = async (csvFile: File) => {
		const progressStepId = await onSubmitCsv(csvFile)
		if (progressStepId) {
			setCsvProgressStepId(progressStepId)
		}
	}

	return (
		<div className={styles.container}>
			{!leadData && (
				<Segmented
					value={tab}
					onChange={(tabValue) => setTab(tabValue as Tab)}
					className={styles.contentTabs}
					size='large'
					options={[...TABS]}
				/>
			)}

			{tab === 'Form' && <LeadForm onClose={onClose} onFinish={onFinish} leadData={leadData} />}
			{tab === 'CSV' && (
				<CsvFileUploader
					onClose={onClose}
					uploadCsv={uploadCsv}
					csvProgressStep={csvProgressStep}
				/>
			)}
		</div>
	)
}

export default Lead
