import { PromptTemplate, TemplateTool, ToolId } from '../types/global/tools'

export const promptsTemplates: Record<TemplateTool, PromptTemplate> = {
	TEXT_TO_IMAGE: {
		name: 'Text to image',
		toolIds: ['DALL_TEXT_TO_IMAGE', 'SDXL_TEXT_TO_IMAGE'],
		inputs: [
			{
				id: 'TEXT_TO_IMAGE_DESCRIPTION',
				text: 'Image description',
				limit: 2500,
				placeholder: 'e.g. white cat on the house roof',
				required: true,
			},
			{
				id: 'TEXT_TO_IMAGE_RESOLUTION',
				text: 'Image resolution',
				limit: 10,
				placeholder: 'optional, by default: 1024x1024',
			},
			{
				id: 'TEXT_TO_IMAGE_GENERATOR',
				text: 'Image generator',
				limit: 10,
				placeholder: 'optional, SDXL or DALL-e',
			},
			{
				id: 'TEXT_TO_IMAGE_SAMPLES',
				text: 'Samples',
				limit: 10,
				placeholder: 'optional, by default: 1, max 4',
			},
			{
				id: 'TEXT_TO_IMAGE_FILE',
				text: 'Based image',
				placeholder: '(optional, base image)',
				dropzone: true,
				limit: 1,
				acceptFiles: {
					'image/png': ['.png'],
					'image/jpeg': ['.jpeg', '.jpg'],
				},
			},
		],
	},
	TEXT_TO_LOGO_OR_ICON: {
		name: 'Text to logo or icon',
		toolIds: ['TEXT_TO_LOGO_OR_ICON'],
		inputs: [
			{
				id: 'TEXT_TO_LOGO_OR_ICON_DESCRIPTION',
				text: 'Logo or icon description',
				limit: 2500,
				placeholder:
					'e.g. minimalist software company logo in good quality with shades of red and green',
				required: true,
			},
			{
				id: 'TEXT_TO_LOGO_OR_ICON_RESOLUTION',
				text: 'Logo or icon resolution',
				limit: 10,
				placeholder: 'optional, by default: 512x512',
			},
			{
				id: 'TEXT_TO_LOGO_OR_ICON_SAMPLES',
				text: 'Samples',
				limit: 10,
				placeholder: 'optional, by default: 1, max: 4',
			},
		],
	},
	TEXT_TO_TEXTURE: {
		name: 'Text to material texture',
		toolIds: ['TEXT_TO_TEXTURE'],
		inputs: [
			{
				id: 'TEXT_TO_TEXTURE_DESCRIPTION',
				text: 'Texture description',
				limit: 2500,
				placeholder: 'e.g. wooden floor panels in dark oak color',
				required: true,
			},
			{
				id: 'TEXT_TO_TEXTURE_SIZE',
				text: 'Texture size',
				limit: 10,
				placeholder: 'optional, by default: 512x512, one of "1024x768", "768x768", "512x512"',
			},
			{
				id: 'TEXT_TO_TEXTURE_SAMPLES',
				text: 'Samples',
				limit: 10,
				placeholder: 'optional, by default: 1, max: 5',
			},
		],
	},
	IMAGE_TO_VIDEO: {
		name: 'Image to video',
		toolIds: ['IMAGE_TO_VIDEO'],
		inputs: [
			{
				id: 'IMAGE_TO_VIDEO_FILE',
				text: 'Image',
				placeholder: '(attach image to convert to video)',
				limit: 1,
				required: true,
				dropzone: true,
				acceptFiles: {
					'image/png': ['.png'],
					'image/jpeg': ['.jpeg', '.jpg'],
				},
			},
			{
				id: 'IMAGE_TO_VIDEO_DURATION',
				text: 'Duration of generated video',
				limit: 50,
				placeholder: 'short (~2-3s) or long (~4-5s), by default: short',
			},
			{
				id: 'IMAGE_TO_VIDEO_SPEED',
				text: 'Motion speed of generated videos',
				limit: 50,
				placeholder: 'normal or fast, by default: normal',
			},
			{
				id: 'IMAGE_TO_VIDEO_FORMAT',
				text: 'Output format',
				limit: 10,
				placeholder: 'mp4 or gif, by default: mp4',
			},
		],
	},
	IMAGE_TO_TEXT: {
		name: 'Image to text',
		toolIds: ['IMAGE_INTERPRETER'],
		inputs: [
			{
				id: 'IMAGE_TO_TEXT_PROMPT',
				text: 'Image prompt',
				limit: 1000,
				placeholder: 'e.g. describe this photo or give me the number of people in this photo',
				required: true,
			},
			{
				id: 'IMAGE_TO_TEXT_FILE',
				text: 'Image to interpret',
				placeholder: '(attach image to interpreters)',
				required: true,
				dropzone: true,
				limit: 1,
				acceptFiles: {
					'image/png': ['.png'],
					'image/jpeg': ['.jpeg', '.jpg'],
				},
			},
		],
	},
	IMAGE_RESTORATION: {
		name: 'Image restoration',
		toolIds: ['IMAGE_RESTORATION'],
		inputs: [
			{
				id: 'IMAGE_RESTORATION_REQUEST',
				text: 'Image request',
				limit: 100,
				placeholder:
					'e.g. upgrade images quality, remove blur from images or colorization black and white images',
				required: true,
			},
			{
				id: 'IMAGE_RESTORATION_FILE',
				text: 'Image to restoration',
				placeholder: '(attach image to restoration)',
				limit: 1,
				required: true,
				dropzone: true,
				acceptFiles: {
					'image/png': ['.png'],
					'image/jpeg': ['.jpeg', '.jpg'],
				},
			},
		],
	},
	IMAGE_RESIZER: {
		name: 'Resize image to new width and height',
		toolIds: ['IMAGE_RESIZER'],
		inputs: [
			{
				id: 'IMAGE_RESIZER_WIDTH',
				text: 'New images width',
				limit: 10,
				placeholder: 'e.g. 1024',
			},
			{
				id: 'IMAGE_RESIZER_HEIGHT',
				text: 'New images height',
				limit: 10,
				placeholder: 'e.g. 512',
			},
			{
				id: 'IMAGE_RESIZER_ASPECT_RATIO',
				text: 'New images aspect ratio',
				limit: 10,
				placeholder: 'e.g. 16:9',
			},
			{
				id: 'IMAGE_RESIZER_ZOOM',
				text: 'Image zoom',
				limit: 10,
				placeholder: 'e.g. 150%',
			},
			{
				id: 'IMAGE_RESIZER_FILE',
				text: 'Image to resize',
				placeholder: '(attach image to resize)',
				limit: 1,
				dropzone: true,
				required: true,
				acceptFiles: {
					'image/png': ['.png'],
					'image/jpeg': ['.jpeg', '.jpg'],
				},
			},
		],
	},
	FACE_SWAP: {
		name: 'Face swap',
		toolIds: ['FACE_SWAP'],
		inputs: [
			{
				id: 'FACE_SOURCE_FILE',
				text: 'Source face photo',
				placeholder: '(attach source face photo)',
				limit: 1,
				dropzone: true,
				required: true,
				acceptFiles: {
					'image/png': ['.png'],
					'image/jpeg': ['.jpeg', '.jpg'],
				},
			},
			{
				id: 'FACE_TARGET_FILE',
				text: 'Target face photo',
				placeholder: '(attach target face photo)',
				limit: 1,
				dropzone: true,
				required: true,
				acceptFiles: {
					'image/png': ['.png'],
					'image/jpeg': ['.jpeg', '.jpg'],
				},
			},
		],
	},
	BACKGROUND_REMOVER: {
		name: 'Remove image background',
		toolIds: ['BACKGROUND_REMOVER'],
		inputs: [
			{
				id: 'BACKGROUND_REMOVER_FILE',
				text: 'Image',
				placeholder: '(attach image to remove background)',
				limit: 1,
				dropzone: true,
				required: true,
				acceptFiles: {
					'image/png': ['.png'],
					'image/jpeg': ['.jpeg', '.jpg'],
				},
			},
		],
	},
	TEXT_TO_MUSIC: {
		name: 'Text to music',
		toolIds: ['TEXT_TO_MUSIC'],
		inputs: [
			{
				id: 'TEXT_TO_MUSIC_DESCRIPTION',
				text: 'Music description',
				placeholder: 'e.g. modern Hip-Hop bit',
				limit: 1000,
				required: true,
			},
			{
				id: 'TEXT_TO_MUSIC_DURATION',
				text: 'Music duration',
				limit: 50,
				placeholder: 'e.g. 30s, by default: 5s',
			},
		],
	},
	SPEECH_TO_TEXT: {
		name: 'Audio or video files to transcription texts',
		toolIds: ['SPEECH_TO_TEXT'],
		inputs: [
			{
				id: 'SPEECH_TO_TEXT_FILE',
				text: 'Speech file',
				placeholder: '(attach speech file to transcription)',
				limit: 1,
				dropzone: true,
				required: true,
				acceptFiles: {
					'audio/mpeg': ['.mp3'],
					'video/mp4': ['.mp4'],
				},
			},
		],
	},
	TEXT_TO_SPEECH: {
		name: 'Text to speech audio file',
		toolIds: ['TEXT_TO_SPEECH'],
		inputs: [
			{
				id: 'TEXT_TO_SPEECH',
				text: 'Text to speech',
				limit: 1000,
				placeholder: 'e.g. Hello, my name is Matthew',
				required: true,
			},
		],
	},
}

export const toolIdToName: Record<ToolId, string> = {
	DALL_TEXT_TO_IMAGE: 'Dall-e',
	SDXL_TEXT_TO_IMAGE: 'SDXL image generator',
	TEXT_TO_LOGO_OR_ICON: 'Logotype generator',
	TEXT_TO_TEXTURE: 'Texture generator',
	IMAGE_TO_VIDEO: 'Image to video',
	IMAGE_INTERPRETER: 'Image to text',
	IMAGE_RESIZER: 'Image resizer',
	FACE_SWAP: 'Face swap',
	BACKGROUND_REMOVER: 'Background remover',
	TEXT_TO_MUSIC: 'Text to music',
	SPEECH_TO_TEXT: 'Speech to text',
	TEXT_TO_SPEECH: 'Text to speech',
	FILE_TYPE_CHANGER: 'File type changer',
	TRANSLATION: 'Translation',
	RESPONSE_MESSAGE_LANGUAGE: 'Get response message language',
	DETECT_LANGUAGE: 'Detect language',
	IMAGE_RESTORATION: 'Image upgrader',
	CHATBOT: 'Chatbot',
	CONTEXT: 'Building context',
	ASSISTANT: 'Assistant',
	FILE_PROCESSING: 'Processing a file',
}
