import React, { PropsWithChildren } from 'react'
import { createStateContext } from '../createStateContext'
import { EditorContent, EditorContextState, EditorValidationCode } from '../../types/EditorContext'
import { MIN_CHAT_FOOTER_HEIGHT } from '../../utils/consts'
import { TemplateTool } from '@aiapp/types/global/tools'

const editorInitialState: EditorContextState = {
	editorContent: {},
	editorFiles: {},
	toolType: undefined,
	editorHeight: MIN_CHAT_FOOTER_HEIGHT,
	canSubmit: false,
}

const EditorContext = createStateContext(editorInitialState, (setStore) => ({
	setEditorContent(inputId: string, value: string) {
		setStore((store) => {
			return {
				...store,
				editorContent: {
					...store.editorContent,
					[inputId]: value,
				},
			}
		})
	},
	addEditorFiles(inputId: string, fileIds: string[]) {
		setStore((store) => {
			return {
				...store,
				editorFiles: {
					...store.editorFiles,
					[inputId]: [...(store.editorFiles[inputId] ?? []), ...fileIds],
				},
			}
		})
	},
	removeEditorFiles(inputId: string, fileIds: string[]) {
		setStore((store) => {
			return {
				...store,
				editorFiles: {
					...store.editorFiles,
					[inputId]: (store.editorFiles[inputId] ?? []).filter(
						(fileId) => !fileIds.includes(fileId),
					),
				},
			}
		})
	},
	setToolType(toolType: TemplateTool | undefined, initEditorContent?: EditorContent) {
		setStore((store) => {
			if (toolType !== store.toolType) {
				return {
					...store,
					toolType: toolType,
					editorContent: initEditorContent ?? {},
					editorFiles: {},
					editorHeight: MIN_CHAT_FOOTER_HEIGHT,
					canSubmit: false,
				}
			}

			return {
				...store,
				editorContent: initEditorContent ?? {},
				editorHeight: MIN_CHAT_FOOTER_HEIGHT,
			}
		})
	},
	clearEditor() {
		setStore(() => {
			return {
				toolType: undefined,
				editorContent: {},
				editorFiles: {},
				editorHeight: MIN_CHAT_FOOTER_HEIGHT,
				canSubmit: false,
			}
		})
	},
	setCanSubmit(canSubmit: boolean) {
		setStore((store) => {
			return {
				...store,
				canSubmit,
			}
		})
	},
	setEditorHeight(editorHeight: number) {
		setStore((store) => {
			return {
				...store,
				editorHeight,
			}
		})
	},
	setValidationCode(validationCode: EditorValidationCode | undefined) {
		setStore((store) => {
			return {
				...store,
				validationCode,
			}
		})
	},
}))

export const EditorContextProvider = ({ children }: PropsWithChildren<{}>) => (
	<EditorContext.Provider>{children}</EditorContext.Provider>
)

export const useEditorContext = EditorContext.useContext
