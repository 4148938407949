import { FileId, MessageAttachedFileIds } from '../types/chat/chatMessage'
import { FIND_URL_REGEX, LOADING_CONTENT } from './consts'
import { Assistant } from '../types/assistant'
import { isInHoursRange, toTimezoneDate, utcDate } from './dates'

import timezones from './resources/timezones.json'

export const attachedFilesToIds = (attachedFileIds?: MessageAttachedFileIds): FileId[] =>
	Object.values(attachedFileIds ?? {}).flat()

export const messageContentIsPending = (content: string): boolean =>
	content.trim() === LOADING_CONTENT

export const messageContentIsInStatus = (content: string): boolean => {
	const regex = /^\[.*]$/
	return regex.test(content)
}

export const isMessageContentWithUrls = (messageContent: string): boolean => {
	return FIND_URL_REGEX.test(messageContent)
}

export const messageContentWithoutUrls = (messageContent: string): string => {
	return messageContent.replace(FIND_URL_REGEX, '').trim()
}

export const getUrlsFromMessage = (messageContent: string): string[] => {
	return messageContent.match(FIND_URL_REGEX)?.slice(0, 5) || []
}

export const isAssistantWork = (assistant: Assistant) => {
	if (assistant.workHours?.length === 2) {
		const assistantTimezone = assistant.timezone.split(':')
		if (assistantTimezone.length !== 2) {
			return true
		}

		const findTimezoneData = timezones.find(
			(timezoneData) => timezoneData.value === assistantTimezone[0],
		)
		if (!findTimezoneData || !findTimezoneData.utc[0]) {
			return true
		}

		const currentDate = toTimezoneDate(utcDate(), findTimezoneData.utc[0])
		return isInHoursRange(currentDate, assistant.workHours[0], assistant.workHours[1])
	}
	return true
}
