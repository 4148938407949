import React, { useState } from 'react'
import { App, Button, Tooltip, Table } from 'antd'
import { SyncOutlined } from '@ant-design/icons'
import { Payment } from '@aiapp/types/order/payment'
import { Header } from 'antd/es/layout/layout'
import { useAccountContext } from '../../../hooks/context/AccountContext'
import useTryCatch from '../../../hooks/useTryCatch'
import query from '../../../utils/query'
import useAsyncEffect from '../../../hooks/useAsyncEffect'
import { formatDate } from '@aiapp/utils/dates'
import styles from './PaymentHistory.module.scss'

const PaymentHistory = () => {
	const { message } = App.useApp()
	const tryCatch = useTryCatch(message)

	const accountContext = useAccountContext()
	const usingUserId = accountContext.useSubscribe((account) => account.usingUserId)

	const [payments, setPayments] = useState<Payment[]>([])
	const [loading, setLoading] = useState<boolean>(true)

	const loadPayments = async () => {
		setLoading(true)
		await tryCatch(async () => {
			const payments = await query<Payment[]>('/order/payments', 'GET', {
				params: { usingUserId },
			})
			setPayments(payments)
		})
		setLoading(false)
	}

	const retryPayment = async (paymentId: string) => {
		await tryCatch(async () => {
			await query('/order/payment/retry', 'POST', {
				data: { paymentId },
			})
			message.open({
				type: 'success',
				content: 'Payment retry initiated',
			})
			await loadPayments() // reload payments after retry
		})
	}

	useAsyncEffect(loadPayments, [])

	const columns = [
		{
			title: 'Payment ID',
			dataIndex: 'id',
			key: 'id',
		},
		{
			title: 'Status',
			dataIndex: 'status',
			key: 'status',
		},
		{
			title: 'Amount',
			dataIndex: 'amount',
			key: 'amount',
			render: (price: number, record: Payment) => `${(price / 100).toFixed(2)} ${record.currency}`,
		},
		{
			title: 'Date',
			dataIndex: 'createdAt',
			key: 'createdAt',
			render: (createdAt: string) => formatDate(new Date(createdAt)),
		},
		{
			title: 'Action',
			key: 'action',
			render: (_: any, record: Payment) =>
				(record.status === 'refused' ||
					record.status === 'expired' ||
					record.status === 'cancelled') && (
					<Button type='primary' onClick={() => retryPayment(record.id)} disabled={loading}>
						Retry Payment
					</Button>
				),
		},
	]

	return (
		<div className={styles.container}>
			<Header className={styles.header}>
				<div className={styles.headerElements} />
				<Tooltip trigger={['hover', 'focus']} title='Refresh'>
					<Button
						className={styles.headerRefresh}
						onClick={loadPayments}
						shape='circle'
						icon={<SyncOutlined />}
						loading={loading}
					/>
				</Tooltip>
			</Header>

			<div>
				<h3 className={styles.headingLabel}>Payment history</h3>
				<Table columns={columns} dataSource={payments} loading={loading} rowKey='id' />
			</div>
		</div>
	)
}

export default PaymentHistory
