import { Form } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import styles from './Forms.module.scss'

const PromotionOffer = () => {
	return (
		<Form.Item
			rules={[
				{
					message: 'Promotion offer description can have a maximum of 1500 characters',
					max: 1500,
				},
			]}
			tooltip='Describe if you have a promotional/discount offer, along with a description of what offers it applies to and what conditions must be met.'
			className={styles.formItem}
			label='Promotion offer'
			name='promotionsDescription'
		>
			<TextArea
				className={styles.formTextarea}
				size='large'
				allowClear
				count={{
					show: true,
				}}
				maxLength={1500}
				autoSize={{ minRows: 2, maxRows: 15 }}
				placeholder='Description of the promotion offer'
			/>
		</Form.Item>
	)
}

export default PromotionOffer
