import React, { PropsWithChildren } from 'react'
import { createStateContext } from '../createStateContext'
import { Account } from '../../types/Account'
import { useLocation, useNavigate } from 'react-router-dom'
import useQuery from '../useQuery'
import useAsyncEffect from '../useAsyncEffect'
import query from '../../utils/query'
import { Offer } from '@aiapp/types/account'

const accountInitialState: Account = {
	email: '',
	firstName: '',
	lastName: '',
	phone: '',
	active: undefined,
	roles: [],
	offer: {
		orderIds: [],
		availableRoles: [],
		availablePersonas: 0,
	},
	modals: {},
	loaded: false,
}

const AccountContext = createStateContext(accountInitialState, (setStore) => ({
	updateAccount(account: Partial<Account>) {
		setStore((store) => {
			return {
				...store,
				...account,
			}
		})
	},
	setUsingUserId(userId: string | undefined) {
		setStore((store) => {
			if (userId) {
				localStorage.setItem('usingUserId', userId)
			} else {
				localStorage.removeItem('usingUserId')
			}

			return {
				...store,
				usingUserId: userId,
			}
		})
	},
	loaded() {
		setStore((store) => ({ ...store, loaded: true }))
	},
	logout() {
		setStore(() => {
			localStorage.removeItem('usingUserId')
			return accountInitialState
		})
	},
}))

const UpdateAccountContextState = () => {
	const navigate = useNavigate()
	const location = useLocation()
	const queryParams = useQuery()

	const queryUsingUserId = queryParams.get('usingUserId')
	const storageUsingUserId = localStorage.getItem('usingUserId')

	const accountContext = useAccountContext()
	const accountId = accountContext.useSubscribe((account) => account.id)
	const contextUsingUserId = accountContext.useSubscribe((account) => account.usingUserId)

	useAsyncEffect(async () => {
		if (!accountId) {
			return
		}

		const userOffer = await query<Offer>('/account/offer', 'GET', {
			params: {
				userId: contextUsingUserId ? contextUsingUserId : accountId,
			},
			withCredentials: true,
		})

		accountContext.updateAccount({ offer: userOffer })
	}, [contextUsingUserId, accountId])

	useAsyncEffect(async () => {
		if (location.pathname.startsWith('/admin') || location.pathname.startsWith('/broker')) {
			accountContext.setUsingUserId(undefined)
			return
		}

		if (queryUsingUserId && contextUsingUserId !== queryUsingUserId) {
			accountContext.setUsingUserId(queryUsingUserId)
			return
		}

		if (storageUsingUserId && contextUsingUserId !== storageUsingUserId) {
			accountContext.setUsingUserId(storageUsingUserId)
			return
		}

		if (contextUsingUserId && !location.search.includes(contextUsingUserId)) {
			if (location.search.length) {
				navigate({ search: `${location.search}&usingUserId=${contextUsingUserId}` })
			} else {
				navigate({ search: `?usingUserId=${contextUsingUserId}` })
			}
		}
	}, [location.pathname, location.search, contextUsingUserId, storageUsingUserId])

	return <></>
}

export const AccountContextProvider = ({ children }: PropsWithChildren<{}>) => (
	<AccountContext.Provider>
		<UpdateAccountContextState />
		{children}
	</AccountContext.Provider>
)

export const useAccountContext = AccountContext.useContext
