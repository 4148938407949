import { ReactNode } from 'react'
import styles from './LoginWrapper.module.scss'

type Props = {
	children: ReactNode
	width: number
}

const LoginWrapper = ({ children, width }: Props) => {
	return (
		<div className={styles.container}>
			<div style={{ maxWidth: width }} className={styles.wrapper}>
				<h2 className={styles.heading}>Quantum Neuron</h2>
				{children}
			</div>
		</div>
	)
}

export default LoginWrapper
