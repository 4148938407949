import CalendlyWidget from './CalendlyWidget/CalendlyWidget'
import { useAccountContext } from '../../../hooks/context/AccountContext'
import { App } from 'antd'
import styles from './Appointment.module.scss'

const Appointment = () => {
	const { message } = App.useApp()

	const accountContext = useAccountContext()
	const accountEmail = accountContext.useSubscribe((account) => account.email)

	const copyEmail = async () => {
		await navigator.clipboard.writeText(accountEmail)

		message.open({
			type: 'success',
			content: 'E-mail copied',
		})
	}

	return (
		<div className={styles.container}>
			<h2 className={styles.appointmentHeading}>Wybierz termin spotkania wdrożeniowego</h2>
			<div className={styles.appointmentInfo}>
				Podczas rezerwacji terminu spotkania użyj tego adresu e-mail:{' '}
				<span onClick={copyEmail} className={styles.accountEmail}>
					{accountEmail}
				</span>
			</div>

			<CalendlyWidget />
		</div>
	)
}

export default Appointment
