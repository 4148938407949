import FormRow from '../../../../../../components/FormRow/FormRow'
import { Form, Radio, Slider, Switch } from 'antd'
import styles from './Forms.module.scss'

type Props = {
	followupInterval: number | boolean | undefined
}

const FollowupsConfig = ({ followupInterval }: Props) => {
	return (
		<FormRow columns={[1, 3, 2]}>
			<Form.Item name='followupInterval' label='Automatic follow-ups' className={styles.formItem}>
				<Switch />
			</Form.Item>

			{!!followupInterval && (
				<>
					<Form.Item
						tooltip="This role will be send a message reminding client about the conversation after the specified number of hours since the user's last message."
						className={styles.formItem}
						rules={[{ required: true, message: 'Please set follow-ups interval' }]}
						label='Follow-ups interval'
						name='followupInterval'
					>
						<Slider
							disabled={!followupInterval}
							min={1}
							max={23}
							tooltip={{
								formatter: (value) =>
									value ? `${value} ${value > 1 ? 'hours' : 'hour'}` : '1 hour',
							}}
						/>
					</Form.Item>

					<Form.Item
						rules={[{ required: true, message: 'Please select maximum follow-ups' }]}
						tooltip="Select the maximum number of follow-ups the Persona can send within a 24-hour window from the user's last message."
						className={styles.formItem}
						label='Maximum follow-ups'
						name='maxFollowups'
					>
						<Radio.Group disabled={!followupInterval} size='large'>
							<Radio.Button value={1}>1</Radio.Button>
							<Radio.Button value={2}>2</Radio.Button>
							<Radio.Button value={3}>3</Radio.Button>
							<Radio.Button value={4}>4</Radio.Button>
							<Radio.Button value={5}>5</Radio.Button>
						</Radio.Group>
					</Form.Item>
				</>
			)}
		</FormRow>
	)
}

export default FollowupsConfig
