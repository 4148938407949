import React, { useEffect, useRef, useState } from 'react'
import { Tab, TextContent } from '../../../../types/AiContext'
import styles from './ContextTextarea.module.scss'
import { MAX_TEXT_CONTENT_LENGTH } from '@aiapp/types/context'

type Props = {
	updateTab: (newTab: Partial<Tab>) => void
	content: TextContent
}

const ContextTextarea = ({ updateTab, content }: Props) => {
	const [rowCount, setRowCount] = useState(1)
	const textareaRef = useRef<HTMLTextAreaElement>(null)

	useEffect(() => {
		const textarea = textareaRef.current

		if (textarea) {
			const lineHeight = parseFloat(getComputedStyle(textarea).lineHeight)
			const textareaHeight = textarea.scrollHeight
			const calculatedRowCount = Math.floor(textareaHeight / lineHeight)
			setRowCount(calculatedRowCount)
		}
	}, [content.text, textareaRef.current])

	useEffect(() => {
		const textarea = textareaRef.current
		if (textarea) {
			textarea.style.height = 'auto'
			textarea.style.height = `${textarea.scrollHeight}px`
		}
	}, [content.text])

	return (
		<div className={styles.textareaContainer}>
			<div className={styles.textareaWrapper}>
				<div className={styles.textareaLineNumber}>
					{Array.from({ length: rowCount }).map((_, index) => (
						<span key={index}>{index + 1}</span>
					))}
				</div>

				<textarea
					ref={textareaRef}
					className={styles.textarea}
					placeholder='Plain text context...'
					onChange={(e) => {
						updateTab({ content: { text: e.target.value } })
					}}
					value={content.text}
				/>
			</div>

			<div className={styles.textareaTextLength}>
				Characters:{' '}
				<span
					className={`${content.text.length > MAX_TEXT_CONTENT_LENGTH ? styles.tooLongText : ''}`}
				>
					{content.text.length}
				</span>{' '}
				/ {MAX_TEXT_CONTENT_LENGTH}
			</div>
		</div>
	)
}

export default ContextTextarea
