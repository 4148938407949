import { Form } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import styles from './Forms.module.scss'

const AdditionalInformation = () => {
	return (
		<Form.Item
			rules={[
				{ message: 'Additional information can have a maximum of 5000 characters', max: 5000 },
			]}
			className={styles.formItem}
			label='Additional information'
			name='additionalInformation'
		>
			<TextArea
				className={styles.formTextarea}
				size='large'
				allowClear
				count={{
					show: true,
				}}
				maxLength={5000}
				autoSize={{ minRows: 2, maxRows: 50 }}
				placeholder='Additional information about this Persona role'
			/>
		</Form.Item>
	)
}

export default AdditionalInformation
