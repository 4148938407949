import FormRow from '../../../../../../components/FormRow/FormRow'
import { Form, Radio } from 'antd'
import styles from './Forms.module.scss'

const ConversationConfig = () => {
	return (
		<FormRow>
			<Form.Item
				rules={[{ required: true, message: 'Please select conversation style' }]}
				className={styles.formItem}
				label='Conversation style'
				name='conversationStyle'
			>
				<Radio.Group size='large'>
					<Radio.Button value='casual'>Casual</Radio.Button>
					<Radio.Button value='balanced'>Balanced</Radio.Button>
					<Radio.Button value='official'>Official</Radio.Button>
				</Radio.Group>
			</Form.Item>

			<Form.Item
				rules={[{ required: true, message: 'Please select response length' }]}
				className={styles.formItem}
				label='Response length'
				name='responseLength'
			>
				<Radio.Group size='large'>
					<Radio.Button value='short'>Short</Radio.Button>
					<Radio.Button value='balanced'>Balanced</Radio.Button>
					<Radio.Button value='long'>Long</Radio.Button>
				</Radio.Group>
			</Form.Item>
		</FormRow>
	)
}

export default ConversationConfig
