import { Form, Select } from 'antd'
import { DEFAULT_GMAIL_LABELS } from '@aiapp/types/integration/gmail'
import React from 'react'
import styles from './GmailConfig.module.scss'

const GmailConfig = () => {
	return (
		<Form.Item
			name={['config', 'activeLabels']}
			label='Active e-mail labels'
			className={styles.formItem}
			tooltip='Select the names of mail labels to be handled by the integration.'
		>
			<Select
				size='large'
				mode='tags'
				showSearch
				maxLength={10}
				tokenSeparators={[',']}
				options={DEFAULT_GMAIL_LABELS.map((provider) => ({
					label: provider,
					value: provider,
				}))}
			/>
		</Form.Item>
	)
}

export default GmailConfig
