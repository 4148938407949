export interface Agreement {
	id: string
	userId: string
	status: AgreementStatus
	businessTurnoverLevel: BusinessTurnoverLevel
	enterprise: boolean
	startAt: Date
	endAt: Date
	hasUpsell?: boolean
	createdAt: Date
}

export const BUSINESS_TURNOVER_LEVELS = [1, 2, 3, 4] as const
export type BusinessTurnoverLevel = (typeof BUSINESS_TURNOVER_LEVELS)[number]

export const AGREEMENT_STATUSES = ['draft', 'active', 'inactive', 'cancelled', 'ended'] as const
export type AgreementStatus = (typeof AGREEMENT_STATUSES)[number]

export type CreateAgreementRequest = {
	userId: string
	businessTurnoverLevel: BusinessTurnoverLevel
	enterprise: boolean
	trainingDays: number
	trainingAdditionalCost: number
	startAt: Date
}

export type PublicUpdateAgreementRequest = {
	id: string
	startAt?: Date
	endAt?: Date
}

export type PrivateUpdateAgreementRequest = {
	id: string
	status?: AgreementStatus
	hasUpsell?: boolean
}
