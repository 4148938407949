import styles from './PrivacyPolicy.module.scss'

const PrivacyPolicy = () => {
	return (
		<div className={styles.container}>
			<div className={styles.wrapper}>
				<h1>Privacy Policy for staging.quantumneuron.eu</h1>
				<h3>1. Introduction</h3>
				<p>
					This Privacy Policy is intended to inform users of the staging.quantumneuron.eu platform,
					managed by Quantum Neuron (hereinafter referred to as &quot;we&quot;, &quot;us&quot; or
					&quot;our&quot;), about the rules for processing personal data in accordance with the
					General Data Protection Regulation (GDPR). The platform deals with the creation of
					chatbots and AI Personas and is only available to those who have previously contacted our
					company and agreed to test our services.
				</p>
				<h3>2. Personal Data</h3>
				<p>
					We collect the following personal information through the login and registration form:
					<ul>
						<li>Email address</li>
						<li>Password</li>
					</ul>
				</p>
				<h3>3. Purpose of Data Processing</h3>
				<p>
					Personal data is processed for the sole purpose of:
					<ul>
						<li>Enabling access to our platform and its functionalities.</li>
						<li>To manage the user&apos;s account.</li>
						<li>Contacting the user on matters related to the service.</li>
					</ul>
				</p>
				<h3>4. Data Protection</h3>
				<p>
					All personal data is protected and encrypted in accordance with current security
					standards. We do not collect any additional user data for marketing or other similar
					purposes.
				</p>
				<h3>5. Cookies</h3>
				<p>
					The site only stores cookies required to maintain a logged - in user&apos;s session. We do
					not use cookies to track user activities outside of our site.
				</p>
				<h3>6. User Rights</h3>
				<p>
					Each user has the right to:
					<ul>
						<li>Access to his/her personal data.</li>
						<li>Correct his/her personal data.</li>
						<li>Deletion of his/her personal data (&quot;right to be forgotten&quot;).</li>
						<li>Restrict the processing of his/her personal data.</li>
						<li>Transfer of your personal data.</li>
					</ul>
				</p>
				<h3>7. Deletion of Data</h3>
				<p>
					The user may at any time irretrievably delete his/her data from our platform by contacting
					us via e-mail address:{' '}
					<a href='mailto:contact@quantumneuron.eu'>contact@quantumneuron.eu</a>.
				</p>
				<h3>8. Contact</h3>
				<p>
					If you have any questions about the processing of your personal data, please contact us
					via e-mail: <a href='mailto:contact@quantumneuron.eu'>contact@quantumneuron.eu</a>.
				</p>
				<h3>9. Changes to the Privacy Policy</h3>
				<p>
					We reserve the right to make changes to the Privacy Policy. We will inform you of any
					changes via our website.
				</p>
				<h4>Company Details:</h4>
				<p>
					<div>Name: QUANTUM MEDIA GROUP POLAND</div>
					<div>TAX ID: 5223225951</div>
					<div>Address: Szczęsna 26, 02-454 Warszawa</div>
					<div>Country: Poland</div>
					<div>
						Contact e-mail: <a href='mailto:contact@quantumneuron.eu'>contact@quantumneuron.eu</a>
					</div>
				</p>
				Effective Date: <b>10.04.2024</b>.
				<br />
				---
				<br />
				<p>
					This Privacy Policy complies with the current requirements of the RODO. Please note that
					this is a sample privacy policy and you should consult with a data protection lawyer
					before implementing it to ensure that it meets all legal requirements appropriate to your
					specific situation.
				</p>
			</div>
		</div>
	)
}

export default PrivacyPolicy
