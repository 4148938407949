import { Button, Form, Input, Select } from 'antd'
import { AssistantRole } from '@aiapp/types/assistant/assistantRole'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { useEffect } from 'react'
import Joi from 'joi'
import { prepareAssistantData } from '../../Role'
import { useAssistantRoleContext } from '../../../../../../hooks/context/AssistantRoleContext'
import { formValidate } from '../../../../../../utils/validation'
import FormRow from '../../../../../../components/FormRow/FormRow'
import { SELLER_OFFERS_GOALS, SellerOffersGoal } from '@aiapp/types/assistant/roles/sellerOffers'
import { createSellerOffersRoleSchema } from '@aiapp/schemas/assistant/roles/sellerOffers'
import RoleHeader from '../../components/RoleHeader/RoleHeader'
import { useParams } from 'react-router-dom'
import { prepareRoleFormValues } from '../../utils'
import FormHeading from '../../components/FormHeading/FormHeading'
import SystemName from '../../components/Forms/SystemName'
import CompanyOffer from '../../components/Forms/CompanyOffer'
import PromotionOffer from '../../components/Forms/PromotionOffer'
import AdditionalInformation from '../../components/Forms/AdditionalInformation'
import EmojiConfig from '../../components/Forms/EmojiConfig'
import ConversationConfig from '../../components/Forms/ConversationConfig'
import FollowupsConfig from '../../components/Forms/FollowupsConfig'
import WarmupLevel from '../../components/Forms/WarmupLevel'
import NaturalizationBooster from '../../components/Forms/NaturalizationBooster'
import RedirectConditions from '../../components/Forms/RedirectConditions'
import { roles } from '../../../../../../utils/roles'
import styles from './SellerOffers.module.scss'

const DEFAULT_SELLER_OFFERS_ROLE_DATA: Partial<AssistantRole> = {
	type: 'sellerOffers',
	disabledEmoji: false,
	conversationStyle: 'balanced',
	responseLength: 'balanced',
	warmupLevel: 2,
	followupInterval: 5,
	maxFollowups: 2,
	goal: 'offersUrls',
	naturalizationBooster: false,
}

const SellerOffersRoleForm = () => {
	const [form] = Form.useForm()

	const { assistantRoleId } = useParams()

	const assistantRoleContext = useAssistantRoleContext()
	const assistantRoleData = assistantRoleContext.useSubscribe((context) => context.data)

	useEffect(() => {
		if (!assistantRoleId) {
			assistantRoleContext.updateData(DEFAULT_SELLER_OFFERS_ROLE_DATA)
		}
	}, [assistantRoleId])

	useEffect(() => {
		checkFormValidate()
	}, [assistantRoleData])

	useEffect(() => {
		form.resetFields()
	}, [
		assistantRoleData.id,
		assistantRoleData.type,
		'followupInterval' in assistantRoleData && !!assistantRoleData.followupInterval,
	])

	const updateAssistantRoleData = (value: Record<string, any>, values: any) => {
		prepareRoleFormValues(value, values)
		assistantRoleContext.updateData(values)
	}

	const checkFormValidate = () => {
		const validate = formValidate(prepareAssistantData(assistantRoleData), assistantRoleSchema)
		assistantRoleContext.setValidStep(validate ? !Object.keys(validate).length : true)
	}

	const roleInfo = roles.flat().find((role) => role.key === 'sellerOffers')!

	return (
		<Form
			form={form}
			autoComplete='true'
			initialValues={assistantRoleData}
			onValuesChange={updateAssistantRoleData}
			labelCol={{ span: 4 }}
			wrapperCol={{ span: 14 }}
			className={styles.form}
			layout='vertical'
		>
			<RoleHeader
				avatar={roleInfo.avatar}
				name={roleInfo.name}
				description={roleInfo.description}
			/>

			<FormHeading title='Base information' />

			<SystemName />

			<CompanyOffer />

			<PromotionOffer />

			<AdditionalInformation />

			<EmojiConfig disabledEmoji={assistantRoleData.disabledEmoji} />

			<Form.Item
				rules={[{ required: true, message: 'Please select Persona role goal' }]}
				name='goal'
				label='Selling goal'
				className={styles.formItem}
			>
				<Select
					size='large'
					showSearch
					options={SELLER_OFFERS_GOALS.map((goal) => ({
						label: sellerOffersGoalToLabel[goal],
						value: goal,
					}))}
				/>
			</Form.Item>

			{'goal' in assistantRoleData && assistantRoleData.goal === 'offersUrls' && (
				<Form.Item
					required
					tooltip='Enter a link to buy the sale offer. The Persona will send this link to the customer at the final stage of the conversation.'
					className={styles.formItem}
					label='Selling links'
				>
					<Form.List
						initialValue={
							'offersUrls' in assistantRoleData && assistantRoleData.offersUrls
								? undefined
								: [{ url: '' }]
						}
						name='offersUrls'
					>
						{(fields, { add, remove }) => (
							<>
								{fields.map(({ key, name, ...restField }) => (
									<div key={key} className={styles.fieldList}>
										<FormRow columns={[1, 1]}>
											<Form.Item
												{...restField}
												rules={[
													{ required: true, message: 'Please fill selling link' },
													{
														type: 'url',
														message: 'Please fill correct link',
													},
													{
														message: 'Selling link must have a minimum of 6 characters',
														min: 6,
													},
													{
														message: 'Selling link can have a maximum of 1000 characters',
														max: 1000,
													},
												]}
												className={styles.formItem}
												name={[name, 'url']}
											>
												<Input
													maxLength={1000}
													size='large'
													className={styles.formInput}
													placeholder='https://quantumneuron.eu/offer'
												/>
											</Form.Item>

											<Form.Item
												{...restField}
												rules={[
													{
														required: true,
														message: 'Please fill selling link usage context',
													},
													{
														message:
															'Selling link usage context must have a minimum of 6 characters',
														min: 6,
													},
													{
														message:
															'Selling link usage context can have a maximum of 500 characters',
														max: 500,
													},
												]}
												className={styles.formItem}
												name={[name, 'context']}
											>
												<Input
													size='large'
													count={{
														show: true,
													}}
													maxLength={500}
													className={styles.formInput}
													placeholder='Use this link for customers interested in XYZ offerings'
												/>
											</Form.Item>
										</FormRow>
										{key !== 0 && (
											<MinusCircleOutlined
												className={styles.listRemoveIcon}
												onClick={() => remove(name)}
											/>
										)}
									</div>
								))}
								<Form.Item className={styles.formItem}>
									<Button
										size='large'
										type='dashed'
										disabled={fields.length === 5}
										onClick={() => {
											fields.length < 5 && add()
										}}
										block
										icon={<PlusOutlined />}
									>
										Add another selling link
									</Button>
								</Form.Item>
							</>
						)}
					</Form.List>
				</Form.Item>
			)}

			<FormHeading title='Role settings' />

			<ConversationConfig />

			<FollowupsConfig
				followupInterval={
					'followupInterval' in assistantRoleData ? assistantRoleData.followupInterval : undefined
				}
			/>

			<WarmupLevel
				warmupLevel={'warmupLevel' in assistantRoleData ? assistantRoleData.warmupLevel : undefined}
			/>

			<FormHeading title='Advanced settings' />

			<NaturalizationBooster />
			<RedirectConditions />
		</Form>
	)
}

const sellerOffersGoalToLabel: Record<SellerOffersGoal, string> = {
	offersUrls: 'Selling via link to offer',
}

const assistantRoleSchema = Joi.object({
	...createSellerOffersRoleSchema,
	id: Joi.any().optional(),
}).required()

export default SellerOffersRoleForm
