import { Form, Radio } from 'antd'
import styles from './Forms.module.scss'

const NaturalizationBooster = () => {
	return (
		<Form.Item
			rules={[{ required: true, message: 'Please select any option' }]}
			className={styles.formItem}
			label='Human private communication booster'
			name='naturalizationBooster'
			tooltip='Persona messages will be allowed to contain small grammatical and punctuation errors dosotated to the conversation style.'
		>
			<Radio.Group size='large'>
				<Radio.Button value={true}>Enabled</Radio.Button>
				<Radio.Button value={false}>Disabled</Radio.Button>
			</Radio.Group>
		</Form.Item>
	)
}

export default NaturalizationBooster
