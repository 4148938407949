import { Avatar } from 'antd'
import {
	CANCEL_CONTENT,
	COST_LIMIT_CONTENT,
	ERROR_CONTENT,
	LOADING_CONTENT,
	DELETED_MESSAGE_CONTENT,
	messageRole,
	NOT_SUPPORTED_MESSAGE_CONTENT,
} from '@aiapp/utils/consts'
import { RobotOutlined, UserOutlined } from '@ant-design/icons'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import MessageContent from './MessageContent/MessageContent'
import MessageTooltip from './MessageTooltip/MessageTooltip'
import { useDebounced } from '../../../../utils/helpers'
import { formatDate } from '@aiapp/utils/dates'
import { Fade } from 'react-awesome-reveal'
import { useChatContext } from '../../../../hooks/context/ChatContext'
import { PublicMessage } from '../../../../types/Chat'
import MessageWrapper from './MessageWrapper/MessageWrapper'
import styles from './Message.module.scss'

type Props = {
	message: PublicMessage
	isLastMessage: boolean
	attachFilesToMessage: (files: Array<string | File>) => Promise<string[]>
	lastMessageChildren: PublicMessage | undefined
	scrollToBottom: () => void
	onSetMessageInfo: (message: PublicMessage) => void
	resendMessage?: (retryMessage: PublicMessage) => Promise<void>
	cancelMessage?: (messageId: string) => Promise<void>
	deleteAssistantMessage?: (deleteMessage: PublicMessage) => Promise<void>
}

const MESSAGE_WITH_FILE_MAX_WIDTH = '45%'
const TEXT_MESSAGE_MAX_WIDTH = '60%'

const Message = ({
	message,
	isLastMessage,
	attachFilesToMessage,
	resendMessage,
	cancelMessage,
	scrollToBottom,
	onSetMessageInfo,
	lastMessageChildren,
	deleteAssistantMessage,
}: Props) => {
	const debounced = useDebounced()

	const chatContext = useChatContext()
	const isAssistant = chatContext.useSubscribe((context) => context.chat?.isAssistant)
	const currentChatSession = chatContext.useSubscribe((context) => context.currentChatSession)
	const disableNewMessage = chatContext.useSubscribe((context) => context.disableNewMessage)
	const progressSteps = chatContext.useSubscribe((context) => context.progressSteps)

	const [removeAnimation, setRemoveAnimation] = useState<boolean>(false)
	const [showTooltip, setShowTooltip] = useState<boolean>(false)
	const [isHoveredBelowHalf, setIsHoveredBelowHalf] = useState<boolean>(false)

	const messageContentRef = useRef<any>()

	useEffect(() => {
		if (messageContentRef.current) {
			setTimeout(() => setRemoveAnimation(true), 500)
		}
	}, [messageContentRef.current])

	const onShowTooltip = (e: any) => {
		if (!messageContentRef.current) {
			return
		}

		const { top, height } = messageContentRef.current.getBoundingClientRect()
		const middle = top + height / 2

		if (e.clientY > middle) {
			setIsHoveredBelowHalf(true)
		} else {
			setIsHoveredBelowHalf(false)
		}

		setShowTooltip(true)
	}

	const showMessageTooltip = useMemo(() => {
		const currentMessage = lastMessageChildren ?? message
		return (
			currentMessage.role !== messageRole.SYSTEM &&
			![NOT_SUPPORTED_MESSAGE_CONTENT, DELETED_MESSAGE_CONTENT].includes(
				currentMessage.content as any,
			)
		)
	}, [lastMessageChildren, message])

	const showMessageDate = useMemo(() => {
		const currentMessage = lastMessageChildren ?? message
		return currentMessage.role !== messageRole.SYSTEM && currentMessage.content !== LOADING_CONTENT
	}, [lastMessageChildren, message])

	const isErrorMessage = useMemo(() => {
		const currentMessage = lastMessageChildren ?? message
		return [ERROR_CONTENT, COST_LIMIT_CONTENT].includes(currentMessage.content as any)
	}, [lastMessageChildren, message])

	return useMemo(() => {
		if (message.parentMessageId) {
			return <></>
		}

		const currentMessage = lastMessageChildren ?? message
		return (
			<Fade
				style={removeAnimation ? { animation: 'unset' } : {}}
				direction='up'
				triggerOnce
				duration={750}
			>
				<MessageWrapper
					message={currentMessage}
					progressSteps={progressSteps}
					isLastMessage={isLastMessage}
				>
					<div
						className={`
					${styles.messageBox}
					${currentMessage.role === messageRole.USER ? styles.userMessageBox : styles.assistantMessageBox}
				`}
					>
						<Avatar
							className={`
						${isErrorMessage ? styles.errorMessage : ''}
						${currentMessage.content === CANCEL_CONTENT ? styles.stopMessage : ''}
					`}
							size={32}
							icon={currentMessage.role === messageRole.USER ? <UserOutlined /> : <RobotOutlined />}
						/>

						<div
							ref={messageContentRef}
							onMouseMove={(e) => showMessageTooltip && debounced(() => onShowTooltip(e), 10)}
							onMouseLeave={() => showMessageTooltip && debounced(() => setShowTooltip(false), 50)}
							style={{
								maxWidth: currentMessage.attachedFileIds
									? MESSAGE_WITH_FILE_MAX_WIDTH
									: TEXT_MESSAGE_MAX_WIDTH,
							}}
							className={styles.messageContentContainer}
						>
							{showMessageTooltip && (
								<MessageTooltip
									chatMessage={currentMessage}
									position={isHoveredBelowHalf ? 'bottom' : 'top'}
									attachFilesToMessage={attachFilesToMessage}
									showTooltip={showTooltip}
									onSetMessageInfo={onSetMessageInfo}
									onResendMessage={resendMessage ? () => resendMessage(message) : undefined}
									onCancelAssistantMessage={
										cancelMessage ? () => cancelMessage(message.id) : undefined
									}
									onDeleteAssistantMessage={
										deleteAssistantMessage ? () => deleteAssistantMessage(message) : undefined
									}
								/>
							)}

							<MessageContent
								chatMessage={message}
								resendMessage={resendMessage}
								scrollToBottom={scrollToBottom}
								lastMessageChildren={lastMessageChildren}
							/>

							{showMessageDate && (
								<div
									className={`${styles.createdAt} ${
										currentMessage.role === messageRole.USER
											? styles.userCreatedAt
											: styles.assistantCreatedAt
									}`}
								>
									{formatDate(
										currentMessage.sentAt ? currentMessage.sentAt : currentMessage.createdAt,
										true,
									)}
								</div>
							)}
						</div>
					</div>
				</MessageWrapper>
			</Fade>
		)
	}, [
		message,
		isLastMessage,
		lastMessageChildren,
		progressSteps,
		currentChatSession,
		disableNewMessage,
		isHoveredBelowHalf,
		showTooltip,
		isAssistant,
	])
}

export default Message
