import { InfoCircleOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'
import { LLMName } from '@aiapp/types/global'
import styles from './LLMModelMapper.module.scss'

type Props = {
	llmModel: LLMName
	simple?: boolean
}

const LLMModelMapper = ({ llmModel, simple = false }: Props) => {
	if (llmModel === 'gpt-4o') {
		return (
			<span>
				GPT-4o{' '}
				{!simple && (
					<>
						(expensive, but most efficient and advanced)
						<Tooltip title='Recommended for complex contexts, large prompts and difficult tasks'>
							<InfoCircleOutlined className={styles.infoIcon} />
						</Tooltip>
					</>
				)}
			</span>
		)
	}
	if (llmModel === 'gpt-4o mini') {
		return (
			<span>
				GPT-4o mini{' '}
				{!simple && (
					<>
						(fast and cheapest)
						<Tooltip title='Recommended for common and fast tasks'>
							<InfoCircleOutlined className={styles.infoIcon} />
						</Tooltip>
					</>
				)}
			</span>
		)
	}
	if (llmModel === 'gpt-3.5-turbo') {
		return (
			<span>
				GPT-3.5 Turbo+{' '}
				{!simple && (
					<>
						(optimized for most common tasks)
						<Tooltip title='Recommended for most common tasks used with medium-sized contexts and prompts'>
							<InfoCircleOutlined className={styles.infoIcon} />
						</Tooltip>
					</>
				)}
			</span>
		)
	}

	return <></>
}

export default LLMModelMapper
