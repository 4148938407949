import Joi from 'joi'
import { assistantRoleBaseSchema, GoalUrlSchema } from '../assistantRole'
import { usingUserIdSchema, uuidIdSchema } from '../../global'
import { HELPDESK_UPSELL_GOALS } from '@aiapp/types/assistant/roles/helpdeskUpsell'
import { appointmentSetterRoleSchema } from './appointmentSetter'

const helpdeskUpsellRoleSchema = {
	...assistantRoleBaseSchema,
	type: Joi.string().valid('helpdeskUpsell').required(),
	companyOffer: Joi.string().max(3000).required(),
	promotionsDescription: Joi.string().max(1500),
	goal: Joi.string()
		.valid(...HELPDESK_UPSELL_GOALS)
		.required(),
	offersUrls: Joi.any().when('goal', {
		is: 'offersUrls',
		then: Joi.array().items(GoalUrlSchema).min(1).max(5),
		otherwise: Joi.any().forbidden(),
	}),
}

export const createHelpdeskUpsellRoleSchema = {
	assistantId: helpdeskUpsellRoleSchema.assistantId.required(),
	name: helpdeskUpsellRoleSchema.name.required(),
	additionalInformation: helpdeskUpsellRoleSchema.additionalInformation.optional(),
	naturalizationBooster: helpdeskUpsellRoleSchema.naturalizationBooster.optional(),
	redirectConditions: helpdeskUpsellRoleSchema.redirectConditions.optional(),

	type: helpdeskUpsellRoleSchema.type.required(),
	companyOffer: helpdeskUpsellRoleSchema.companyOffer.required(),
	promotionsDescription: appointmentSetterRoleSchema.promotionsDescription.optional(),
	disabledEmoji: helpdeskUpsellRoleSchema.disabledEmoji.required(),
	preferredEmoji: helpdeskUpsellRoleSchema.preferredEmoji.optional(),
	followupInterval: helpdeskUpsellRoleSchema.followupInterval.optional(),
	maxFollowups: helpdeskUpsellRoleSchema.maxFollowups.optional(),
	conversationStyle: helpdeskUpsellRoleSchema.conversationStyle.required(),
	responseLength: helpdeskUpsellRoleSchema.responseLength.required(),
	goal: helpdeskUpsellRoleSchema.goal.required(),
	offersUrls: helpdeskUpsellRoleSchema.offersUrls.required(),
	...usingUserIdSchema,
}

export const updateHelpdeskUpsellRoleSchema = {
	id: uuidIdSchema.required(),
	assistantId: helpdeskUpsellRoleSchema.assistantId.required(),
	name: helpdeskUpsellRoleSchema.name.optional(),
	additionalInformation: helpdeskUpsellRoleSchema.additionalInformation.optional(),
	naturalizationBooster: helpdeskUpsellRoleSchema.naturalizationBooster.optional(),
	redirectConditions: helpdeskUpsellRoleSchema.redirectConditions.optional(),

	type: helpdeskUpsellRoleSchema.type.optional(),
	companyOffer: helpdeskUpsellRoleSchema.companyOffer.optional(),
	promotionsDescription: appointmentSetterRoleSchema.promotionsDescription.optional(),
	disabledEmoji: helpdeskUpsellRoleSchema.disabledEmoji.optional(),
	preferredEmoji: helpdeskUpsellRoleSchema.preferredEmoji.optional(),
	followupInterval: helpdeskUpsellRoleSchema.followupInterval.optional(),
	maxFollowups: helpdeskUpsellRoleSchema.maxFollowups.optional(),
	conversationStyle: helpdeskUpsellRoleSchema.conversationStyle.optional(),
	responseLength: helpdeskUpsellRoleSchema.responseLength.optional(),
	goal: helpdeskUpsellRoleSchema.goal.optional(),
	offersUrls: helpdeskUpsellRoleSchema.offersUrls.optional(),
}
