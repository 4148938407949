import { useLocation } from 'react-router-dom'
import { useMemo } from 'react'

const useQuery = () => {
	const location = useLocation()

	return useMemo(() => {
		return new URLSearchParams(location.search)
	}, [location.search])
}

export default useQuery
