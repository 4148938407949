import { Language, UsingUserId } from '../global'

export interface Assistant {
	id: string
	status: AssistantStatus
	firstname: string
	gender: AssistantGender
	companyName: string
	companyDescription: string
	personality: Personality
	additionalContext?: string
	workHours?: string[]
	timezone: string
	defaultLanguage: Language
	multilingual: boolean
	humanEmails: string[]
	userId: string
	createdAt: Date
	updatedAt?: Date
}

export type CreateAssistant = Omit<Assistant, 'id' | 'status' | 'userId' | 'createdAt'>

export const PERSONALITY_TYPES = ['default', 'custom'] as const
export type PersonalityType = (typeof PERSONALITY_TYPES)[number]

export type Personality = {
	type: PersonalityType
	description?: string
}

export const ASSISTANT_GENDERS = ['male', 'female'] as const
export type AssistantGender = (typeof ASSISTANT_GENDERS)[number]

export const ASSISTANT_STATUSES = [
	'active',
	'inactive',
	'requested',
	'approved',
	'error',
	'deleting',
] as const
export type AssistantStatus = (typeof ASSISTANT_STATUSES)[number]

// REQUESTS
export type GetAssistantRequest = Pick<Assistant, 'id'>
export type DeleteAssistantRequest = Pick<Assistant, 'id'>
export type ChangeAssistantStatusRequest = Pick<Assistant, 'id' | 'status'>
export type UpdateAssistantRequest = Partial<
	Omit<
		Assistant,
		'userId' | 'status' | 'translatedAdditionalContext' | 'translatedCompanyDescription'
	>
> &
	Pick<Assistant, 'id'>
export type CreateAssistantRequest = Omit<Assistant, 'id' | 'status' | 'userId' | 'createdAt'> &
	UsingUserId
