import React, { useEffect, useState } from 'react'
import useAsyncEffect from '../../hooks/useAsyncEffect'
import query from '../../utils/query'
import { Context, ContextStatus } from '@aiapp/types/context'
import { useNavigate } from 'react-router-dom'
import { useAiContext } from '../../hooks/context/AiContext'
import { formatDate } from '@aiapp/utils/dates'
import { App, Button, Layout, Tag, Tooltip } from 'antd'
import Loading, { InlineLoading } from '../../components/Loading/Loading'
import { socket } from '../../global/SocketManager'
import useTryCatch from '../../hooks/useTryCatch'
import CardTitle from '../../components/CardTitle/CardTitle'
import EmptyData from '../../components/EmptyData/EmptyData'
import {
	DeleteOutlined,
	SyncOutlined,
	UndoOutlined,
	FormOutlined,
	DatabaseOutlined,
} from '@ant-design/icons'
import { contextStatusToColor } from '../../utils/helpers'
import CardItem from '../../components/CardItem/CardItem'
import { useAccountContext } from '../../hooks/context/AccountContext'
import styles from './AiContexts.module.scss'

const { Header } = Layout

const AiContexts = () => {
	const navigate = useNavigate()
	const { message } = App.useApp()
	const tryCatch = useTryCatch(message)

	const accountContext = useAccountContext()
	const usingUserId = accountContext.useSubscribe((account) => account.usingUserId)

	const aiContext = useAiContext()
	const [selectedContextIds, setSelectedContextIds] = useState<number[]>([])
	const [contexts, setContexts] = useState<Context[]>([])
	const [loading, setLoading] = useState<boolean>(true)

	const pendingStatus = (status: ContextStatus) => status === 'deleting' || status === 'refreshing'

	useEffect(() => {
		socket.on('contextStatusUpdated', updateContextState)
		socket.on('contextDeleted', removeContextState)

		return () => {
			socket.off('contextStatusUpdated', updateContextState)
			socket.off('contextDeleted', removeContextState)
		}
	}, [contexts])

	const onLoadContext = async () => {
		setLoading(true)
		await tryCatch(async () => {
			const resContexts = await query<Context[]>('/context/forUser', 'GET', {
				withCredentials: true,
				params: {
					usingUserId,
				},
			})
			setContexts(resContexts)
		})
		setLoading(false)
	}

	useAsyncEffect(onLoadContext, [usingUserId])

	const updateContextState = (updatedContext: Context) => {
		const findContextToUpdate = contexts.find((context) => context.id === updatedContext.id)
		if (findContextToUpdate) {
			if (updatedContext.status === 'error') {
				message.open({
					type: 'error',
					content: `Context "${updatedContext.resource.name}" error. Try again`,
				})
			}
			if (updatedContext.status === 'refreshing') {
				message.open({
					type: 'info',
					content: `Context "${updatedContext.resource.name}" is refreshing...`,
				})
			}
			if (updatedContext.status === 'indexed') {
				message.open({
					type: 'success',
					content: `Context "${updatedContext.resource.name}" is indexed`,
				})
			}

			setContexts((contexts) =>
				contexts.map((context) => (context.id === updatedContext.id ? updatedContext : context)),
			)
		}
	}

	const removeContextState = (contextId: number) => {
		const findContextToRemove = contexts.find((context) => context.id === contextId)
		if (findContextToRemove) {
			message.open({
				type: 'success',
				content: `Context "${findContextToRemove.resource.name}" was deleted.`,
			})
			setContexts((contexts) => contexts.filter((context) => context.id !== contextId))
		}
	}

	const deleteContexts = async (contextIds: number[]) => {
		for (const contextId of contextIds) {
			await tryCatch(
				async () => {
					await query('/context/delete', 'POST', {
						data: { id: contextId },
						withCredentials: true,
					})
				},
				undefined,
				{
					message: 'Error while deleting context.',
				},
			)
		}
		setSelectedContextIds([])
	}

	const editContexts = (contextIds: number[]) => {
		for (const context of contexts.filter((context) => contextIds.includes(context.id))) {
			if (pendingStatus(context.status)) {
				continue
			}

			const content: any = {}

			if (context.resource.text) {
				content.text = context.resource.text
			}
			if (context.resource.url) {
				content.url = context.resource.url
				content.isValidUrl = true
			}
			if (context.resource.fileId) {
				content.fileId = context.resource.fileId
			}

			aiContext.addNewTab({
				id: context.id,
				name: context.resource.name,
				type: context.resource.type,
				content: content,
				editing: true,
			})
		}
		navigate('/context')
	}

	const selectContext = (contextId: number, contextStatus: ContextStatus) => {
		if (pendingStatus(contextStatus)) {
			setSelectedContextIds((ids) => ids.filter((id) => id !== contextId))
		}

		const hasSelectedContextId = selectedContextIds.some((id) => id === contextId)
		if (hasSelectedContextId) {
			setSelectedContextIds((ids) => ids.filter((id) => id !== contextId))
		} else {
			setSelectedContextIds([...selectedContextIds, contextId])
		}
	}

	const refreshContexts = async (contextIds: number[]) => {
		for (const contextId of contextIds) {
			await tryCatch(
				async () => {
					await query('/context/refresh', 'POST', {
						data: { id: contextId },
						withCredentials: true,
					})

					setContexts((contexts) =>
						contexts.map((context) =>
							contextIds.includes(context.id) ? { ...context, status: 'refreshing' } : context,
						),
					)
				},
				() => {
					setContexts((contexts) =>
						contexts.map((context) =>
							contextIds.includes(context.id) ? { ...context, status: 'error' } : context,
						),
					)
				},
				{ message: 'Error while refreshing context. Try again.' },
			)
		}
	}

	const atLeastOneSelectedContextIsAvailable = (): boolean => {
		const selectedContexts = contexts.filter(({ id }) => selectedContextIds.includes(id))
		return selectedContexts.some(({ status }) => status !== 'refreshing' && status !== 'deleting')
	}

	if (loading) {
		return <Loading />
	}

	const leastOneSelectedContextIsAvailable = atLeastOneSelectedContextIsAvailable()

	return (
		<div className={styles.container}>
			<Header className={styles.header}>
				<div className={styles.headerElements}>
					<Button icon={<DatabaseOutlined />} onClick={() => navigate('/context')}>
						Create new context
					</Button>
					{!!selectedContextIds.length && leastOneSelectedContextIsAvailable && (
						<>
							<Button icon={<FormOutlined />} onClick={() => editContexts(selectedContextIds)}>
								Edit selected {selectedContextIds.length > 1 ? 'contexts' : 'context'} (
								{selectedContextIds.length})
							</Button>
							<Button icon={<UndoOutlined />} onClick={() => refreshContexts(selectedContextIds)}>
								Refresh selected {selectedContextIds.length > 1 ? 'contexts' : 'context'} (
								{selectedContextIds.length})
							</Button>
						</>
					)}
				</div>

				<div className={styles.headerElements}>
					{!!selectedContextIds.length && leastOneSelectedContextIsAvailable && (
						<Button
							icon={<DeleteOutlined />}
							danger
							onClick={() => deleteContexts(selectedContextIds)}
						>
							Delete selected {selectedContextIds.length > 1 ? 'contexts' : 'context'} (
							{selectedContextIds.length})
						</Button>
					)}

					<Tooltip trigger={['hover', 'focus']} title='Refresh'>
						<Button
							className={styles.headerRefresh}
							onClick={onLoadContext}
							shape='circle'
							icon={<SyncOutlined />}
						/>
					</Tooltip>
				</div>
			</Header>

			<div className={styles.contexts}>
				{contexts.map((context) => (
					<CardItem
						key={context.id}
						itemId={context.id}
						selectedItems={selectedContextIds}
						selectItem={() => selectContext(context.id, context.status)}
						cardTitle={
							<CardTitle
								id={context.id}
								name={context.resource.name}
								disabled={context.status === 'deleting'}
								onDelete={(id: number) => deleteContexts([id])}
							/>
						}
						labels={[
							{
								label: 'Type',
								value: context.resource.type,
							},
							{
								label: 'Status',
								value: (
									<Tag color={contextStatusToColor[context.status]}>
										{pendingStatus(context.status) && (
											<InlineLoading iconClassName={styles.loading} color='#fff' size={10} />
										)}
										{context.status}
									</Tag>
								),
							},
							{
								label: 'Created date',
								value: formatDate(context.createdAt),
							},
						]}
					>
						<Button
							disabled={pendingStatus(context.status)}
							onClick={(e) => {
								e.stopPropagation()
								editContexts([context.id])
							}}
						>
							Edit
						</Button>
						<Button
							disabled={pendingStatus(context.status)}
							onClick={async (e) => {
								e.stopPropagation()
								await refreshContexts([context.id])
							}}
						>
							Refresh
						</Button>
					</CardItem>
				))}

				{!contexts.length && (
					<EmptyData onClick={() => navigate('/context')} description='Empty context list' />
				)}
			</div>
		</div>
	)
}

export default AiContexts
