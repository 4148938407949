import React from 'react'
import styles from './WaitingForOffer.module.scss'

const WaitingForOffer = () => {
	return (
		<div className={styles.container}>
			<h2 className={styles.heading}>Proszę czekać na kontakt brokera</h2>
			<p className={styles.info}>
				Dziękujemy za przesłanie swoich danych. Twój broker skontaktuje się z Tobą wkrótce, aby
				omówić dalsze kroki.
			</p>
		</div>
	)
}

export default WaitingForOffer
