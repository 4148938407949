import { FileData } from '../../../../../../../types/ChatContext'
import React from 'react'
import { InlineLoading } from '../../../../../../../components/Loading/Loading'
import { FileTextOutlined } from '@ant-design/icons'
import styles from './FileContent.module.scss'

type Props = {
	fileData: FileData
	downloadFile: (fileId: string) => void
	downloadLoading: boolean
}

const FileContent = ({ fileData, downloadFile, downloadLoading }: Props) => (
	<div onClick={() => downloadFile(fileData.id)} className={styles.fileMessage}>
		{downloadLoading ? (
			<InlineLoading size={16} iconClassName={styles.loadingFileIcon} />
		) : (
			<FileTextOutlined className={styles.fileIcon} />
		)}
		{fileData.name}
	</div>
)

export default FileContent
