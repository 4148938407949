import React, { useMemo } from 'react'
import UploadFileWrapper from '../../../components/UploadFileWrapper/UploadFileWrapper'
import { useChatContext } from '../../../hooks/context/ChatContext'
import { useAttachFiles } from '../hooks'
import styles from './ChatDropzone.module.scss'

const ChatDropzone = () => {
	const attachFiles = useAttachFiles()

	const chatContext = useChatContext()
	const showChatDropzone = chatContext.useSubscribe((context) => context.showChatDropzone)

	const onDropInputFiles = async (acceptedFiles: File[]) => {
		chatContext.setShowChatDropzone(false)
		await attachFiles.attachFilesToMessage(acceptedFiles)
	}

	return useMemo(
		() => (
			<div className={`${styles.onDragFile} ${showChatDropzone ? styles.show : styles.hide}`}>
				<UploadFileWrapper
					ref={(ref) => {
						if (ref) {
							chatContext.setUploadChatFileRef(ref)
						}
					}}
					className={styles.uploadFileWrapper}
					onUpload={onDropInputFiles}
					maxFiles={10}
				>
					<div className={styles.dropzone}>Drop files to attachments</div>
				</UploadFileWrapper>
			</div>
		),
		[showChatDropzone],
	)
}

export default ChatDropzone
