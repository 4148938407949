import IntegrationList from '../../../../../components/IntegrationList/IntegrationList'
import Loading from '../../../../../components/Loading/Loading'
import { App, Segmented } from 'antd'
import { IntegrationProvider, PublicIntegration } from '@aiapp/types/integration'
import useTryCatch from '../../../../../hooks/useTryCatch'
import React, { useMemo, useState } from 'react'
import useAsyncEffect from '../../../../../hooks/useAsyncEffect'
import query from '../../../../../utils/query'
import { useAssistantRoleContext } from '../../../../../hooks/context/AssistantRoleContext'
import { GetLeadsGroupsResponse } from '@aiapp/types/leadsGroup'
import LeadsGroupList from '../../../../../components/LeadsGroupList/LeadsGroupList'
import { useParams } from 'react-router-dom'
import { useAccountContext } from '../../../../../hooks/context/AccountContext'
import styles from './Connections.module.scss'

const TABS = ['Integrations', 'Leads groups'] as const
type Tab = (typeof TABS)[number]

const Connections = () => {
	const { message } = App.useApp()
	const tryCatch = useTryCatch(message)

	const { assistantRoleId } = useParams()

	const accountContext = useAccountContext()
	const usingUserId = accountContext.useSubscribe((account) => account.usingUserId)

	const assistantRoleContext = useAssistantRoleContext()
	const assistantRoleIntegrations = assistantRoleContext.useSubscribe(
		(context) => context.data.connections?.integrations,
	)
	const assistantRoleLeadsGroupIds = assistantRoleContext.useSubscribe(
		(context) => context.data.connections?.leadsGroupIds,
	)

	const [tab, setTab] = useState<Tab>('Integrations')

	const [integrations, setIntegrations] = useState<PublicIntegration[]>([])
	const [leadsGroups, setLeadsGroups] = useState<GetLeadsGroupsResponse | undefined>()

	const [integrationsLoading, setIntegrationsLoading] = useState<boolean>(true)
	const [leadsGroupsLoading, setLeadsGroupsLoading] = useState<boolean>(true)

	const onLoadIntegrations = async () => {
		if (!assistantRoleId) {
			return
		}
		setIntegrationsLoading(true)
		assistantRoleContext.setValidStep(true)

		await tryCatch(async () => {
			const resIntegrations = await query<PublicIntegration[]>(
				'/integration/forAssistantRole',
				'GET',
				{
					params: {
						assistantRoleId,
						usingUserId,
					},
					withCredentials: true,
				},
			)
			setIntegrations(resIntegrations)
		})
		setIntegrationsLoading(false)
	}

	const onLoadLeadsGroups = async () => {
		setLeadsGroupsLoading(true)

		await tryCatch(async () => {
			const resLeadsGroups = await query<GetLeadsGroupsResponse>('/leadsGroup/all', 'GET', {
				withCredentials: true,
				params: {
					usingUserId,
				},
			})
			setLeadsGroups(resLeadsGroups)
		})
		setLeadsGroupsLoading(false)
	}

	useAsyncEffect(async () => {
		if (tab === TABS[0]) {
			await onLoadIntegrations()
		}
		if (tab === TABS[1]) {
			await onLoadLeadsGroups()
		}
	}, [tab, assistantRoleId])

	const selectIntegration = (integrationId: string, provider: IntegrationProvider) => {
		const hasSelectedAssistantId = assistantRoleIntegrations?.some(({ id }) => id === integrationId)

		if (hasSelectedAssistantId) {
			assistantRoleContext.setIntegrations(
				assistantRoleIntegrations!.filter(({ id }) => id !== integrationId),
			)
		} else {
			if (assistantRoleIntegrations && assistantRoleIntegrations.length >= 15) {
				return
			}
			assistantRoleContext.setIntegrations([
				...(assistantRoleIntegrations ?? []),
				{ id: integrationId, provider },
			])
		}
	}

	const selectLeadsGroupId = (leadsGroupId: string) => {
		const hasSelectedGroupId = assistantRoleLeadsGroupIds?.some((id) => id === leadsGroupId)

		if (hasSelectedGroupId) {
			assistantRoleContext.setLeadsGroupIds(
				assistantRoleLeadsGroupIds!.filter((id) => id !== leadsGroupId),
			)
		} else {
			if (assistantRoleLeadsGroupIds && assistantRoleLeadsGroupIds.length >= 5) {
				return
			}
			assistantRoleContext.setLeadsGroupIds([...(assistantRoleLeadsGroupIds ?? []), leadsGroupId])
		}
	}

	return useMemo(() => {
		return (
			<div className={styles.container}>
				<Segmented
					value={tab}
					onChange={(tabValue) => setTab(tabValue as Tab)}
					className={styles.contentTabs}
					size='large'
					options={[...TABS]}
				/>

				<div className={styles.tabWrapper}>
					{tab === 'Integrations' && (
						<>
							{integrationsLoading ? (
								<Loading className={styles.loading} />
							) : (
								<IntegrationList
									showSelectingButton
									showEditButton
									showCreateButton
									integrations={integrations}
									selectedIntegrationIds={assistantRoleIntegrations?.map(({ id }) => id) ?? []}
									selectIntegration={selectIntegration}
									onRefreshIntegrations={onLoadIntegrations}
								/>
							)}
						</>
					)}

					{tab === 'Leads groups' && (
						<>
							{leadsGroupsLoading || !leadsGroups ? (
								<Loading className={styles.loading} />
							) : (
								<LeadsGroupList
									showOpenButton
									leadsGroups={leadsGroups}
									onLoadLeadsGroups={onLoadLeadsGroups}
									selectLeadsGroup={selectLeadsGroupId}
									selectedLeadsGroupIds={assistantRoleLeadsGroupIds ?? []}
								/>
							)}
						</>
					)}
				</div>
			</div>
		)
	}, [
		tab,
		integrations,
		leadsGroups,
		assistantRoleLeadsGroupIds,
		assistantRoleIntegrations,
		leadsGroupsLoading,
		integrationsLoading,
	])
}

export default Connections
