import { InfoCircleOutlined, WarningOutlined } from '@ant-design/icons'
import React from 'react'
import { PublicProgressStep } from '@aiapp/types/progressSteps'
import MessageProgressSteps from './MessageProgressSteps/MessageProgressSteps'
import styles from './MessageStatus.module.scss'

type Props = {
	warnings: string[]
	information: string[]
	messageProgressSteps: PublicProgressStep[]
}

const MessageStatus = ({ warnings, information, messageProgressSteps }: Props) => {
	if (warnings.length > 0) {
		return (
			<>
				{warnings.map((warning, index) => (
					<div key={`${index}.${warning}`} className={`${styles.status} ${styles.warning}`}>
						<WarningOutlined />
						{warning}
					</div>
				))}
			</>
		)
	}

	if (information.length > 0) {
		return (
			<>
				{information.map((info, index) => (
					<div key={`${index}.${info}`} className={`${styles.status} ${styles.info}`}>
						<InfoCircleOutlined />
						{info}
					</div>
				))}
			</>
		)
	}

	if (messageProgressSteps.length > 0) {
		return <MessageProgressSteps messageProgressSteps={messageProgressSteps} />
	}

	return <></>
}

export default MessageStatus
