import { MessageAttachedFileIds } from '@aiapp/types/chat/chatMessage'
import { TemplateTool } from '@aiapp/types/global/tools'

export const DEFAULT_INPUT_ID = 'DEFAULT' as const

type TemplateInputName = string
type TemplateInputValue = string

export type EditorContent = Record<TemplateInputName, TemplateInputValue>

const EDITOR_VALIDATION_CODES = ['EDIT-1', 'EDIT-2', 'EDIT-3'] as const
export type EditorValidationCode = (typeof EDITOR_VALIDATION_CODES)[number]

export const EDITOR_VALIDATION_CODE_MAPPER: Record<EditorValidationCode, string> = {
	'EDIT-1': 'Message must have at least two characters',
	'EDIT-2': 'Some required text fields not have at least two characters',
	'EDIT-3': 'Some required files are not attached',
}

export interface EditorContextState {
	editorContent: EditorContent
	editorFiles: MessageAttachedFileIds
	toolType: TemplateTool | undefined
	canSubmit: boolean
	editorHeight: number
	validationCode?: EditorValidationCode
}
