import { FileData } from '../../types/ChatContext'
import { FileTextOutlined } from '@ant-design/icons'
import React from 'react'
import styles from './FilePriview.module.scss'

type Props = {
	fileData: FileData
}

const FilePreview = ({ fileData }: Props) => {
	if (fileData.type.startsWith('image')) {
		return <img className={styles.filePreview} src={fileData.url} alt={fileData.name} />
	}

	if (fileData.type.startsWith('video')) {
		return (
			<video className={styles.filePreview} controls={false} autoPlay>
				<source src={fileData.url} type='video/mp4' />
			</video>
		)
	}

	if (fileData.type.startsWith('audio')) {
		return <audio className={styles.filePreview} controls src={fileData.url} />
	}

	return (
		<div className={styles.filePreview}>
			<FileTextOutlined className={styles.fileIcon} /> {fileData.name}
		</div>
	)
}

export default FilePreview
