import React, { useState } from 'react'
import { LinkContent, Tab } from '../../../../types/AiContext'
import { extractDomain } from '../../../../utils/helpers'
import { ExportOutlined } from '@ant-design/icons'
import { STRICT_URL_REGEX } from '@aiapp/utils/consts'
import styles from './ContextUrl.module.scss'

type Props = {
	content: LinkContent
	updateTab: (newTab: Partial<Tab>) => void
}

const ContextUrl = ({ content, updateTab }: Props) => {
	const [showValidError, setShowValidError] = useState<boolean>(false)

	const updateMainLink = (link: string) => {
		const newContent = {
			...content,
			url: link,
			isValidUrl: validLink(link),
		}

		const newTab: Partial<Tab> = {
			content: newContent,
		}

		if (newContent.isValidUrl) {
			newTab.name = extractDomain(newContent.url)
		}

		updateTab(newTab)
		setShowValidError(false)
	}

	const validLink = (link: string): boolean => {
		if (!link.length) {
			return false
		}
		return !!link.match(STRICT_URL_REGEX)
	}

	return (
		<div className={styles.form}>
			<a
				className={`${validLink(content.url) ? styles.validUrl : styles.invalidUrl}`}
				target='_blank'
				href={content.url}
				rel='noreferrer'
			>
				<ExportOutlined className={styles.openLinkIcon} />
			</a>
			<input
				onBlur={() => setShowValidError(true)}
				placeholder='Enter website url'
				className={`${styles.linkInput} ${
					showValidError && !content.isValidUrl ? styles.inputError : ''
				}`}
				type='text'
				value={content.url}
				onChange={(e) => updateMainLink(e.target.value)}
			/>
		</div>
	)
}

export default ContextUrl
