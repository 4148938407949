import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Form, Input, Button, App, Select } from 'antd'
import { AdyenCheckout, Card, CoreConfiguration, SubmitData } from '@adyen/adyen-web'
import Joi from 'joi'
import { billingAddressSchema } from '@aiapp/schemas/order/paymentMethod'
import useTryCatch from '../../hooks/useTryCatch'
import { Order } from '@aiapp/types/order'
import query from '../../utils/query'
import useAsyncEffect from '../../hooks/useAsyncEffect'
import { formValidate } from '../../utils/validation'
import Line from '../Line/Line'
import { socket } from '../../global/SocketManager'
import { FAILED_PAYMENT_STATUSES, Payment as PaymentInterface } from '@aiapp/types/order/payment'
import FormRow from '../FormRow/FormRow'
import Loading from '../Loading/Loading'
import { selectFilterOption } from '../../utils/helpers'

import '@adyen/adyen-web/styles/adyen.css'
import styles from './Payment.module.scss'
import './AdyenStyles.scss'

import countries from '@aiapp/utils/resources/countries.json'
import { useAccountContext } from '../../hooks/context/AccountContext'

type Props = {
	onClose: () => Promise<void>
	order?: Order
}

const Payment = ({ order, onClose }: Props) => {
	const { message } = App.useApp()
	const tryCatch = useTryCatch(message)
	const [form] = Form.useForm()

	const accountContext = useAccountContext()
	const accountId = accountContext.useSubscribe((account) => account.id)

	const cardComponentRef = useRef<Card | null>(null)

	const [formData, setFormData] = useState({
		holderName: '',
		street: '',
		country: 'PL',
		city: '',
		postalCode: '',
		houseNumberOrName: '',
	})

	const [isCardValid, setIsCardValid] = useState(false)

	const [pspReference, setPspReference] = useState<string>()
	const [paymentProcessing, setPaymentProcessing] = useState(false)

	const checkPaymentStatus = async (updatedPayment: PaymentInterface) => {
		if (updatedPayment.pspReference !== pspReference) {
			return
		}

		if (updatedPayment.status === 'authorised' || updatedPayment.status === 'captured') {
			message.open({
				type: 'success',
				content: 'Płatność zakończona pomyślnie!',
			})
			await onClose()
		}

		if (FAILED_PAYMENT_STATUSES.includes(updatedPayment.status)) {
			message.open({
				type: 'error',
				content: 'Płatność nie powiodła się',
			})
		}
	}

	const addNewPaymentMethod = async (state: SubmitData) => {
		await tryCatch(
			async () => {
				const response = await query<string>('/order/payment-method/add', 'POST', {
					withCredentials: true,
					data: {
						userId: accountId,
						holderName: formData.holderName,
						billingAddress: {
							street: formData.street,
							postalCode: formData.postalCode,
							city: formData.city,
							country: formData.country,
							houseNumberOrName: formData.houseNumberOrName,
						},
						encryptedCardDetails: {
							encryptedCardNumber: state.data.paymentMethod.encryptedCardNumber,
							encryptedExpiryMonth: state.data.paymentMethod.encryptedExpiryMonth,
							encryptedExpiryYear: state.data.paymentMethod.encryptedExpiryYear,
							encryptedSecurityCode: state.data.paymentMethod.encryptedSecurityCode,
						},
					},
				})

				setPspReference(response)
			},
			() => {
				setPaymentProcessing(false)
			},
			{ message: 'Błąd podczas próby dodania nowej karty płatniczej' },
		)
	}

	const makeOrderPayment = async (state: SubmitData) => {
		await tryCatch(
			async () => {
				const response = await query<string>('/order/payment/make', 'POST', {
					withCredentials: true,
					data: {
						orderId: order?.id,
						paymentMethod: {
							holderName: formData.holderName,
							billingAddress: {
								street: formData.street,
								postalCode: formData.postalCode,
								city: formData.city,
								country: formData.country,
								houseNumberOrName: formData.houseNumberOrName,
							},
							encryptedCardDetails: {
								encryptedCardNumber: state.data.paymentMethod.encryptedCardNumber,
								encryptedExpiryMonth: state.data.paymentMethod.encryptedExpiryMonth,
								encryptedExpiryYear: state.data.paymentMethod.encryptedExpiryYear,
								encryptedSecurityCode: state.data.paymentMethod.encryptedSecurityCode,
							},
						},
					},
				})

				setPspReference(response)
			},
			() => {
				setPaymentProcessing(false)
			},
			{ message: 'Błąd podczas próby dokonania płatności' },
		)
	}

	const onSubmitPayment = async (state: SubmitData) => {
		setPaymentProcessing(true)
		if (order) {
			await makeOrderPayment(state)
		} else {
			await addNewPaymentMethod(state)
		}
	}

	useAsyncEffect(async () => {
		if (paymentProcessing) {
			return
		}

		const configuration: CoreConfiguration = {
			clientKey: 'test_4WDBRWHYAVCGLMKIISCZ4GR7Q4LT3JJV',
			environment: 'test',
			locale: 'pl-PL',
			countryCode: 'PL',
			onSubmit: (state) => onSubmitPayment(state),
			// onError: (error, component) => {
			// 	console.error(error.name, error.message, error.stack, component)
			// },
			onChange: (state) => {
				setIsCardValid(state.isValid)
			},
		}

		const checkout = await AdyenCheckout(configuration)
		const cardComponent = new Card(checkout)

		if (cardComponent) {
			cardComponent.mount('#card-container')
			cardComponentRef.current = cardComponent
		}
	}, [formData, paymentProcessing])

	useEffect(() => {
		return () => {
			if (cardComponentRef.current) {
				cardComponentRef.current.unmount()
			}
		}
	}, [])

	useEffect(() => {
		socket.on('paymentStatusUpdated', checkPaymentStatus)
		return () => {
			socket.off('paymentStatusUpdated', checkPaymentStatus)
		}
	}, [pspReference])

	const handleCardPayment = useCallback(() => {
		if (cardComponentRef.current) {
			cardComponentRef.current.submit()
		}
	}, [cardComponentRef.current])

	const updateFormData = (_value: Record<string, any>, values: any) => {
		setFormData((prevData) => ({ ...prevData, ...values }))
	}

	const countryOptions = useMemo(() => {
		return Object.entries(countries).map(([code, name]) => ({
			label: name,
			value: code,
		}))
	}, [])

	const isFormValid = useMemo(() => {
		const validate = formValidate(formData, paymentMethodSchema)
		return validate ? !Object.keys(validate).length : true
	}, [formData])

	if (paymentProcessing) {
		return (
			<div className={styles.loadingWrapper}>
				<Loading size={48} />
			</div>
		)
	}

	return (
		<Form
			form={form}
			layout='vertical'
			autoComplete='true'
			initialValues={formData}
			onValuesChange={updateFormData}
		>
			<Line />

			<Form.Item
				name='holderName'
				label='Imię i nazwisko posiadacza karty'
				className={styles.formItem}
			>
				<Input count={{ show: true }} maxLength={50} size='large' placeholder='Jan Kowalski' />
			</Form.Item>

			<FormRow columns={[2, 1]}>
				<Form.Item name='street' label='Ulica' className={styles.formItem}>
					<Input count={{ show: true }} maxLength={100} size='large' placeholder='ul. Główna 123' />
				</Form.Item>

				<Form.Item
					name='houseNumberOrName'
					label='Numer domu lub nazwa'
					className={styles.formItem}
				>
					<Input count={{ show: true }} maxLength={10} size='large' placeholder='Mieszkanie 101' />
				</Form.Item>
			</FormRow>

			<FormRow columns={[1, 1, 1]}>
				<Form.Item name='city' label='Miasto' className={styles.formItem}>
					<Input count={{ show: true }} maxLength={50} size='large' placeholder='Warszawa' />
				</Form.Item>

				<Form.Item name='postalCode' label='Kod pocztowy' className={styles.formItem}>
					<Input count={{ show: true }} maxLength={10} size='large' placeholder='00-123' />
				</Form.Item>

				<Form.Item
					className={styles.formItem}
					label='Kraj'
					name='country'
					rules={[{ required: true, message: 'Proszę wybrać kraj' }]}
				>
					<Select
						showSearch
						size='large'
						filterOption={selectFilterOption}
						placeholder='Wybierz kraj'
						options={countryOptions}
					/>
				</Form.Item>
			</FormRow>

			<Line className={styles.cardLine} />

			<div id='card-container' />

			<Button
				size='large'
				className={styles.addPaymentMethodButton}
				type='primary'
				onClick={() => handleCardPayment()}
				disabled={!isFormValid || !isCardValid}
			>
				{order ? 'Zapłać teraz' : 'Dodaj kartę płatniczą'}
			</Button>
		</Form>
	)
}

const paymentMethodSchema = Joi.object({
	holderName: Joi.string().min(3).max(50).required(),
	...billingAddressSchema,
}).required()

export default Payment
