import Joi from 'joi'
import { MODALS } from '../../types/account'
import { emailSchema, uuidIdSchema } from '../global'

// const NEEDS_CAPTCHA = 'needsCaptcha'

const passwordSchema = Joi.string()
	// .regex(/(?=.*\d)(?=.*[a-ząćęńółśźż])(?=.*[A-ZĄĆĘŃÓŁŚŹŻ])/)
	.min(8)
	.max(2000)

const roleSchema = Joi.string().max(500).trim().lowercase()

export const loginSchema = Joi.object({
	email: emailSchema.required(),
	password: passwordSchema.required(),
}).required()

export const createAccountSchema = {
	email: emailSchema.required(),
	password: passwordSchema.required(),
	firstName: Joi.string().min(3).max(100).required(),
	lastName: Joi.string().min(3).max(100).required(),
	phone: Joi.string().max(20).required(),
	brokerEmail: emailSchema.allow(null, '').optional(),
	reflinkCode: Joi.string().optional(),
}

export const accountsFilterSchema = Joi.object({
	accountIds: Joi.string().max(5000).trim(),
	email: emailSchema,
	role: roleSchema,
	skipBlocked: Joi.bool(),

	page: Joi.number().positive().integer().default(1),
	perPage: Joi.number().positive().integer().default(20).max(1000),
}).required()

export const getAccountOfferRequestSchema = Joi.object({
	userId: uuidIdSchema.required(),
}).required()

export const activateAccountValidationSchema = Joi.object({
	userId: uuidIdSchema.required(),
}).required()

export const blockAccountRequestSchema = Joi.object({
	userId: uuidIdSchema.required(),
}).required()

export const deleteAccountRequestSchema = Joi.object({
	userId: uuidIdSchema.required(),
}).required()

// const idProviderSchema = Joi.string().valid('facebook', 'google', 'apple').required()

// const forbiddenForIdProvider = (schema: Joi.AnySchema) =>
// 	schema.when('idProvider', {
// 		is: idProviderSchema,
// 		then: Joi.string().forbidden(),
// 		otherwise: schema.required(),
// 	})
//
// const requiredForIdProvider = (schema: Joi.AnySchema) =>
// 	schema.when('idProvider', {
// 		is: idProviderSchema,
// 		then: schema.required(),
// 		otherwise: Joi.string().forbidden(),
// 	})

// export const recaptchaTokenSchema = Joi.string()
// 	.max(1000)
// 	.alter({ [NEEDS_CAPTCHA]: (schema) => schema.required() })

export const updateModalRequestSchema = {
	modalName: Joi.string()
		.valid(...MODALS)
		.required(),
	show: Joi.boolean().required(),
}

export const adminUpdateAccountRequestSchema = {
	id: uuidIdSchema.required(),
	brokerEmail: emailSchema.allow('', null).optional(),
	note: Joi.string().max(1000).allow('', null).optional(),
	roles: Joi.array().items(roleSchema).allow(null).optional(),
}

export const companySchema = {
	name: Joi.string().min(1).max(250).required(),
	taxId: Joi.string().min(1).max(50).required(),
	country: Joi.string().min(1).max(100).required(),
	address: Joi.string().min(1).max(500).required(),
	province: Joi.string().min(1).max(100).required(),
	city: Joi.string().min(1).max(100).required(),
	postalCode: Joi.string().min(1).max(20).required(),
}

export const updateAccountDetailsRequestSchema = Joi.object(companySchema).required()
