import { Button, Form, Input } from 'antd'
import FormRow from '../../../../../../components/FormRow/FormRow'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { GoalUrl } from '@aiapp/types/assistant/assistantRole'
import styles from './Forms.module.scss'

type Props = {
	meetingUrls: GoalUrl[] | undefined
}

const AppointmentLinks = ({ meetingUrls }: Props) => {
	return (
		<Form.Item
			required
			tooltip='Enter a link to schedule meeting. Persona will use the link to set up an appointment with the client.'
			className={styles.formItem}
			label='Meeting links'
		>
			<Form.List initialValue={meetingUrls ? undefined : [{ url: '' }]} name='meetingUrls'>
				{(fields, { add, remove }) => (
					<>
						{fields.map(({ key, name, ...restField }) => (
							<div key={key} className={styles.fieldList}>
								<FormRow columns={[1, 1]}>
									<Form.Item
										{...restField}
										rules={[
											{ required: true, message: 'Please fill meeting link' },
											{
												type: 'url',
												message: 'Please fill correct link',
											},
											{
												message: 'Meeting link must have a minimum of 6 characters',
												min: 6,
											},
											{
												message: 'Meeting link can have a maximum of 1000 characters',
												max: 1000,
											},
										]}
										className={styles.formItem}
										name={[name, 'url']}
									>
										<Input
											maxLength={1000}
											size='large'
											className={styles.formInput}
											placeholder='https://quantumneuron.eu/meeting'
										/>
									</Form.Item>

									<Form.Item
										{...restField}
										rules={[
											{
												required: true,
												message: 'Please fill meeting link usage context',
											},
											{
												message: 'Meeting link usage context must have a minimum of 6 characters',
												min: 6,
											},
											{
												message: 'Meeting link usage context can have a maximum of 500 characters',
												max: 500,
											},
										]}
										className={styles.formItem}
										name={[name, 'context']}
									>
										<Input
											size='large'
											count={{
												show: true,
											}}
											maxLength={500}
											className={styles.formInput}
											placeholder='Use this link to schedule a meeting with customers interested in XYZ offer'
										/>
									</Form.Item>
								</FormRow>
								{key !== 0 && (
									<MinusCircleOutlined
										className={styles.listRemoveIcon}
										onClick={() => remove(name)}
									/>
								)}
							</div>
						))}
						<Form.Item className={styles.formItem}>
							<Button
								size='large'
								type='dashed'
								disabled={fields.length === 5}
								onClick={() => {
									fields.length < 5 && add()
								}}
								block
								icon={<PlusOutlined />}
							>
								Add another meeting link
							</Button>
						</Form.Item>
					</>
				)}
			</Form.List>
		</Form.Item>
	)
}

export default AppointmentLinks
