import { UsingUserId } from '../global'

export interface Context {
	id: number
	userId: string
	teamId?: string
	indexId?: string
	status: ContextStatus
	errorMessage?: string
	resource: Resource
	createdAt: Date
	reindexedAt?: Date
	updatedAt?: Date
}

export type Resource = {
	name: string
	type: SourceType
	url?: string
	text?: string
	fileId?: string
}

export const MAX_TEXT_CONTENT_LENGTH = 25000 as const
export const MAX_CONTEXT_NAME_LENGTH = 256 as const

export const CONTEXT_STATUSES = [
	'indexed',
	'refreshing',
	'needReindex',
	'error',
	'deleting',
] as const

export type ContextStatus = (typeof CONTEXT_STATUSES)[number]

export const SOURCE_TYPES = ['text', 'url', 'file'] as const
export type SourceType = (typeof SOURCE_TYPES)[number]

export interface DataSummary {
	id: string
	name: string
	title: string
	summary: string
}

export type RemoveContextData = {
	contextId: number
	indexId: string
}

export type GetContextRequest = {
	id?: number
	ids?: number[]
}
export type RefreshContextRequest = Pick<Context, 'id'>
export type UpdateContextRequest = Pick<Context, 'id' | 'teamId' | 'resource'>
export type UpdateContextStatusRequest = Pick<
	Context,
	'id' | 'status' | 'errorMessage' | 'indexId' | 'userId'
>
export type CreateContextRequest = Pick<Context, 'teamId' | 'resource'> & {
	assistantRoleId?: string
} & UsingUserId
export type DeleteContextPublicRequest = Pick<Context, 'id'>

export type DeleteContextPrivateRequest = Pick<Context, 'id' | 'userId'>
