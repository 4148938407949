import FormRow from '../../../../../components/FormRow/FormRow'
import { Button, Form, Switch } from 'antd'
import React from 'react'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { Integration } from '@aiapp/types/integration'
import Line from '../../../../../components/Line/Line'
import TextArea from 'antd/es/input/TextArea'
import styles from './InstagramReactions.module.scss'

type Props = {
	integrationData: Partial<Integration>
}

const InstagramReactions = ({ integrationData }: Props) => {
	return (
		<div className={styles.actions}>
			<h4 className={styles.titleLabel}>Actions</h4>
			<Line className={styles.line} />

			<Form.Item
				name={['reactions', 'instagramCommentConversation']}
				label='New conversation for comments'
				className={styles.formItem}
			>
				<Switch />
			</Form.Item>

			{!!integrationData.reactions?.instagramCommentConversation && (
				<>
					<h4 className={styles.headingLabel}>Comments action</h4>
					<Line className={styles.line} />

					<FormRow columns={[5, 1]} className={styles.formRow}>
						<Form.Item
							tooltip='Conversations will be started only for comments in which the user has tagged your profile.'
							name={['reactions', 'instagramCommentConversation', 'onlyMentions']}
							label='Only mentions'
							className={styles.formItem}
						>
							<Switch />
						</Form.Item>
					</FormRow>

					<Form.Item
						tooltip='Define sample messages that start a new conversation after a user comment.'
						className={styles.formItem}
						label='Start conversation messages'
					>
						<Form.List
							name={['reactions', 'instagramCommentConversation', 'startConversationMessages']}
						>
							{(fields, { add, remove }) => (
								<>
									{fields.map(({ key, name, ...restField }) => (
										<div key={key} className={styles.fieldList}>
											<Form.Item
												{...restField}
												rules={[
													{
														message:
															'Start conversation message must have a minimum of 6 characters',
														min: 6,
													},
													{
														message: 'Start conversation message have a maximum of 500 characters',
														max: 500,
													},
												]}
												className={styles.formItem}
												name={name}
											>
												<TextArea
													className={styles.formTextarea}
													size='large'
													allowClear
													count={{
														show: true,
													}}
													maxLength={500}
													defaultValue=''
													autoSize={{ minRows: 2, maxRows: 4 }}
													placeholder='Hi, thanks for your comment! Maybe you are interested in our offer?'
												/>
											</Form.Item>

											<MinusCircleOutlined
												className={styles.listRemoveIcon}
												onClick={() => remove(name)}
											/>
										</div>
									))}
									<Form.Item className={styles.formItem}>
										<Button
											size='large'
											type='dashed'
											disabled={fields.length === 5}
											onClick={() => {
												fields.length < 5 && add()
											}}
											block
											icon={<PlusOutlined />}
										>
											Add new start conversation message
										</Button>
									</Form.Item>
								</>
							)}
						</Form.List>
					</Form.Item>
				</>
			)}
		</div>
	)
}

export default InstagramReactions
