import { useAssistantRoleContext } from '../../../../../hooks/context/AssistantRoleContext'
import useQuery from '../../../../../hooks/useQuery'
import SellerOffersRoleForm from './SellerOffers/SellerOffers'
import AppointmentSetterRoleForm from './AppointmentSetter/AppointmentSetter'
import HelpdeskUpsellRoleForm from './HelpdeskUpsell/HelpdeskUpsell'
import HelpdeskRoleForm from './Helpdesk/Helpdesk'
import ExternalAssistantRoleForm from './ExternalAssistant/ExternalAssistant'
import InternalAssistantRoleForm from './InternalAssistant/InternalAssistant'
import LeadGenerationRoleForm from './LeadGeneration/LeadGeneration'

const RoleForm = () => {
	const role = useQuery().get('role')

	const assistantRoleContext = useAssistantRoleContext()
	const assistantRoleType = assistantRoleContext.useSubscribe((context) => context.data.type)

	if (role === 'sellerOffers' || assistantRoleType === 'sellerOffers') {
		return <SellerOffersRoleForm />
	}
	if (role === 'leadGeneration' || assistantRoleType === 'leadGeneration') {
		return <LeadGenerationRoleForm />
	}
	if (role === 'appointmentSetter' || assistantRoleType === 'appointmentSetter') {
		return <AppointmentSetterRoleForm />
	}
	if (role === 'helpdeskUpsell' || assistantRoleType === 'helpdeskUpsell') {
		return <HelpdeskUpsellRoleForm />
	}
	if (role === 'helpdesk' || assistantRoleType === 'helpdesk') {
		return <HelpdeskRoleForm />
	}
	if (role === 'internalAssistant' || assistantRoleType === 'internalAssistant') {
		return <InternalAssistantRoleForm />
	}
	if (role === 'externalAssistant' || assistantRoleType === 'externalAssistant') {
		return <ExternalAssistantRoleForm />
	}

	return <></>
}

export default RoleForm
