import React, { useEffect, useRef, useState } from 'react'
import { Accept } from 'react-dropzone'
import { FileContent, Tab } from '../../../../types/AiContext'
import FileFormats from '../../../../components/FileFormats/FileFormats'
import fileFormatMapper from '../../../../utils/fileFormatMapper'
import { FileInfo } from '@aiapp/types/fileInfo'
import { CloseOutlined } from '@ant-design/icons'
import useAsyncEffect from '../../../../hooks/useAsyncEffect'
import query from '../../../../utils/query'
import { App, Button, Drawer, Input } from 'antd'
import Loading from '../../../../components/Loading/Loading'
import useTryCatch from '../../../../hooks/useTryCatch'
import UploadFileWrapper, {
	UploadFileRef,
} from '../../../../components/UploadFileWrapper/UploadFileWrapper'
import { contentTypeToExtension } from '@aiapp/utils'
import { SourceType } from '@aiapp/types/context'
import styles from './UploadContextFile.module.scss'

type Props = {
	content: FileContent
	updateTab: (newTab: Partial<Tab>) => void
	createNewTab: (type: SourceType, tab: Partial<Tab>) => void
}

const { TextArea } = Input

const UploadContextFile = ({ content, updateTab, createNewTab }: Props) => {
	const { message } = App.useApp()
	const tryCatch = useTryCatch(message)

	const uploadFileRef = useRef<UploadFileRef>(null)

	const [openPreview, setOpenPreview] = useState<boolean>(false)
	const [loading, setLoading] = useState<boolean>(false)
	const [transcription, setTranscription] = useState<string>('')
	const [fileInfo, setFileInfo] = useState<FileInfo>()
	const [acceptTypes, setAcceptTypes] = useState<Accept>(contentTypeToExtension)

	useEffect(() => {
		if (Object.keys(acceptTypes).length !== Object.keys(contentTypeToExtension).length) {
			// Open file modal when acceptTypes was updated
			uploadFileRef.current?.openFileModal()
			setAcceptTypes(contentTypeToExtension)
		}
	}, [acceptTypes])

	useAsyncEffect(async () => {
		if (!content.fileId) {
			setFileInfo(undefined)
			setTranscription('')
			return
		}

		setLoading(true)

		await tryCatch(async () => {
			const fileInfo = await query<FileInfo>('/file/get', 'GET', {
				params: {
					id: content.fileId,
				},
				useCache: true,
				withCredentials: true,
			})

			setFileInfo(fileInfo)
			setTranscription(fileInfo.transcription ?? '')
		})

		setLoading(false)
	}, [content.fileId])

	const onDrop = (acceptedFiles: File[]) => {
		acceptedFiles.forEach((file, index) => {
			if (index === 0) {
				updateTab({ name: file.name, content: { file } })
			} else {
				createNewTab('file', { name: file.name, content: { file } })
			}
		})
	}

	const setAcceptFileTypes = (types: string | string[]) => {
		if (typeof types === 'string') {
			setAcceptTypes({
				[types]: [],
			})
		} else {
			const newAcceptTypes: Accept = types.reduce((acc: Accept, type) => {
				acc[type] = []
				return acc
			}, {})

			setAcceptTypes(newAcceptTypes)
		}
	}

	const deleteFile = (e: any) => {
		e.stopPropagation()
		updateTab({ content: { file: null } })
	}

	const onCloseTranscription = async () => {
		if (transcription === fileInfo?.transcription) {
			setOpenPreview(false)
			return
		}

		await tryCatch(
			async () => {
				await query('/file/updateTranscription', 'POST', {
					withCredentials: true,
					data: {
						id: fileInfo?.id,
						transcription,
					},
				})
				setOpenPreview(false)
			},
			undefined,
			{ message: 'Update transcription error. Try again.' },
		)
	}

	const fileFormatData = fileFormatMapper(content.file?.type || fileInfo?.contentType)

	if (loading) {
		return (
			<div className={styles.uploadContainer}>
				<Loading />
			</div>
		)
	}

	return (
		<div className={styles.uploadContainer}>
			<UploadFileWrapper
				className={styles.uploadFileWrapper}
				ref={uploadFileRef}
				acceptTypes={acceptTypes}
				maxFiles={10}
				onUpload={onDrop}
			>
				{fileFormatData ? (
					<div className={styles.uploadedFile}>
						<div className={styles.previewFile}>
							<CloseOutlined onClick={deleteFile} className={styles.deleteFileIcon} />
							<img className={styles.fileIcon} src={fileFormatData.icon} alt='File icon' />
						</div>

						{content.file?.name || fileInfo?.filename || 'Missing file name'}
						{!!fileInfo?.transcription && (
							<Button
								onClick={(e) => {
									e.stopPropagation()
									setOpenPreview(true)
								}}
								type='text'
								className={styles.previewTranscription}
							>
								Preview transcription
							</Button>
						)}

						{!fileInfo?.transcription && (
							<div className={styles.previewTranscription}>
								Transcript preview will be available after the context reindex.
							</div>
						)}
					</div>
				) : (
					<div className={styles.emptyFileContent}>
						<p>
							<b>Upload the selected source files.</b>
						</p>
						<div className={styles.supportFiles}>
							We support most of the most popular formats of text files, documents, images, photos,
							audio and video files.
						</div>
						<div className={styles.fileLimits}>
							Up to 10 files each with a maximum weight of 10mb can be uploaded at one time.
						</div>

						<FileFormats onClick={setAcceptFileTypes} />
					</div>
				)}
			</UploadFileWrapper>

			<Drawer
				destroyOnClose
				title='Transcription preview'
				placement='right'
				size='large'
				onClose={onCloseTranscription}
				open={openPreview}
			>
				<TextArea
					variant='borderless'
					className={styles.transcription}
					value={transcription}
					onChange={(e) => setTranscription(e.target.value)}
				/>
			</Drawer>
		</div>
	)
}

export default UploadContextFile
