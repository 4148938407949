export type OmitEvery<T, K extends keyof any> = T extends any ? Omit<T, K> : never

export type ChangeDate = {
	date: Date
	userId?: string
}

export type UsingUserId = {
	usingUserId?: string
}

export const DEFAULT_LLM_NAME = 'gpt-4o mini' as const
export const LLM_NAMES = ['gpt-4o', 'gpt-4o mini', 'gpt-3.5-turbo'] as const
export const LLM_MODEL_NAMES = [
	'gpt-4o-2024-08-06',
	'gpt-4o-mini-2024-07-18',
	'gpt-3.5-turbo-0125',
] as const

export const MAIN_MODEL_NAME = 'gpt-4o-2024-08-06' as const
export const ADDITIONAL_MODEL_NAME = 'gpt-4o-mini-2024-07-18' as const

export const LANGUAGES = [
	'Bulgarian:BG',
	'Czech:CS',
	'Danish:DA',
	'German:DE',
	'Greek:EL',
	'English:EN',
	'Spanish:ES',
	'Estonian:ET',
	'Finnish:FI',
	'French:FR',
	'Hungarian:HU',
	'Indonesian:ID',
	'Italian:IT',
	'Lithuanian:LT',
	'Latvian:LV',
	'Norwegian:NB',
	'Dutch:NL',
	'Polish:PL',
	'Portuguese:PT',
	'Romanian:RO',
	'Russian:RU',
	'Slovak:SK',
	'Slovenian:SL',
	'Swedish:SV',
	'Turkish:TR',
	'Ukrainian:UK',
]
export const DEFAULT_LANGUAGE: Language = 'English:EN' as const

export type Language = (typeof LANGUAGES)[number]
export type LLMName = (typeof LLM_NAMES)[number]
export type LLMModelName = (typeof LLM_MODEL_NAMES)[number]

export type AccountProviderInfo = {
	firstName?: string
	timezone?: number
	gender?: string
	note?: string
}
