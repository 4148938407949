import Joi from 'joi'
import { WARMUP_LEVEL } from '@aiapp/types/assistant/assistantRole'
import { SELLER_OFFERS_GOALS } from '@aiapp/types/assistant/roles/sellerOffers'
import { assistantRoleBaseSchema, GoalUrlSchema } from '../assistantRole'
import { usingUserIdSchema, uuidIdSchema } from '../../global'

const sellerOffersRoleSchema = {
	...assistantRoleBaseSchema,
	type: Joi.string().valid('sellerOffers'),
	companyOffer: Joi.string().max(3000),
	promotionsDescription: Joi.string().max(1500),
	warmupLevel: Joi.string().valid(...WARMUP_LEVEL),
	goal: Joi.string().valid(...SELLER_OFFERS_GOALS),
	offersUrls: Joi.any().when('goal', {
		is: 'offersUrls',
		then: Joi.array().items(GoalUrlSchema).min(1).max(5),
		otherwise: Joi.any().forbidden(),
	}),
}

export const createSellerOffersRoleSchema = {
	assistantId: sellerOffersRoleSchema.assistantId.required(),
	name: sellerOffersRoleSchema.name.required(),
	additionalInformation: sellerOffersRoleSchema.additionalInformation.optional(),
	naturalizationBooster: sellerOffersRoleSchema.naturalizationBooster.optional(),
	redirectConditions: sellerOffersRoleSchema.redirectConditions.optional(),

	type: sellerOffersRoleSchema.type.required(),
	companyOffer: sellerOffersRoleSchema.companyOffer.required(),
	promotionsDescription: sellerOffersRoleSchema.promotionsDescription.optional(),
	disabledEmoji: sellerOffersRoleSchema.disabledEmoji.required(),
	preferredEmoji: sellerOffersRoleSchema.preferredEmoji.optional(),
	followupInterval: sellerOffersRoleSchema.followupInterval.optional(),
	maxFollowups: sellerOffersRoleSchema.maxFollowups.optional(),
	conversationStyle: sellerOffersRoleSchema.conversationStyle.required(),
	responseLength: sellerOffersRoleSchema.responseLength.required(),
	warmupLevel: sellerOffersRoleSchema.warmupLevel.required(),
	goal: sellerOffersRoleSchema.goal.required(),
	offersUrls: sellerOffersRoleSchema.offersUrls.required(),
	...usingUserIdSchema,
}

export const updateSellerOffersRoleSchema = {
	id: uuidIdSchema.required(),
	assistantId: sellerOffersRoleSchema.assistantId.required(),
	name: sellerOffersRoleSchema.name.optional(),
	additionalInformation: sellerOffersRoleSchema.additionalInformation.optional(),
	naturalizationBooster: sellerOffersRoleSchema.naturalizationBooster.optional(),
	redirectConditions: sellerOffersRoleSchema.redirectConditions.optional(),

	type: sellerOffersRoleSchema.type.optional(),
	companyOffer: sellerOffersRoleSchema.companyOffer.optional(),
	promotionsDescription: sellerOffersRoleSchema.promotionsDescription.optional(),
	disabledEmoji: sellerOffersRoleSchema.disabledEmoji.optional(),
	preferredEmoji: sellerOffersRoleSchema.preferredEmoji.optional(),
	followupInterval: sellerOffersRoleSchema.followupInterval.optional(),
	maxFollowups: sellerOffersRoleSchema.maxFollowups.optional(),
	conversationStyle: sellerOffersRoleSchema.conversationStyle.optional(),
	responseLength: sellerOffersRoleSchema.responseLength.optional(),
	warmupLevel: sellerOffersRoleSchema.warmupLevel.optional(),
	goal: sellerOffersRoleSchema.goal.optional(),
	offersUrls: sellerOffersRoleSchema.offersUrls.optional(),
}
