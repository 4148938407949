import Joi, { ValidationOptions } from 'joi'
import { filterObjectByValues } from './helpers'

export const formValidate = (
	values: Record<string, any>,
	schema: Joi.SchemaLike, // todo: replace with Joi.ObjectSchema and remove Joi.compile
	customValidatorCodeMap?: Record<string, string>, // TODO: use it as custom errors
	additionalOptions?: ValidationOptions,
): Record<string, any> => {
	const { error } = Joi.compile(schema).validate(filterObjectByValues(values, ['', null]), {
		abortEarly: false,
		...additionalOptions,
	})

	const errorDetails = error?.details ?? []

	const mappedError: Record<string, any> = {}

	for (const errorDetail of errorDetails) {
		const key = errorDetail.context?.key

		if (!key) {
			continue
		}

		mappedError[key] = errorDetail.message
	}

	return mappedError
}
