import styles from './ScrollTo.module.scss'
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import React from 'react'

type Props = {
	scrollTo: () => void
	scrollToVisible: boolean
	direction: 'top' | 'down'
	className?: string
}

const ScrollTo = ({ scrollTo, scrollToVisible, direction, className = '' }: Props) => {
	return (
		<Button
			onClick={scrollTo}
			type='text'
			className={`${styles.scrollTo} ${
				scrollToVisible ? styles.showScrollTo : styles.hideScrollTo
			} ${className}`}
		>
			{direction === 'top' ? <ArrowUpOutlined /> : <ArrowDownOutlined />}
		</Button>
	)
}

export default ScrollTo
