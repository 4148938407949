import React from 'react'
import { CloseOutlined, InboxOutlined } from '@ant-design/icons'
import UploadFileWrapper from '../../../../../../components/UploadFileWrapper/UploadFileWrapper'
import { useAssistantRoleContext } from '../../../../../../hooks/context/AssistantRoleContext'
import fileFormatMapper from '../../../../../../utils/fileFormatMapper'
import styles from './KnowledgeFileUploader.module.scss'

const KnowledgeFileUploader = () => {
	const assistantRoleContext = useAssistantRoleContext()
	const contextFile = assistantRoleContext.useSubscribe((context) => context.contextFile)

	const updateContextFile = (file: File) => {
		assistantRoleContext.setContextFile(file)
	}

	const fileFormatData = fileFormatMapper(contextFile?.type)

	return (
		<UploadFileWrapper
			className={styles.uploadFileWrapper}
			maxFiles={10}
			onUpload={(files) => updateContextFile(files[0])}
		>
			<div className={styles.dropzoneContent}>
				{contextFile && fileFormatData?.icon ? (
					<div className={styles.previewFileWrapper}>
						<div className={styles.previewFile}>
							<CloseOutlined
								onClick={(e) => {
									e.stopPropagation()
									assistantRoleContext.setContextFile(undefined)
								}}
								className={styles.deleteFileIcon}
							/>
							<img className={styles.fileIcon} src={fileFormatData.icon} alt='Uploaded file icon' />
						</div>
						<div className={styles.filename}>{contextFile.name}</div>
					</div>
				) : (
					<>
						<InboxOutlined className={styles.dropzoneIcon} />
						<div className={styles.contentHeading}>
							Click to upload a file or drag and drop it here
						</div>
						<div className={styles.contentInfo}>
							We support most of the most popular formats of text files, documents, images, photos,
							audio and video files.
						</div>
					</>
				)}
			</div>
		</UploadFileWrapper>
	)
}

export default KnowledgeFileUploader
