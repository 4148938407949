import Line from '../../../../../../components/Line/Line'
import styles from './FormHeading.module.scss'

type Props = {
	title: string
}

const FormHeading = ({ title }: Props) => {
	return (
		<>
			<h3 className={styles.headingLabel}>{title}</h3>
			<Line />
		</>
	)
}

export default FormHeading
