import { ConversationMessageCategory, SalesStage } from '../assistant/conversationMessage'
import { Lead } from '../leadsGroup/lead'
import { FailureObject } from '../global/failure'
import { UsingUserId } from '../global'

export interface Integration {
	id: string
	name: string
	provider: IntegrationProvider
	status: IntegrationStatus
	config: IntegrationConfig
	reactions?: IntegrationReactions
	userId: string
	createdAt: Date
	updatedAt?: Date
	lastStatusCheckAt?: Date

	credentials?: IntegrationCredentials
}

export type PublicIntegration = Omit<Integration, 'credentials' | 'lastStatusCheckAt'> & {
	credentials?: PublicCredentials
}

export type PublicCredentials = ProviderIntegrationBase & { phoneNumbers?: WhatsappPhoneNumbers }

export type IntegrationConfig = {
	intelligentResponseTime: boolean
	intelligentWritingTime: boolean
	activeLabels?: string[]
	excludeMessageDescriptions?: string[]
}

export type IntegrationReactions = {
	instagramCommentConversation?: InstagramCommentConversationReaction
}

export type InstagramCommentConversationReaction = {
	onlyMentions?: boolean
	startConversationMessages?: string[]
}

export type IntegrationCredentials =
	| MessengerIntegrationCredentials
	| InstagramIntegrationCredentials
	| WhatsappIntegrationCredentials
	| GmailIntegrationCredentials

type MessengerPageId = string
export type MessengerIntegrationCredentials = {
	type: 'messenger'
	userAccessToken: string
	pages: Record<
		MessengerPageId,
		{
			name: string
			accessToken: string
		}
	>
} & ProviderIntegrationBase

type InstagramAccountId = string
export type InstagramIntegrationCredentials = {
	type: 'instagram'
	userAccessToken: string
	pages: Record<
		InstagramAccountId,
		{
			pageId: string
			name: string
			accessToken: string
		}
	>
} & ProviderIntegrationBase

type WhatsappPhoneNumberId = string
export type WhatsappPhoneNumbers = Record<
	WhatsappPhoneNumberId,
	{
		name: string
		phoneNumber: string
	}
>
export type WhatsappIntegrationCredentials = {
	type: 'whatsapp'
	businessAccountId: string
	accessToken: string
	phoneNumbers: WhatsappPhoneNumbers
} & ProviderIntegrationBase

export type GmailIntegrationCredentials = {
	type: 'gmail'
	email: string
	lastHistoryId: string
	accessToken: string
	refreshToken: string
} & ProviderIntegrationBase

type ProviderIntegrationBase = {
	providerIds: string[] // e.g page ids
	providerNames: string[] // e.g page names
}

export const INTEGRATION_PROVIDERS = [
	'playground',
	'messenger',
	'instagram',
	'whatsapp',
	'gmail',
] as const
export type IntegrationProvider = (typeof INTEGRATION_PROVIDERS)[number]

export const INTEGRATION_STATUSES = ['active', 'inactive', 'deleting'] as const
export type IntegrationStatus = (typeof INTEGRATION_STATUSES)[number]

export const MESSAGE_REACTIONS = ['like', 'love'] as const
export type MessageReaction = (typeof MESSAGE_REACTIONS)[number]

export type GetIntegrationRequest = Pick<Integration, 'id'>
export type FilterUserIntegrationsRequest = {
	providers?: IntegrationProvider[]
} & UsingUserId
export type GetIntegrationsForAssistantRoleRequest = {
	assistantRoleId: string
} & UsingUserId
export type CreateIntegrationRequest = {
	name: string
	provider: IntegrationProvider
	config: IntegrationConfig
	reactions: IntegrationReactions
	accessCode?: string
} & UsingUserId
export type CreatePlaygroundIntegrationRequest = Pick<Integration, 'name' | 'userId'>
export type UpdateIntegrationRequest = {
	id: string
	name?: string
	provider?: IntegrationProvider
	config?: IntegrationConfig
	reactions?: IntegrationReactions

	accessCode?: string
	removeCredentials?: true
}
export type DeleteIntegrationRequest = Pick<Integration, 'id'>
export type RetryProviderMessageSendRequest = {
	conversationMessageId: string
}
export type PublishProviderMessageRequest = {
	conversationMessageId: string
}
export type TriggerConversationResponseRequest = {
	conversationId: string
}

export type NewMessageAction = {
	type: 'newUserMessage' | 'newAssistantMessage'
	userId: string
	content: string
	subject?: string
	conversationId?: string
	providerConversationId?: string
	providerMessageId?: string
	assistantProviderId?: string
	userProviderId?: string
	assistantRoleId?: string
	manual?: boolean
}
export type StartCommentConversationAction = {
	type: 'startCommentConversation'
	comment: string
	userProviderId: string
	assistantProviderId: string
	startConversationMessage?: string
}
export type StartLeadConversationAction = {
	type: 'startLeadConversationAction'
	content: string
	reminderContent: string
	lead: Pick<Lead, 'firstname' | 'email' | 'phone' | 'gender' | 'id' | 'note'>
	publishAt?: Date

	// email
	subject?: string
	signature?: string
}
export type TypingResponseAction = {
	type: 'typingResponse'
	conversationId: string
	markSeen?: boolean
}
export type ResponseAction = {
	type: 'response'
	conversationId: string
	userId: string
	remind?: boolean
	manual?: boolean
}
export type SetResponseTimeAction = {
	type: 'setResponseTime'
	conversationId: string
	responseTime: string
}
export type UserMessageReactionAction = {
	type: 'userMessageReaction'
	conversationId: string
	messageId: string
	reaction: MessageReaction
}
export type UpdateAssistantMessageAction = {
	type: 'updateAssistantMessage'
	userId: string
	conversationId: string
	messageId: string
	content: string
	category?: ConversationMessageCategory
	salesStage?: SalesStage
	redirectReason?: string
	summary?: string
	error?: FailureObject
	processing?: boolean
}

export type OnIntegrationAction = {
	integrationId: string
	action:
		| NewMessageAction
		| StartCommentConversationAction
		| StartLeadConversationAction
		| TypingResponseAction
		| ResponseAction
		| SetResponseTimeAction
		| UserMessageReactionAction
		| UpdateAssistantMessageAction
}

export type AccessTokenInfoResponse = {
	data: {
		user_id: string
		app_id: string
		type: string
		application: string
		data_access_expires_at: number
		expires_at: number
		is_valid: boolean
		issued_at: number
		scopes: string[]
		granular_scopes: {
			scope: string
			target_ids: string[]
		}[]
	}
}

// REDIRECT REASONS
export const NO_INTEGRATION_REDIRECT_REASON =
	'The integration assigned to this conversation has been removed.' as const
export const MANUAL_REDIRECT_REASON = 'Manual redirection.' as const
export const ERROR_REDIRECT_REASON = 'Getting error when trying to respond.' as const
