import { AssistantRoleType } from '../assistant/assistantRole'

export interface Order {
	id: string
	agreementId: string
	userId: string
	status: OrderStatus
	paymentProcessing: boolean
	isPaymentMethodVerification?: true
	products: Product[]
	currency: Currency
	paymentInterval: PaymentInterval
	totalPrice: number
	paidAmount: number
	lastPaymentAt?: Date
	nextPaymentAt?: Date
	updatedAt?: Date
	createdAt: Date
}

export type Product = TrainingProduct | PersonaProduct | RolesProduct | VerificationProduct

export type TrainingProduct = {
	type: 'training'
	baseCost: number
	additionalCost: number
	trainingDays: number
}

export type PersonaProduct = {
	type: 'personas'
	personasNumber: number
	baseCost: number
	additionalCost: number
	roles?: AssistantRoleType[]
}

export type RolesProduct = {
	type: 'roles'
	baseCost: number
	additionalCost: number
	roles: AssistantRoleType[]
}

export type VerificationProduct = {
	type: 'verification'
	baseCost: number
}

export const PAYMENT_INTERVALS = ['month', 'once'] as const
export type PaymentInterval = (typeof PAYMENT_INTERVALS)[number]

export const PRODUCT_TYPES = ['training', 'personas', 'roles'] as const
export type ProductType = (typeof PRODUCT_TYPES)[number]

export const CURRENCIES = ['PLN'] as const
export type Currency = (typeof CURRENCIES)[number]

export const ORDER_STATUSES = [
	'draft',
	'pending',
	'active',
	'inactive',
	'purchased',
	'paymentProblem',
	'cancelled',
] as const
export type OrderStatus = (typeof ORDER_STATUSES)[number]

export const BASE_PERSONA_PRICE = 750000
export const BASE_PERSONA_ROLE_PRICE = 150000
export const BASE_TRAINING_COSTS = [30000, 47000, 74000, 88000] as const

export type CreateOrder = Pick<
	Order,
	'userId' | 'status' | 'agreementId' | 'products' | 'currency' | 'paymentInterval' | 'totalPrice'
>

export type CreateOrderProduct =
	| Omit<TrainingProduct, 'baseCost'>
	| Omit<PersonaProduct, 'baseCost'>
	| Omit<RolesProduct, 'baseCost'>
	| Omit<VerificationProduct, 'baseCost'>

export type CreateOrderRequest = {
	currency: Currency
	paymentInterval: PaymentInterval
	products: CreateOrderProduct[]
	userId: string
}

export interface UpdateOrderStatusRequest {
	id: string
	status: OrderStatus
}
