import { App, Layout } from 'antd'
import React, { useMemo, useState } from 'react'
import { useChatContext } from '../../../hooks/context/ChatContext'
import query from '../../../utils/query'
import useTryCatch from '../../../hooks/useTryCatch'
import { Conversation } from '@aiapp/types/assistant/conversation'
import useAsyncEffect from '../../../hooks/useAsyncEffect'
import Loading from '../../Loading/Loading'
import { ConversationUsage } from '@aiapp/types/usage'
import { AssistantRole } from '@aiapp/types/assistant/assistantRole'
import ConversationInfo from './ConversationInfo/ConversationInfo'
import styles from './ConversationSession.module.scss'
import { Assistant } from '@aiapp/types/assistant'
import { toPublicSession } from '../../../utils/chatMappers'

const { Sider } = Layout

const ConversationSession = () => {
	const { message } = App.useApp()
	const tryCatch = useTryCatch(message)

	const chatContext = useChatContext()
	const currentChatSession = chatContext.useSubscribe((context) => context.currentChatSession)
	const sessionsCollapsed = chatContext.useSubscribe((context) => context.sessionsCollapsed)

	const [conversation, setConversation] = useState<Conversation | null>(null)
	const [conversationUsage, setConversationUsage] = useState<ConversationUsage | null>(null)
	const [assistant, setAssistant] = useState<Assistant>()
	const [assistantRoles, setAssistantRoles] = useState<AssistantRole[] | undefined>()

	const [triggerConversationResponseLoading, setTriggerConversationResponseLoading] =
		useState<boolean>(false)
	const [redirectConversationLoading, setRedirectConversationLoading] = useState<boolean>(false)

	const fetchConversationData = async () => {
		if (!currentChatSession?.id) {
			return
		}

		await tryCatch(async () => {
			const resConversation = await query<Conversation>('/assistant/conversation/get', 'GET', {
				params: {
					id: currentChatSession.id,
				},
				withCredentials: true,
			})
			const resConversationUsage = await query<ConversationUsage>('/usage/forConversation', 'GET', {
				params: {
					conversationId: currentChatSession.id,
				},
				withCredentials: true,
			})

			setAssistant(await getAssistants(resConversation))
			setAssistantRoles(await getIntegrationAssistantRoles(resConversation.integration?.id))

			setTriggerConversationResponseLoading(false)
			setRedirectConversationLoading(false)

			setConversationUsage(resConversationUsage)
			setConversation(resConversation)
		})
	}

	const getAssistants = async (conversation: Conversation): Promise<Assistant | undefined> => {
		if (!conversation.assistantId) {
			return
		}

		return await tryCatch(async () => {
			return await query<Assistant>('/assistant/get', 'GET', {
				params: {
					id: conversation.assistantId,
				},
				withCredentials: true,
			})
		})
	}

	const getIntegrationAssistantRoles = async (
		integrationId: string | undefined,
	): Promise<AssistantRole[] | undefined> => {
		if (!integrationId) {
			return
		}

		return await tryCatch(async () => {
			return await query<AssistantRole[]>('/assistant/roles/byIntegration', 'GET', {
				params: {
					integrationId,
				},
				withCredentials: true,
			})
		})
	}

	useAsyncEffect(fetchConversationData, [currentChatSession])

	const manualTriggerConversationResponse = async () => {
		if (!conversation || triggerConversationResponseLoading) {
			return
		}

		setTriggerConversationResponseLoading(true)
		query('/integration/triggerResponse', 'POST', {
			data: {
				conversationId: conversation.id,
			},
			withCredentials: true,
		}).catch(() => setTriggerConversationResponseLoading(false))
	}

	const manualRedirectConversation = async () => {
		if (
			!conversation ||
			conversation.ended ||
			conversation.redirected ||
			redirectConversationLoading
		) {
			return
		}

		setRedirectConversationLoading(true)
		await tryCatch(
			async () => {
				const updatedConversation = await query<Conversation>(
					'/assistant/conversation/redirect',
					'POST',
					{
						data: {
							id: conversation.id,
						},
						withCredentials: true,
					},
				)
				chatContext.setCurrentChatSession(toPublicSession(updatedConversation))
			},
			() => {
				setRedirectConversationLoading(false)
			},
		)
	}

	const restoreAssistantManageConversation = async () => {
		if (!conversation || conversation.ended || !conversation.redirected) {
			return
		}

		await tryCatch(async () => {
			const updatedConversation = await query<Conversation>(
				'/assistant/conversation/restore',
				'POST',
				{
					data: {
						id: conversation.id,
					},
					withCredentials: true,
				},
			)
			chatContext.setCurrentChatSession(toPublicSession(updatedConversation))
		})
	}

	return useMemo(
		() => (
			<Sider
				width='25%'
				collapsedWidth={0}
				theme='light'
				trigger={null}
				collapsible
				collapsed={sessionsCollapsed}
			>
				<div className={styles.menuContainer}>
					{conversation ? (
						<ConversationInfo
							triggerConversationResponseLoading={triggerConversationResponseLoading}
							redirectConversationLoading={redirectConversationLoading}
							manualTriggerConversationResponse={manualTriggerConversationResponse}
							manualRedirectConversation={manualRedirectConversation}
							restoreAssistantManageConversation={restoreAssistantManageConversation}
							conversation={conversation}
							conversationUsage={conversationUsage}
							assistant={assistant}
							assistantRoles={assistantRoles}
						/>
					) : (
						<Loading />
					)}
				</div>
			</Sider>
		),
		[
			conversation,
			triggerConversationResponseLoading,
			sessionsCollapsed,
			conversationUsage,
			assistant,
			assistantRoles,
			redirectConversationLoading,
		],
	)
}

export default ConversationSession
