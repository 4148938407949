import { Card, Tooltip } from 'antd'
import { useMemo } from 'react'
import { useEditorContext } from '../../../../hooks/context/EditorContext'
import { Fade } from 'react-awesome-reveal'
import styles from './StartCard.module.scss'

import textToImage from '../../../../assets/toolsIcons/textToImage.webp'
import textToLogo from '../../../../assets/toolsIcons/textToLogo.webp'
import textToMaterialTexture from '../../../../assets/toolsIcons/textToMaterialTexture.webp'
import imageToVideo from '../../../../assets/toolsIcons/imageToVideo.webp'
import imageInterpreter from '../../../../assets/toolsIcons/imageInterpreter.webp'
import imagesRestoration from '../../../../assets/toolsIcons/imagesRestoration.webp'
import imageResize from '../../../../assets/toolsIcons/imageResize.webp'
import faceSwap from '../../../../assets/toolsIcons/faceSwap.webp'
import removeBackground from '../../../../assets/toolsIcons/removeBackground.webp'
import textToMusic from '../../../../assets/toolsIcons/textToMusic.webp'
import textToSpeech from '../../../../assets/toolsIcons/textToSpeech.webp'
import speechToText from '../../../../assets/toolsIcons/speechToText.webp'

const StartCard = () => {
	const editorContext = useEditorContext()

	const tools = [
		{
			name: 'Text to image',
			src: textToImage,
			onClick: () => editorContext.setToolType('TEXT_TO_IMAGE'),
		},
		{
			name: 'Text to logotype',
			src: textToLogo,
			onClick: () => editorContext.setToolType('TEXT_TO_LOGO_OR_ICON'),
		},
		{
			name: 'Text to material texture',
			src: textToMaterialTexture,
			onClick: () => editorContext.setToolType('TEXT_TO_TEXTURE'),
		},
		{
			name: 'Image to video',
			src: imageToVideo,
			onClick: () => editorContext.setToolType('IMAGE_TO_VIDEO'),
		},
		{
			name: 'Image interpreter',
			src: imageInterpreter,
			onClick: () => editorContext.setToolType('IMAGE_TO_TEXT'),
		},
		{
			name: 'Image upgrader',
			src: imagesRestoration,
			onClick: () => editorContext.setToolType('IMAGE_RESTORATION'),
		},
		{
			name: 'Image resizer',
			src: imageResize,
			onClick: () => editorContext.setToolType('IMAGE_RESIZER'),
		},
		{
			name: 'Face swap',
			src: faceSwap,
			onClick: () => editorContext.setToolType('FACE_SWAP'),
		},
		{
			name: 'Background remover',
			src: removeBackground,
			onClick: () => editorContext.setToolType('BACKGROUND_REMOVER'),
		},
		{
			name: 'Text to music',
			src: textToMusic,
			onClick: () => editorContext.setToolType('TEXT_TO_MUSIC'),
		},
		{
			name: 'Text to speech',
			src: textToSpeech,
			onClick: () => editorContext.setToolType('TEXT_TO_SPEECH'),
		},
		{
			name: 'Speech to text',
			src: speechToText,
			onClick: () => editorContext.setToolType('SPEECH_TO_TEXT'),
		},
	]

	return useMemo(
		() => (
			<Fade direction='up' triggerOnce duration={750} fraction={0}>
				<Card className={styles.startCard}>
					<div className={styles.heading}>Try to use any of the available tools!</div>
					<div className={styles.toolWrapper}>
						<Fade direction='left' cascade triggerOnce duration={200} fraction={0}>
							{tools.map((tool) => (
								<Tooltip key={tool.name} title={tool.name}>
									<img
										onClick={tool.onClick}
										className={styles.toolIcon}
										src={tool.src}
										alt={tool.name}
									/>
								</Tooltip>
							))}
						</Fade>
					</div>
				</Card>
			</Fade>
		),
		[],
	)
}

export default StartCard
