import { Button, Form, Input, Radio } from 'antd'
import FormRow from '../../../../../components/FormRow/FormRow'
import { Lead } from '@aiapp/types/leadsGroup/lead'
import styles from './LeadForm.module.scss'

const { TextArea } = Input

type Props = {
	leadData?: Partial<Lead>
	onFinish: (formData: Partial<Lead>) => void
	onClose: (refresh?: boolean) => Promise<void>
}

const LeadForm = ({ onFinish, leadData, onClose }: Props) => (
	<Form
		autoComplete='true'
		className={styles.form}
		initialValues={leadData ?? {}}
		onFinish={onFinish}
		labelCol={{ span: 4 }}
		wrapperCol={{ span: 14 }}
		layout='vertical'
	>
		<FormRow columns={[2, 2, 1]}>
			<Form.Item
				rules={[
					{ type: 'email', message: 'Invalid e-mail format' },
					{
						message: 'E-mail can have a maximum of 250 characters',
						max: 250,
					},
				]}
				className={styles.formItem}
				label='E-mail'
				name='email'
			>
				<Input
					count={{
						show: true,
					}}
					maxLength={250}
					size='large'
					className={styles.formInput}
					placeholder='example@quantumneuron.eu'
				/>
			</Form.Item>

			<Form.Item
				rules={[{ message: 'Phone can have a maximum of 16 characters', max: 16 }]}
				className={styles.formItem}
				label='Phone'
				name='phone'
			>
				<Input
					count={{
						show: true,
					}}
					maxLength={16}
					size='large'
					className={styles.formInput}
					placeholder='+48 123 456 789'
				/>
			</Form.Item>

			<Form.Item className={styles.formItem} label='Gender' name='gender'>
				<Radio.Group size='large'>
					<Radio.Button value='male'>Male</Radio.Button>
					<Radio.Button value='female'>Female</Radio.Button>
				</Radio.Group>
			</Form.Item>
		</FormRow>

		<FormRow>
			<Form.Item
				rules={[
					{ message: 'Firstname must have a minimum of 3 characters', min: 3 },
					{ message: 'Firstname can have a maximum of 50 characters', max: 50 },
				]}
				className={styles.formItem}
				label='Firstname'
				name='firstname'
			>
				<Input
					count={{
						show: true,
					}}
					maxLength={50}
					size='large'
					className={styles.formInput}
					placeholder='Firstname'
				/>
			</Form.Item>
			<Form.Item
				rules={[
					{ message: 'Lastname must have a minimum of 3 characters', min: 3 },
					{ message: 'Lastname can have a maximum of 50 characters', max: 80 },
				]}
				className={styles.formItem}
				label='Lastname'
				name='lastname'
			>
				<Input
					count={{
						show: true,
					}}
					maxLength={80}
					size='large'
					className={styles.formInput}
					placeholder='Lastname'
				/>
			</Form.Item>
		</FormRow>

		<Form.Item
			rules={[{ message: 'Note can have a maximum of 1000 characters', max: 1000 }]}
			className={styles.formItem}
			label='Note'
			name='note'
		>
			<TextArea
				className={styles.formTextarea}
				size='large'
				allowClear
				count={{
					show: true,
				}}
				maxLength={1000}
				defaultValue=''
				autoSize={{ minRows: 2, maxRows: 6 }}
				placeholder='Note'
			/>
		</Form.Item>

		<Form.Item noStyle>
			<div className={styles.buttons}>
				<Button size='large' htmlType='submit' type='primary'>
					Submit
				</Button>
				<Button size='large' onClick={() => onClose()}>
					Cancel
				</Button>
			</div>
		</Form.Item>
	</Form>
)

export default LeadForm
