import { Button, Form, Input, Switch } from 'antd'
import React from 'react'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import Line from '../../../../../components/Line/Line'
import FormRow from '../../../../../components/FormRow/FormRow'
import styles from './GlobalConfig.module.scss'

const GlobalConfig = () => {
	return (
		<div className={styles.configWrapper}>
			<h4 className={styles.titleLabel}>Configurations</h4>
			<Line className={styles.line} />

			<FormRow>
				<Form.Item
					label='Intelligent response time'
					name={['config', 'intelligentResponseTime']}
					valuePropName='checked'
					className={styles.formItem}
					tooltip='The system adjusts the response time to user messages to simulate human activity and writing proccess.'
				>
					<Switch />
				</Form.Item>

				<Form.Item
					label='Intelligent writing time'
					name={['config', 'intelligentWritingTime']}
					valuePropName='checked'
					className={styles.formItem}
					tooltip='The system itself will select the time of writing messages in a conversation based on the average time of human writing.'
				>
					<Switch />
				</Form.Item>
			</FormRow>

			<Form.Item
				tooltip='Define the message descriptions for which you want to exclude the creation of a new conversation.'
				className={`${styles.formItem} ${styles.excludeMessage}`}
				label='Message exclusions'
			>
				<Form.List name={['config', 'excludeMessageDescriptions']}>
					{(fields, { add, remove }) => (
						<>
							{fields.map(({ key, name, ...restField }) => (
								<div key={key} className={styles.fieldList}>
									<Form.Item
										{...restField}
										rules={[
											{
												required: true,
												message: 'Please fill description of the message exclusion',
											},
											{
												message:
													'Description of the message exclusion must have a minimum of 10 characters',
												min: 6,
											},
											{
												message:
													'Description of the message exclusion can have a maximum of 500 characters',
												max: 500,
											},
										]}
										className={styles.formItem}
										name={name}
									>
										<Input
											maxLength={500}
											count={{
												show: true,
											}}
											size='large'
											className={styles.formInput}
											placeholder='Description of the message exclusion'
										/>
									</Form.Item>

									<MinusCircleOutlined
										className={styles.listRemoveIcon}
										onClick={() => remove(name)}
									/>
								</div>
							))}
							<Form.Item className={styles.formItem}>
								<Button
									size='large'
									type='dashed'
									disabled={fields.length === 5}
									onClick={() => {
										fields.length < 5 && add()
									}}
									block
									icon={<PlusOutlined />}
								>
									Add new message exclusion
								</Button>
							</Form.Item>
						</>
					)}
				</Form.List>
			</Form.Item>
		</div>
	)
}

export default GlobalConfig
