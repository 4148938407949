import Joi from 'joi'
import { usingUserIdSchema, uuidIdSchema } from '../../global'
import { assistantRoleBaseSchema, GoalUrlSchema } from '../assistantRole'

const externalAssistantRoleSchema = {
	...assistantRoleBaseSchema,
	type: Joi.string().valid('externalAssistant').required(),

	companyDetails: Joi.string().max(3000),
	meetingUrls: Joi.array().items(GoalUrlSchema.required()).min(1).max(5),
}

export const createExternalAssistantRoleSchema = {
	assistantId: externalAssistantRoleSchema.assistantId.required(),
	name: externalAssistantRoleSchema.name.required(),
	additionalInformation: externalAssistantRoleSchema.additionalInformation.optional(),
	naturalizationBooster: externalAssistantRoleSchema.naturalizationBooster.optional(),
	redirectConditions: externalAssistantRoleSchema.redirectConditions.optional(),

	type: externalAssistantRoleSchema.type.required(),
	disabledEmoji: externalAssistantRoleSchema.disabledEmoji.required(),
	preferredEmoji: externalAssistantRoleSchema.preferredEmoji.optional(),
	conversationStyle: externalAssistantRoleSchema.conversationStyle.required(),
	responseLength: externalAssistantRoleSchema.responseLength.required(),
	companyDetails: externalAssistantRoleSchema.companyDetails.optional(),
	meetingUrls: externalAssistantRoleSchema.meetingUrls.required(),
	...usingUserIdSchema,
}

export const updateExternalAssistantRoleSchema = {
	id: uuidIdSchema.required(),
	assistantId: externalAssistantRoleSchema.assistantId.required(),
	name: externalAssistantRoleSchema.name.optional(),
	additionalInformation: externalAssistantRoleSchema.additionalInformation.optional(),
	naturalizationBooster: externalAssistantRoleSchema.naturalizationBooster.optional(),
	redirectConditions: externalAssistantRoleSchema.redirectConditions.optional(),

	type: externalAssistantRoleSchema.type.optional(),
	disabledEmoji: externalAssistantRoleSchema.disabledEmoji.optional(),
	preferredEmoji: externalAssistantRoleSchema.preferredEmoji.optional(),
	conversationStyle: externalAssistantRoleSchema.conversationStyle.optional(),
	responseLength: externalAssistantRoleSchema.responseLength.optional(),
	companyDetails: externalAssistantRoleSchema.companyDetails.optional(),
	meetingUrls: externalAssistantRoleSchema.meetingUrls.optional(),
}
