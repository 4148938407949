import { Currency } from '../index'
import { BillingAddress, EncryptedCardDetails } from '../paymentMethod'

export interface Payment {
	id: string
	userId: string
	orderId: string
	pspReference?: string
	status: PaymentStatus
	amount: number
	currency: Currency
	paymentAttempts?: number
	lastPaymentAttempt?: Date
	updatedAt?: Date
	createdAt: Date
}

export const PAYMENT_STATUSES = [
	'initialized', // Payment has been initialized but no further actions have been taken.
	'pending', // Payment is pending and awaiting processing (e.g., waiting for authorization).
	'error', // Internal payment problem
	'authorised', // Payment has been authorized but not yet captured.
	'captured', // Payment has been authorized and the funds have been captured.
	'expired', // Payment authorization has expired and can no longer be processed.
	'refused', // Payment was refused by the payment gateway or bank.
	'cancelled', // Payment was cancelled by the user or the system.
	'refunded', // Payment was refunded to the customer.
	'chargeback', // A chargeback has been initiated by the customer.
	'chargeback_reversed', // The chargeback was reversed, and funds were returned to the merchant.
] as const
export type PaymentStatus = (typeof PAYMENT_STATUSES)[number]

export const COMPLETED_PAYMENT_STATUSES: PaymentStatus[] = ['authorised', 'captured']
export const FAILED_PAYMENT_STATUSES: PaymentStatus[] = ['refused', 'expired', 'chargeback']

export type CreatePayment = Omit<
	Payment,
	'id' | 'createdAt' | 'userId' | 'status' | 'updatedAt' | 'paymentAttempts' | 'lastPaymentAttempt'
>

export type MakePaymentRequest = {
	orderId: string
	paymentMethod?: {
		holderName: string
		billingAddress: BillingAddress
		encryptedCardDetails: EncryptedCardDetails
	}
}

export type PrivateMakePaymentRequest = {
	orderId: string
}
