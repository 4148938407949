import { ReactNode, useEffect } from 'react'
import { Form } from 'antd'
import styles from './ShadowForm.module.scss'

type Props = {
	children: ReactNode
	initialValues?: Record<string, any>
	onValuesChange?: (changedValues: any, values: any) => void
}

const ShadowForm = ({ children, initialValues, onValuesChange }: Props) => {
	const [form] = Form.useForm()

	useEffect(() => {
		if (!initialValues) {
			return
		}

		for (const [key, value] of Object.entries(initialValues)) {
			if (form.getFieldValue(key) !== value) {
				form.resetFields()
				return
			}
		}
	}, [initialValues])

	return (
		<Form
			form={form}
			initialValues={initialValues}
			onValuesChange={onValuesChange}
			layout='vertical'
			autoComplete='off'
			className={styles.shadowForm}
		>
			{children}
		</Form>
	)
}

export default ShadowForm
