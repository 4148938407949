import React from 'react'
import { App, Button, Table, Tag, Tooltip } from 'antd'
import type { TableProps } from 'antd'
import { formatDate } from '@aiapp/utils/dates'
import { Context, ContextStatus, SourceType } from '@aiapp/types/context'
import { InlineLoading } from '../../../../../../components/Loading/Loading'
import useTryCatch from '../../../../../../hooks/useTryCatch'
import query from '../../../../../../utils/query'
import { contextStatusToColor } from '../../../../../../utils/helpers'
import styles from './ContextTable.module.scss'

type Props = {
	contexts: Context[]
}

const ContextTable = ({ contexts }: Props) => {
	const { message } = App.useApp()
	const tryCatch = useTryCatch(message)

	const onRefreshContext = async (contextId: number) => {
		await tryCatch(async () => {
			await query('/context/refresh', 'POST', {
				data: { id: contextId },
				withCredentials: true,
			})
		})
	}

	const onDeleteContext = async (contextId: number) => {
		await tryCatch(
			async () => {
				await query('/context/delete', 'POST', {
					data: { id: contextId },
					withCredentials: true,
				})
			},
			undefined,
			{
				message: 'Error while deleting context. Check if context used by any chat and try again.',
			},
		)
	}

	const columns: TableProps<ContextTableData>['columns'] = [
		{
			title: 'Name',
			dataIndex: 'name',
			key: 'name',
			className: `${styles.contextValue} ${styles.contextName}`,
			ellipsis: {
				showTitle: false,
			},
			render: (value) => (
				<Tooltip placement='topLeft' title={value}>
					{value}
				</Tooltip>
			),
		},
		{
			title: 'Type',
			dataIndex: 'type',
			className: `${styles.contextValue} ${styles.contextType}`,
			key: 'type',
			width: 100,
		},
		{
			title: 'Status',
			dataIndex: 'status',
			key: 'status',
			className: styles.contextValue,
			render: (status: ContextStatus) => (
				<Tag color={contextStatusToColor[status]}>
					{status === 'refreshing' && (
						<InlineLoading iconClassName={styles.loading} color='#fff' size={12} />
					)}
					<span>{status}</span>
				</Tag>
			),
			width: 140,
		},
		{
			title: 'Refreshed date',
			dataIndex: 'reindexedAt',
			key: 'reindexedAt',
			className: styles.contextValue,
			render: (date) => formatDate(date),
			width: 180,
		},
		{
			title: 'Created date',
			dataIndex: 'createdAt',
			key: 'createdAt',
			className: styles.contextValue,
			render: (date) => formatDate(date),
			width: 180,
		},
		{
			title: 'Action',
			key: 'action',
			className: styles.contextValue,
			render: (record: ContextTableData & { key: number }) => (
				<div className={styles.actions}>
					<Button onClick={() => onRefreshContext(record.key)}>Refresh</Button>
					<Button onClick={() => onDeleteContext(record.key)}>Delete</Button>
				</div>
			),
			width: 180,
		},
	]

	const contextsToContextTable = (): Array<ContextTableData & { key: number }> => {
		return contexts.map((context) => ({
			key: context.id,
			name: context.resource.name,
			type: context.resource.type,
			status: context.status,
			reindexedAt: context.reindexedAt,
			createdAt: context.createdAt,
		}))
	}

	return <Table columns={columns} dataSource={contextsToContextTable()} />
}

interface ContextTableData {
	name: string
	type: SourceType
	status: ContextStatus
	reindexedAt?: Date
	createdAt: Date
}

export default ContextTable
