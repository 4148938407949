import { TemplateTool } from '../global/tools'

export interface ChatMessage {
	id: string
	sessionId: string
	role: MessageRole
	content: string
	sources?: string[]
	attachedFileIds?: MessageAttachedFileIds
	toolType?: TemplateTool
	parentMessageId?: string
	createdBy: string
	createdAt: Date
}

export const ATTACHED_CONTEXT_LABEL = 'CONTEXT' as const
export const ATTACHED_AI_FILES_LABEL = 'AI_FILES' as const

export const MESSAGE_ROLES = ['system', 'user', 'assistant', 'function', 'tool'] as const
export type MessageRole = (typeof MESSAGE_ROLES)[number]

export type TemplateInputId = string
export type FileId = string

export type MessageAttachedFileIds = Record<TemplateInputId, FileId[]>

export type GetChatMessageRequest = Pick<ChatMessage, 'id'>
export type GetChatMessagesRequest = Pick<ChatMessage, 'sessionId'>
export type UpdateChatMessageRequest = Pick<
	ChatMessage,
	'id' | 'content' | 'sources' | 'attachedFileIds'
> & { userId: string }

export type CreateChatMessageRequest = Pick<ChatMessage, 'content'> & {
	sessionId?: string
	chatId: string
	attachedFileIds?: MessageAttachedFileIds
	attachedSessionIds?: string[]
	toolType?: TemplateTool
}

export type RetryMessageRequest = {
	aiMessageId: string
	attachedSessionIds: string[]
}

export type CancelMessageRequest = {
	aiMessageId: string
}
