import Joi from 'joi'
import { AGREEMENT_STATUSES, BUSINESS_TURNOVER_LEVELS } from '@aiapp/types/agreement'

export const createAgreementSchema = {
	userId: Joi.string().required(),
	businessTurnoverLevel: Joi.number()
		.valid(...BUSINESS_TURNOVER_LEVELS)
		.required(),
	enterprise: Joi.boolean().required(),
	trainingDays: Joi.number().min(1).max(10).required(),
	trainingAdditionalCost: Joi.number().min(0).max(200000).required(),
	startAt: Joi.date().required(),
}

export const createAgreementRequestSchema = Joi.object(createAgreementSchema).required()

export const publicUpdateAgreementRequestSchema = Joi.object({
	id: Joi.string().required(),
	startAt: Joi.date().optional(),
	endAt: Joi.date().optional(),
})

export const privateUpdateAgreementRequestSchema = Joi.object({
	id: Joi.string().required(),
	status: Joi.string()
		.valid(...AGREEMENT_STATUSES)
		.required(),
	hasUpsell: Joi.boolean().optional(),
})
