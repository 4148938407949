import { Statistic } from 'antd'
import React from 'react'
import { PublicAnalytics } from '@aiapp/types/analytics'
import styles from './PersonaStatistics.module.scss'

type Props = {
	analytics: PublicAnalytics | undefined
	loading: boolean
}

const PersonaStatistics = ({ analytics, loading }: Props) => {
	return (
		<div className={styles.statistics}>
			<Statistic
				title={analytics && analytics?.assistantsAmount > 1 ? 'AI Personas' : 'AI Person'}
				value={analytics?.assistantsAmount}
				loading={loading}
			/>
			<Statistic title='Personas Roles' value={analytics?.assistantRolesAmount} loading={loading} />
			<Statistic
				title='Active knowledge bases'
				value={analytics?.activeContextsAmount}
				loading={loading}
			/>
		</div>
	)
}

export default PersonaStatistics
