import { OfferFormData } from './UserOffer'
import { CreateOrderRequest } from '@aiapp/types/order'

export const prepareOfferFormDataToOrderOffer = (
	formData: OfferFormData,
): Omit<CreateOrderRequest, 'userId'> => {
	const orderData: Omit<CreateOrderRequest, 'userId'> = {
		currency: formData.currency,
		paymentInterval: formData.paymentInterval,
		products: [],
	}

	if (formData.type === 'personas') {
		orderData.products.push({
			type: 'personas',
			personasNumber: formData.personasNumber!,
			roles: formData.roles,
			additionalCost: formData.additionalCost,
		})
	}

	if (formData.type === 'roles') {
		orderData.products.push({
			type: 'roles',
			roles: formData.roles,
			additionalCost: formData.additionalCost,
		})
	}

	return orderData
}
