import React, { ReactNode, useMemo } from 'react'
import { PublicMessage } from '../../../../../types/Chat'
import { messageContentIsPending } from '@aiapp/utils/assistant'
import { messageRole } from '@aiapp/utils/consts'
import MessageStatus from './MessageStatus/MessageStatus'
import { PublicProgressStep } from '@aiapp/types/progressSteps'
import { parseDateToTime, toTimezoneDate } from '@aiapp/utils/dates'
import styles from './MessageWrapper.module.scss'

const STATUS_SPACING = 15 as const
const TOP_MARGIN_SPACING = 12 as const
const TOP_SPACING = -2 as const

type Props = {
	children: ReactNode
	message: PublicMessage
	progressSteps: PublicProgressStep[]
	isLastMessage: boolean
}

const MessageWrapper = ({ children, message, progressSteps, isLastMessage }: Props) => {
	const warnings = useMemo(() => {
		if (message.role === messageRole.USER) {
			return []
		}

		const warningsArr: string[] = []
		if (message.error?.errorCode === 551) {
			warningsArr.push('This user is no longer available.')
		} else if (message.sentByProvider === false) {
			warningsArr.push('Message was not sent. Check your integration setup.')
		}
		return warningsArr
	}, [message.role, message.sentByProvider])

	const information = useMemo(() => {
		if (message.role === messageRole.USER) {
			return []
		}

		const informationArr: string[] = []
		if (message.publishAt) {
			informationArr.push(
				`This message will be published at: ${parseDateToTime(
					toTimezoneDate(message.publishAt, 'Europe/Warsaw'),
					true,
				)}`,
			)
		}
		return informationArr
	}, [message.publishAt, message.role])

	const messageProgressSteps = useMemo(() => {
		return message.role === messageRole.USER
			? []
			: progressSteps.filter(
					(step) =>
						messageContentIsPending(message.content) &&
						(step.conversationMessageId === message.id || step.messageId === message.id),
				)
	}, [message.role, message.content, message.id, progressSteps])

	const statusLength = warnings.length || information.length || messageProgressSteps.length

	return (
		<div
			className={styles.messageWrapper}
			style={{
				marginBottom: isLastMessage ? 32 : 0,
				marginTop: statusLength ? TOP_MARGIN_SPACING + STATUS_SPACING * statusLength : 0,
			}}
		>
			<div
				className={styles.messageInfo}
				style={{ top: -(TOP_SPACING + STATUS_SPACING * statusLength) }}
			>
				<MessageStatus
					warnings={warnings}
					information={information}
					messageProgressSteps={messageProgressSteps}
				/>
			</div>

			{children}
		</div>
	)
}

export default MessageWrapper
