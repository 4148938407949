import {
	ConversationStyle,
	FollowupInterval,
	GoalUrl,
	MaxFollowups,
	ResponseLength,
	WarmupLevel,
} from '../assistantRole'

export type AppointmentSetterRole = {
	type: 'appointmentSetter'
	companyOffer: string
	promotionsDescription?: string
	disabledEmoji: boolean
	preferredEmoji?: string
	followupInterval?: FollowupInterval
	maxFollowups?: MaxFollowups
	conversationStyle: ConversationStyle
	responseLength: ResponseLength
	warmupLevel: WarmupLevel
	goal: AppointmentSetterGoal
	meetingUrls?: GoalUrl[]
}

export const APPOINTMENT_SETTER_GOALS = ['meetingUrls'] as const
export type AppointmentSetterGoal = (typeof APPOINTMENT_SETTER_GOALS)[number]
