import { HelpdeskRole } from './helpdesk'
import { GoalUrl } from '../assistantRole'

export type HelpdeskUpsellRole = Omit<HelpdeskRole, 'type'> & {
	type: 'helpdeskUpsell'
	companyOffer: string
	promotionsDescription?: string
	goal: HelpdeskUpsellGoal
	offerUrls?: GoalUrl[]
}

export const HELPDESK_UPSELL_GOALS = ['offersUrls'] as const
export type HelpdeskUpsellGoal = (typeof HELPDESK_UPSELL_GOALS)[number]
