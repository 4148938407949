import Joi from 'joi'
import { usingUserIdSchema, uuidIdSchema } from '../global'

export const getReflinkRequestSchema = Joi.object({
	code: Joi.string().required(),
}).required()

export const reflinksFilterSchema = Joi.object({
	used: Joi.boolean().optional(),

	page: Joi.number().positive().integer().default(1),
	perPage: Joi.number().positive().integer().default(20).max(1000),

	...usingUserIdSchema,
}).required()

export const createReflinkSchema = {
	additionalCost: Joi.number().max(200000).required(),
	businessTurnoverLevel: Joi.number().min(1).max(4).required(),
	trainingDays: Joi.number().min(1).max(10).required(),
	enterprise: Joi.boolean().required(),
	agreementStartDate: Joi.date().required(),
	note: Joi.string().max(1000).optional(),
}

export const createReflinkRequestSchema = Joi.object({
	...createReflinkSchema,
	...usingUserIdSchema,
}).required()

export const updateReflinkRequestSchema = Joi.object({
	id: uuidIdSchema.required(),
	additionalCost: Joi.number().max(200000).optional(),
	businessTurnoverLevel: Joi.number().min(1).max(4).optional(),
	trainingDays: Joi.number().min(1).optional(),
	enterprise: Joi.boolean().optional(),
	agreementStartDate: Joi.date().optional(),
	note: Joi.string().optional(),
}).required()

export const deleteReflinksRequestSchema = Joi.object({
	ids: Joi.array().items(uuidIdSchema.required()).max(100).required(),
}).required()
