import CardItem from '../CardItem/CardItem'
import CardTitle from '../CardTitle/CardTitle'
import { Button, Layout, Tag, Tooltip } from 'antd'
import EmptyData from '../EmptyData/EmptyData'
import React from 'react'
import { GetLeadsGroupsResponse } from '@aiapp/types/leadsGroup'
import { formatDate } from '@aiapp/utils/dates'
import { useNavigate } from 'react-router-dom'
import { ContactsOutlined, SyncOutlined } from '@ant-design/icons'
import styles from './LeadsGroupList.module.scss'

const { Header } = Layout

type Props = {
	leadsGroups: GetLeadsGroupsResponse
	onLoadLeadsGroups: () => Promise<void>
	selectedLeadsGroupIds?: string[]
	selectLeadsGroup?: (id: string) => void
	onCreateNewLeadsGroup?: () => void
	deleteLeadsGroups?: (ids: string[]) => void
	onEditNewLeadsGroup?: (id: string) => void
	showOpenButton?: boolean
}

const LeadsGroupList = ({
	leadsGroups,
	onLoadLeadsGroups,
	selectedLeadsGroupIds,
	selectLeadsGroup,
	deleteLeadsGroups,
	onCreateNewLeadsGroup,
	onEditNewLeadsGroup,
	showOpenButton,
}: Props) => {
	const navigate = useNavigate()

	return (
		<div className={styles.container}>
			<Header className={styles.header}>
				<div className={styles.headerElements}>
					{!!onCreateNewLeadsGroup && (
						<Button icon={<ContactsOutlined />} onClick={onCreateNewLeadsGroup}>
							Create new group
						</Button>
					)}
				</div>

				<Tooltip trigger={['hover', 'focus']} title='Refresh'>
					<Button
						className={styles.headerRefresh}
						onClick={onLoadLeadsGroups}
						shape='circle'
						icon={<SyncOutlined />}
					/>
				</Tooltip>
			</Header>

			<div className={styles.groups}>
				{leadsGroups.map(({ group, count }) => (
					<CardItem
						key={group.id}
						itemId={group.id}
						selectedItems={selectedLeadsGroupIds}
						selectItem={selectLeadsGroup ? () => selectLeadsGroup(group.id) : undefined}
						cardTitle={
							<CardTitle
								id={group.id}
								name={group.name}
								onDelete={deleteLeadsGroups ? (id: string) => deleteLeadsGroups([id]) : undefined}
							/>
						}
						labels={[
							{
								label: 'Leads',
								value: <Tag>{count}</Tag>,
							},
							{
								label: 'Description',
								value: group.description && group.description,
							},
							{
								label: 'Created date',
								value: formatDate(group.createdAt),
							},
						]}
					>
						{!!selectLeadsGroup && (
							<Button
								className={selectedLeadsGroupIds?.includes(group.id) ? styles.selectedButton : ''}
								onClick={() => selectLeadsGroup(group.id)}
							>
								{selectedLeadsGroupIds?.includes(group.id) ? 'Selected' : 'Select'}
							</Button>
						)}
						{showOpenButton && (
							<Button onClick={() => navigate(`/leads?groupId=${group.id}`)}>Open</Button>
						)}
						{!!onEditNewLeadsGroup && (
							<Button onClick={() => onEditNewLeadsGroup(group.id)}>Edit</Button>
						)}
					</CardItem>
				))}

				{!leadsGroups.length && (
					<EmptyData onClick={onCreateNewLeadsGroup} description='Empty lleads group list' />
				)}
			</div>
		</div>
	)
}

export default LeadsGroupList
