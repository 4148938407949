import { Statistic } from 'antd'
import React from 'react'
import { PublicAnalytics } from '@aiapp/types/analytics'
import { getCostValue } from '@aiapp/utils'
import styles from './UsageStatistics.module.scss'

type Props = {
	analytics: PublicAnalytics | undefined
	loading: boolean
}

const UsageStatistics = ({ analytics, loading }: Props) => {
	return (
		<div className={styles.statistics}>
			<Statistic
				title='Lifetime cost'
				value={getCostValue(analytics?.lifetimeCost ?? 0, false)}
				loading={loading}
			/>
			<Statistic
				title='Total Personas response time'
				value={analytics?.totalExecutionHours}
				suffix='hours'
				prefix='~'
				precision={2}
				loading={loading}
			/>
			<Statistic
				title='Human working hours'
				value={analytics?.totalMenHours}
				suffix='hours'
				prefix='~'
				loading={loading}
			/>
		</div>
	)
}

export default UsageStatistics
