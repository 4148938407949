import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { App as AppWrapper } from 'antd'
import { AiContextProvider } from '../../hooks/context/AiContext'
import { ChatContextProvider } from '../../hooks/context/ChatContext'
import { AssistantRoleContextProvider } from '../../hooks/context/AssistantRoleContext'
import { AccountContextProvider } from '../../hooks/context/AccountContext'
import { GlobalContextProvider } from '../../hooks/context/GlobalContext'
import { EditorContextProvider } from '../../hooks/context/EditorContext'
import { GoogleOAuthProvider } from '@react-oauth/google'
import env from '../../boot/env'
import styles from './App.module.scss'

import Login from '../Login/Login'
import Signup from '../Signup/Signup'
import AiContext from '../AiContext/AiContext'
import SessionManager from '../../global/SessionManager'
import Dashboard from '../Dashboard/Dashboard'
import AuthRoute from '../../global/AuthRoute'
import AiContexts from '../AiContexts/AiContexts'
import DashboardLayout from '../Layout/DashboardLayout'
import AiChats from '../AiChats/AiChats'
import AiChat from '../AiChat/AiChat'
import SocketManager from '../../global/SocketManager'
import PageNotFound from '../PageNotFound/PageNotFound'
import Conversations from '../Personas/Conversations/Conversations'
import Integrations from '../Personas/Integrations/Integrations'
import Integration from '../Personas/Integration/Integration'
import Conversation from '../Personas/Conversation/Conversation'
import PrivacyPolicy from '../PrivacyPolicy/PrivacyPolicy'
import AboutUs from '../AboutUs/AboutUs'
import PersonaRoles from '../Personas/Roles/Roles'
import Role from '../Personas/Roles/Role/Role'
import Personas from '../Personas/Personas'
import Persona from '../Personas/Persona/Persona'
import Leads from '../LeadsGroups/Leads/Leads'
import LeadsGroups from '../LeadsGroups/LeadsGroups'
import AdminUsers from '../Admin/Users/Users'
import BrokerUsers from '../Broker/Users/Users'
import SimpleLayout from '../SimpleLayout/SimpleLayout'
import BrokerReflinks from '../Broker/Reflinks/Reflinks'
import Onboarding from '../Onboarding/Onboarding'
import UserOffers from '../UserOffers/UserOffers'
import BillingOverview from '../Billing/Overview/Overview'
import BillingPaymentMethods from '../Billing/PaymentMethods/PaymentMethods'
import BillingPaymentHistory from '../Billing/PaymentHistory/PaymentHistory'
import CompanyDetails from '../Billing/CompanyDetails/CompanyDetails'

function App() {
	return (
		<AppWrapper className={styles.container}>
			<AccountContextProvider>
				<SessionManager />
				<SocketManager />

				<GlobalContextProvider>
					<Routes>
						<Route
							path='/'
							element={
								<AuthRoute>
									<DashboardLayout>
										<Dashboard />
									</DashboardLayout>
								</AuthRoute>
							}
						/>

						<Route
							path='/contexts'
							element={
								<AuthRoute>
									<AiContextProvider>
										<DashboardLayout>
											<AiContexts />
										</DashboardLayout>
									</AiContextProvider>
								</AuthRoute>
							}
						/>
						<Route
							path='/context'
							element={
								<AuthRoute>
									<AiContextProvider>
										<DashboardLayout>
											<AiContext />
										</DashboardLayout>
									</AiContextProvider>
								</AuthRoute>
							}
						/>

						<Route
							path='/chats'
							element={
								<AuthRoute>
									<DashboardLayout>
										<AiChats />
									</DashboardLayout>
								</AuthRoute>
							}
						/>
						<Route
							path='/chat'
							element={
								<AuthRoute>
									<DashboardLayout>
										<ChatContextProvider>
											<EditorContextProvider>
												<AiChat />
											</EditorContextProvider>
										</ChatContextProvider>
									</DashboardLayout>
								</AuthRoute>
							}
						/>

						<Route
							path='/leads/groups'
							element={
								<AuthRoute>
									<DashboardLayout>
										<LeadsGroups />
									</DashboardLayout>
								</AuthRoute>
							}
						/>
						<Route
							path='/leads'
							element={
								<AuthRoute>
									<DashboardLayout>
										<Leads />
									</DashboardLayout>
								</AuthRoute>
							}
						/>

						<Route
							path='/personas'
							element={
								<AuthRoute>
									<DashboardLayout>
										<Personas />
									</DashboardLayout>
								</AuthRoute>
							}
						/>
						<Route
							path='/persona'
							element={
								<AuthRoute>
									<DashboardLayout>
										<Persona />
									</DashboardLayout>
								</AuthRoute>
							}
						>
							<Route path=':assistantId' element={<Persona />} />
						</Route>
						<Route
							path='/persona/:assistantId/roles'
							element={
								<AuthRoute>
									<DashboardLayout>
										<PersonaRoles />
									</DashboardLayout>
								</AuthRoute>
							}
						/>
						<Route
							path='/persona/:assistantId/role'
							element={
								<AuthRoute>
									<DashboardLayout>
										<AssistantRoleContextProvider>
											<ChatContextProvider>
												<EditorContextProvider>
													<Role />
												</EditorContextProvider>
											</ChatContextProvider>
										</AssistantRoleContextProvider>
									</DashboardLayout>
								</AuthRoute>
							}
						>
							<Route path=':assistantRoleId' element={<Persona />}>
								<Route path='context' element={<Role />} />
								<Route path='playground' element={<Role />} />
								<Route path='connections' element={<Role />} />
							</Route>
						</Route>

						<Route
							path='/personas/integrations'
							element={
								<AuthRoute>
									<DashboardLayout>
										<Integrations />
									</DashboardLayout>
								</AuthRoute>
							}
						/>
						<Route
							path='/personas/integration'
							element={
								<AuthRoute>
									<DashboardLayout>
										<GoogleOAuthProvider clientId={env.GMAIL_CLIENT_ID}>
											<Integration />
										</GoogleOAuthProvider>
									</DashboardLayout>
								</AuthRoute>
							}
						/>

						<Route
							path='/personas/conversations'
							element={
								<AuthRoute>
									<DashboardLayout>
										<Conversations />
									</DashboardLayout>
								</AuthRoute>
							}
						/>
						<Route
							path='/personas/conversation'
							element={
								<AuthRoute>
									<DashboardLayout>
										<ChatContextProvider>
											<EditorContextProvider>
												<Conversation />
											</EditorContextProvider>
										</ChatContextProvider>
									</DashboardLayout>
								</AuthRoute>
							}
						/>

						<Route
							path='/billing/overview'
							element={
								<AuthRoute>
									<DashboardLayout>
										<BillingOverview />
									</DashboardLayout>
								</AuthRoute>
							}
						/>
						<Route
							path='/billing/payment-methods'
							element={
								<AuthRoute>
									<DashboardLayout>
										<BillingPaymentMethods />
									</DashboardLayout>
								</AuthRoute>
							}
						/>
						<Route
							path='/billing/history'
							element={
								<AuthRoute>
									<DashboardLayout>
										<BillingPaymentHistory />
									</DashboardLayout>
								</AuthRoute>
							}
						/>
						<Route
							path='/billing/company'
							element={
								<AuthRoute>
									<DashboardLayout>
										<CompanyDetails />
									</DashboardLayout>
								</AuthRoute>
							}
						/>

						<Route
							path='/broker/users'
							element={
								<AuthRoute roles={['broker']}>
									<DashboardLayout>
										<BrokerUsers />
									</DashboardLayout>
								</AuthRoute>
							}
						/>
						<Route
							path='/broker/reflinks'
							element={
								<AuthRoute roles={['broker']}>
									<DashboardLayout>
										<BrokerReflinks />
									</DashboardLayout>
								</AuthRoute>
							}
						/>

						<Route
							path='/offers'
							element={
								<AuthRoute roles={['broker', 'admin']}>
									<DashboardLayout>
										<UserOffers />
									</DashboardLayout>
								</AuthRoute>
							}
						/>

						<Route
							path='/login'
							element={
								<SimpleLayout>
									<Login />
								</SimpleLayout>
							}
						/>
						<Route
							path='/signup'
							element={
								<SimpleLayout>
									<Signup />
								</SimpleLayout>
							}
						/>
						<Route
							path='/onboarding'
							element={
								<AuthRoute>
									<SimpleLayout>
										<Onboarding />
									</SimpleLayout>
								</AuthRoute>
							}
						/>
						<Route path='/privacy-policy' element={<PrivacyPolicy />} />
						<Route path='/about-us' element={<AboutUs />} />

						<Route
							path='/admin/users'
							element={
								<AuthRoute roles={['admin']}>
									<DashboardLayout>
										<AdminUsers />
									</DashboardLayout>
								</AuthRoute>
							}
						/>
						<Route
							path='/admin/mails'
							element={
								<AuthRoute roles={['admin']}>
									<DashboardLayout>
										<></>
									</DashboardLayout>
								</AuthRoute>
							}
						/>

						<Route
							path='*'
							element={
								<AuthRoute>
									<DashboardLayout>
										<PageNotFound />
									</DashboardLayout>
								</AuthRoute>
							}
						/>
					</Routes>
				</GlobalContextProvider>
			</AccountContextProvider>
		</AppWrapper>
	)
}

export default App
