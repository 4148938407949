import React, { ForwardedRef, forwardRef, ReactNode, useCallback, useImperativeHandle } from 'react'
import { Accept, useDropzone } from 'react-dropzone'
import { MB_IN_BYTES } from '@aiapp/utils/consts'
import { contentTypeToExtension } from '@aiapp/utils'
import styles from './UploadFileWrapper.module.scss'
import { App } from 'antd'

type Props = {
	onUpload: (files: File[]) => void
	maxFiles?: number
	acceptTypes?: Accept
	children?: ReactNode
	noClick?: boolean
	dropFileText?: string
	className?: string
	noStyle?: boolean
}

export type UploadFileRef = {
	openFileModal: () => void
	isDragActive: boolean
}

const UploadFileWrapper = (
	{
		children,
		onUpload,
		maxFiles = 10,
		dropFileText,
		noClick,
		noStyle,
		className = '',
		acceptTypes = contentTypeToExtension,
	}: Props,
	ref: ForwardedRef<UploadFileRef>,
) => {
	const { message } = App.useApp()

	const onDrop = useCallback((acceptedFiles: File[]) => {
		if (!acceptedFiles.length) {
			message.open({
				type: 'info',
				content: 'Not supported file type',
			})
			return
		}
		onUpload(acceptedFiles)
	}, [])

	const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
		onDrop,
		noClick,
		maxFiles: maxFiles,
		maxSize: MB_IN_BYTES * 10,
		accept: acceptTypes,
	})

	useImperativeHandle(ref, () => ({
		openFileModal: open,
		isDragActive,
	}))

	return (
		<div {...getRootProps({ className: `${noStyle ? '' : styles.dropzone} ${className}` })}>
			<input {...getInputProps()} />
			{isDragActive && (
				<>
					<div className={styles.active} />
					{dropFileText && <div className={styles.dropFile}>{dropFileText}</div>}
				</>
			)}

			{children}
		</div>
	)
}

export default forwardRef(UploadFileWrapper)
