import { App, PaginationProps, Table, type TableProps } from 'antd'
import React, { useEffect, useState } from 'react'
import { Reflink, ReflinksFilter } from '@aiapp/types/reflink'
import ReflinkForm from '../ReflinkForm/ReflinkForm'
import { ReflinkFormData } from '../Reflinks'
import env from '../../../../boot/env'
import styles from './BrokerReflinksTable.module.scss'

type Props = {
	reflinks: Reflink[]
	filters: ReflinksFilter
	total: number
	selectedReflinkIds: string[]
	showingUsed: boolean
	setPagination: (newPage?: number, newPerPage?: number) => void
	setSelectedReflinks: (reflinkIds: string[]) => void
	saveReflinkFormData: (formData: ReflinkFormData) => Promise<void>
}

const BrokerReflinksTable = ({
	reflinks,
	filters,
	total,
	showingUsed,
	selectedReflinkIds,
	setPagination,
	setSelectedReflinks,
	saveReflinkFormData,
}: Props) => {
	const { message } = App.useApp()

	const [windowHeight, setWindowHeight] = useState(window.innerHeight)

	useEffect(() => {
		const handleResize = () => {
			setWindowHeight(window.innerHeight)
		}

		window.addEventListener('resize', handleResize)
		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	const rowSelection = {
		onChange: (selectedRowKeys: React.Key[]) => {
			setSelectedReflinks(selectedRowKeys as string[])
		},
	}

	const copyText = async (text: string, messageContent: string) => {
		await navigator.clipboard.writeText(text)

		message.open({
			type: 'success',
			content: messageContent,
		})
	}

	const columns: TableProps<ReflinkTableData>['columns'] = [
		{
			title: 'Used',
			dataIndex: 'used',
			key: 'used',
			className: `${styles.tableValue} ${styles.boldValue}`,
			width: 55,
			render: (value) => (value ? 'Yes' : 'No'),
		},
		{
			title: 'Business Turnover',
			dataIndex: 'businessTurnoverLevel',
			key: 'businessTurnoverLevel',
			className: styles.tableValue,
			width: 80,
			render: (value) => {
				switch (value) {
					case 1:
						return '0-1 mln'
					case 2:
						return '1-4 mln'
					case 3:
						return '4-10 mln'
					case 4:
						return '10+ mln'
					default:
						return ''
				}
			},
		},
		{
			title: 'Training Days',
			dataIndex: 'trainingDays',
			key: 'trainingDays',
			className: styles.tableValue,
			width: 75,
		},
		{
			title: 'Enterprise',
			dataIndex: 'enterprise',
			key: 'enterprise',
			className: styles.tableValue,
			width: 75,
			render: (value) => (value ? 'Yes' : 'No'),
		},
		{
			title: 'Base training cost',
			dataIndex: 'baseCost',
			key: 'baseCost',
			className: styles.tableValue,
			width: 75,
			render: (value) => `${(value / 100).toFixed(2)} PLN`,
		},
		{
			title: 'Additional cost',
			dataIndex: 'additionalCost',
			key: 'additionalCost',
			className: styles.tableValue,
			width: 75,
			render: (value) => `${(value / 100).toFixed(2)} PLN`,
		},
		{
			title: 'Total training cost',
			dataIndex: 'totalCost',
			key: 'totalCost',
			className: `${styles.tableValue} ${styles.boldValue}`,
			width: 75,
			render: (value) => `${(value / 100).toFixed(2)} PLN`,
		},
		{
			title: 'Link',
			dataIndex: 'link',
			key: 'link',
			className: `${styles.tableValue} ${styles.boldValue}`,
			width: 120,
			ellipsis: true,
			render: (value) => (
				<span
					className={styles.clickableColoredText}
					onClick={() => copyText(value, 'Reflink copied!')}
				>
					{value}
				</span>
			),
		},
	]

	if (!showingUsed) {
		columns.splice(columns.length - 1, 0, Table.EXPAND_COLUMN)
	}

	return (
		<Table
			rowSelection={{
				type: 'checkbox',
				...rowSelection,
				selectedRowKeys: selectedReflinkIds,
				selections: !showingUsed ? [Table.SELECTION_ALL, Table.SELECTION_NONE] : false,
			}}
			expandable={{
				expandedRowRender: !showingUsed
					? (item: any) => (
							<ReflinkForm
								saveReflinkFormData={saveReflinkFormData}
								reflinkData={reflinks.find((reflink) => reflink.id === item.key)}
							/>
						)
					: undefined,
			}}
			pagination={{
				pageSize: filters.perPage,
				total,
				current: filters.page,
				onChange: (page: number, pageSize: number) => setPagination(page, pageSize),
				showTotal,
			}}
			columns={columns}
			dataSource={reflinksToTableData(reflinks)}
			scroll={{ y: windowHeight - 366 }}
		/>
	)
}

const showTotal: PaginationProps['showTotal'] = (total) => `Total ${total} items`

const reflinksToTableData = (reflinks: Reflink[]): Array<ReflinkTableData & { key: string }> => {
	return reflinks.map((reflink) => ({
		key: reflink.id,
		businessTurnoverLevel: reflink.businessTurnoverLevel,
		trainingDays: reflink.trainingDays,
		enterprise: reflink.enterprise,
		baseCost: reflink.baseCost,
		additionalCost: reflink.additionalCost,
		totalCost: reflink.baseCost + reflink.additionalCost,
		used: reflink.used,
		note: reflink.note,
		link: `${env.domain}/signup?ref=${reflink.code}`,
	}))
}

interface ReflinkTableData {
	businessTurnoverLevel: number
	trainingDays: number
	enterprise: boolean
	baseCost: number
	additionalCost: number
	totalCost: number
	used: boolean
	link: string
	note?: string
}

export default BrokerReflinksTable
