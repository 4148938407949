export const ASSISTANT_ROLE_STEPS = ['Form', 'Knowledge base', 'Playground', 'Connections'] as const
export type AssistantRoleStep = (typeof ASSISTANT_ROLE_STEPS)[number]

export const marks: Record<number, AssistantRoleStep> = {
	0: 'Form',
	33: 'Knowledge base',
	66: 'Playground',
	100: 'Connections',
}

export const stepNameToProgress: Record<AssistantRoleStep, number> = {
	Form: 0,
	'Knowledge base': 33,
	Playground: 66,
	Connections: 100,
}

export const warmupLevelToGradient: Record<number, string> = {
	1: '#3366FF',
	2: '#3366FF, #6699FF',
	3: '#3366FF, #6699FF, #FF9966',
	4: '#3366FF, #6699FF, #FF9966, #FF6600',
}

export const warmupLevelMarks: Record<number, string> = {
	1: '1',
	2: '2',
	3: '3',
	4: '4',
}

export const warmupLevelToLabel: Record<number, string> = {
	1: 'Moving to sales as quickly as possible',
	2: 'Understanding the client basic needs and moving on to sales',
	3: 'Extensive knowledge of the customer, his needs and problems, and ultimately sales',
	4: 'Conversation focused on a long dialogue with the client and getting to know all his needs.',
}

export const prepareRoleFormValues = (value: Record<string, any>, values: any) => {
	if ('followupInterval' in value) {
		if (value.followupInterval === true) {
			values.followupInterval = 5
			values.maxFollowups = 2
		}
		if (value.followupInterval === false) {
			values.followupInterval = null
			values.maxFollowups = null
		}
	}

	if (!values.redirectConditions?.length) {
		values.redirectConditions = null
	}
}
