import { FileData } from '../../../../../../../types/ChatContext'
import React, { useMemo } from 'react'
import styles from './VideoContent.module.scss'

type Props = {
	fileData: FileData
	scrollToBottom: () => void
}

const VideoContent = ({ fileData, scrollToBottom }: Props) =>
	useMemo(() => {
		return (
			<div className={styles.videoFile}>
				<video onLoadedData={scrollToBottom} className={styles.video} controls>
					<source src={fileData.url} type='video/mp4' />
				</video>
			</div>
		)
	}, [fileData.url])

export default VideoContent
