import {
	MenuFoldOutlined,
	MenuUnfoldOutlined,
	QuestionCircleOutlined,
	SettingOutlined,
} from '@ant-design/icons'
import React, { MutableRefObject, useMemo } from 'react'
import { Button } from 'antd'
import { useChatContext } from '../../../hooks/context/ChatContext'
import styles from './ChatHeader.module.scss'
import { useGlobalContext } from '../../../hooks/context/GlobalContext'

type Props = {
	chatHeaderRef: MutableRefObject<any>
	onOpenEditChatModal?: () => void
}

const ChatHeader = ({ onOpenEditChatModal, chatHeaderRef }: Props) => {
	const globalContext = useGlobalContext()
	const chatContext = useChatContext()

	const chat = chatContext.useSubscribe((context) => context.chat)
	const sessionsCollapsed = chatContext.useSubscribe((context) => context.sessionsCollapsed)

	const showChatTour = globalContext.useSubscribe((context) => context.showChatTour)

	const startChatTour = () => {
		if (showChatTour) {
			return
		}
		globalContext.setShowChatTour(true)
	}

	return useMemo(
		() => (
			<div className={styles.headerContainer}>
				<div className={styles.header} ref={chatHeaderRef}>
					{!chat?.isAssistant ? (
						<Button
							className={`${styles.headerButton} ${styles.transparency}`}
							type='text'
							onClick={startChatTour}
						>
							<QuestionCircleOutlined className={styles.headerIcon} />
						</Button>
					) : (
						<div />
					)}

					<div className={styles.centerSection}>
						<div className={styles.chatName}>{chat?.name ?? 'Chat'}</div>

						{!!onOpenEditChatModal && (
							<Button
								type='text'
								disabled={chat?.status !== 'active'}
								icon={<SettingOutlined className={styles.editChatIcon} />}
								onClick={() => !showChatTour && onOpenEditChatModal()}
								className={styles.chatSettingsButton}
							/>
						)}
					</div>

					<div>
						<Button
							type='text'
							icon={
								sessionsCollapsed ? (
									<MenuFoldOutlined className={styles.headerIcon} />
								) : (
									<MenuUnfoldOutlined className={styles.headerIcon} />
								)
							}
							onClick={() =>
								!showChatTour &&
								chatContext.setChatContext({ sessionsCollapsed: !sessionsCollapsed })
							}
							className={styles.headerButton}
						/>
					</div>
				</div>
			</div>
		),
		[chat, sessionsCollapsed, showChatTour],
	)
}

export default ChatHeader
