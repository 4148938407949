import { Form, Slider } from 'antd'
import { warmupLevelMarks, warmupLevelToGradient, warmupLevelToLabel } from '../../utils'
import styles from './Forms.module.scss'

type Props = {
	warmupLevel: number | undefined
}

const WarmupLevel = ({ warmupLevel }: Props) => {
	return (
		<Form.Item
			tooltip='
An option that regulates how long the Persona should get to know the customer and their needs before moving on to a specific offer.'
			className={styles.formItem}
			rules={[{ required: true, message: 'Please set warm-up level' }]}
			label='Client warm-up level'
			name='warmupLevel'
		>
			<Slider
				min={1}
				max={4}
				marks={warmupLevelMarks}
				tooltip={{
					formatter: (value) => (value ? warmupLevelToLabel[value] : warmupLevelToLabel[1]),
				}}
				styles={{
					track: {
						background: 'transparent',
					},
					tracks: {
						background: `linear-gradient(to right, ${warmupLevelToGradient[warmupLevel ?? 1]})`,
					},
				}}
			/>
		</Form.Item>
	)
}

export default WarmupLevel
