import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import React from 'react'
import styles from './Loading.module.scss'

type LoadingProps = {
	size?: number
	color?: string
	className?: string
	iconClassName?: string
}

type InlineProps = {
	size?: number
	color?: string
	iconClassName?: string
}

const Loading = ({ size = 32, color, className = '', iconClassName = '' }: LoadingProps) => {
	return (
		<div className={`${styles.loading} ${className}`}>
			<InlineLoading size={size} color={color} iconClassName={iconClassName} />
		</div>
	)
}

export const PulseLoading = ({
	size = 32,
	color,
	className = '',
	iconClassName = '',
}: LoadingProps) => {
	return (
		<div className={`${styles.loading} ${className}`}>
			<InlinePulseLoading size={size} color={color} iconClassName={iconClassName} />
		</div>
	)
}

export const InlinePulseLoading = ({
	size = 32,
	iconClassName = '',
	color = '#1677ff',
}: InlineProps) => {
	return (
		<div
			style={{ width: size, height: size, minWidth: size, minHeight: size, backgroundColor: color }}
			className={`${styles.pulseLoading} ${iconClassName}`}
		/>
	)
}

export const InlineLoading = ({
	size = 32,
	iconClassName = '',
	color = '#1677ff',
}: InlineProps) => {
	return (
		<Spin
			indicator={
				<LoadingOutlined style={{ fontSize: size, color }} className={iconClassName} spin />
			}
		/>
	)
}

export default Loading
