import Joi from 'joi'
import { emailSchema, usingUserIdSchema, uuidIdSchema } from '../../global'
import { assistantRoleBaseSchema } from '../assistantRole'

const internalAssistantRoleSchema = {
	...assistantRoleBaseSchema,
	type: Joi.string().valid('internalAssistant').required(),

	companyDetails: Joi.string().max(3000),
	internalPhoneNumbers: Joi.array().items(Joi.string().trim().required()).min(1).max(50),
	internalEmails: Joi.array().items(emailSchema.trim().required()).min(1).max(50),
}

export const createInternalAssistantRoleSchema = {
	assistantId: internalAssistantRoleSchema.assistantId.required(),
	name: internalAssistantRoleSchema.name.required(),
	additionalInformation: internalAssistantRoleSchema.additionalInformation.optional(),
	naturalizationBooster: internalAssistantRoleSchema.naturalizationBooster.optional(),
	redirectConditions: internalAssistantRoleSchema.redirectConditions.optional(),

	type: internalAssistantRoleSchema.type.required(),
	disabledEmoji: internalAssistantRoleSchema.disabledEmoji.required(),
	preferredEmoji: internalAssistantRoleSchema.preferredEmoji.optional(),
	followupInterval: internalAssistantRoleSchema.followupInterval.optional(),
	maxFollowups: internalAssistantRoleSchema.maxFollowups.optional(),
	conversationStyle: internalAssistantRoleSchema.conversationStyle.required(),
	responseLength: internalAssistantRoleSchema.responseLength.required(),
	companyDetails: internalAssistantRoleSchema.companyDetails.optional(),
	internalPhoneNumbers: internalAssistantRoleSchema.internalPhoneNumbers.required(),
	internalEmails: internalAssistantRoleSchema.internalEmails.required(),
	...usingUserIdSchema,
}

export const updateInternalAssistantRoleSchema = {
	id: uuidIdSchema.required(),
	assistantId: internalAssistantRoleSchema.assistantId.required(),
	name: internalAssistantRoleSchema.name.optional(),
	additionalInformation: internalAssistantRoleSchema.additionalInformation.optional(),
	naturalizationBooster: internalAssistantRoleSchema.naturalizationBooster.optional(),
	redirectConditions: internalAssistantRoleSchema.redirectConditions.optional(),

	type: internalAssistantRoleSchema.type.optional(),
	disabledEmoji: internalAssistantRoleSchema.disabledEmoji.optional(),
	preferredEmoji: internalAssistantRoleSchema.preferredEmoji.optional(),
	followupInterval: internalAssistantRoleSchema.followupInterval.optional(),
	maxFollowups: internalAssistantRoleSchema.maxFollowups.optional(),
	conversationStyle: internalAssistantRoleSchema.conversationStyle.optional(),
	responseLength: internalAssistantRoleSchema.responseLength.optional(),
	companyDetails: internalAssistantRoleSchema.companyDetails.optional(),
	internalPhoneNumbers: internalAssistantRoleSchema.internalPhoneNumbers.optional(),
	internalEmails: internalAssistantRoleSchema.internalEmails.optional(),
}
