// import { parseEnv } from '@aiapp/utils'
//
// const getEnv = (envName: string): string => {
// 	const processEnv = process.env
// 	return (
// 		// eslint-disable-next-line no-underscore-dangle
// 		((typeof window !== 'undefined' ? window : {}) as any).__ENVS__?.[`__${envName}__`] ||
// 		processEnv[envName] ||
// 		processEnv[`REACT_APP_${envName}`] ||
// 		undefined
// 	)
// }

const env = {
	version: '0.7.5',
	environment: 'staging',
	domain: 'staging.quantumneuron.eu',

	META_APP_ID: '1861091531072362',
	WHATSAPP_CONFIG_ID: '1897260674067934',

	GMAIL_CLIENT_ID: '700161868029-3jjvs6br9tifetc90lpcjs49t5tmcokv.apps.googleusercontent.com',
}

export default env
