import React from 'react'
import { Table, Button, Dropdown, Tag, type TableProps } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import { formatDate } from '@aiapp/utils/dates'
import styles from './UserTableColumns.module.scss'
import { AccountTableData } from '../../UsersTable'
import AccountRoleTag from '../AccountRoleTag/AccountRoleTag'
import UserTableOptions from '../UserTableOptions/UserTableOptions'

type Props = {
	previewAccount: (id: string) => void
	viewUserOffer: (id: string) => void
	blockAccount: (id: string) => void
	deleteAccount: (id: string) => void
	isAdmin: boolean
	currentUserId?: string
	copyText: (text: string, messageContent: string) => void
}

const UserTableColumns = ({
	previewAccount,
	viewUserOffer,
	blockAccount,
	deleteAccount,
	isAdmin,
	currentUserId,
	copyText,
}: Props) => {
	const columns: TableProps<AccountTableData>['columns'] = [
		{
			title: 'ID',
			dataIndex: 'key',
			key: 'key',
			className: styles.tableValue,
			ellipsis: true,
			width: 100,
			render: (value) => (
				<span
					className={styles.clickableColoredText}
					onClick={() => copyText(value, 'User ID copied!')}
				>
					{value}
				</span>
			),
		},
		{
			title: 'E-mail',
			dataIndex: 'email',
			key: 'email',
			className: `${styles.tableValue} ${styles.boldValue}`,
			ellipsis: true,
			width: 140,
			render: (value) => (
				<span
					className={styles.clickableText}
					onClick={() => copyText(value, 'User e-mail copied!')}
				>
					{value}
				</span>
			),
		},
		{
			title: 'Status',
			dataIndex: 'active',
			className: styles.tableValue,
			key: 'active',
			width: 75,
			render: (value) =>
				value ? <Tag color='#5cb85c'>Active</Tag> : <Tag color='#d9534f'>Inactive</Tag>,
		},
		{
			title: 'Roles',
			dataIndex: 'roles',
			className: styles.tableValue,
			key: 'roles',
			width: 75,
			render: (roles: string[]) =>
				roles.map((role, index) => <AccountRoleTag key={`${index}-${role}`} role={role} />),
		},
		{
			title: 'Broker',
			dataIndex: 'brokerEmail',
			key: 'brokerEmail',
			className: styles.tableValue,
			ellipsis: true,
			render: (value) => (
				<span
					className={styles.clickableColoredText}
					onClick={() => copyText(value, 'Broker e-mail copied!')}
				>
					{value}
				</span>
			),
			width: 100,
		},
		{
			title: 'Note',
			dataIndex: 'hasNote',
			key: 'hasNote',
			className: styles.tableValue,
			render: (hasNote: boolean) =>
				hasNote ? <span className={styles.boldValue}>Yes</span> : 'No',
			width: 55,
		},
		{
			title: 'Blocked',
			dataIndex: 'blocked',
			className: styles.tableValue,
			key: 'blocked',
			width: 75,
			render: (value) => (value ? <span className={styles.dangerValue}>Yes</span> : 'No'),
		},
		{
			title: 'Created date',
			dataIndex: 'createdAt',
			key: 'createdAt',
			className: styles.tableValue,
			render: (date) => formatDate(date),
			width: 100,
		},
		Table.EXPAND_COLUMN,
		{
			title: 'Options',
			key: 'options',
			className: styles.tableValue,
			fixed: 'right',
			render: (record: AccountTableData & { key: string }) => (
				<Dropdown
					menu={UserTableOptions({
						record,
						previewAccount,
						viewUserOffer,
						blockAccount,
						deleteAccount,
						isAdmin,
						currentUserId,
					})}
					trigger={['click']}
				>
					<Button type='link' icon={<DownOutlined />}>
						Options
					</Button>
				</Dropdown>
			),
			width: 90,
		},
	]

	return columns
}

export default UserTableColumns
