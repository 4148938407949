import Joi from 'joi'
import { usingUserIdSchema, uuidIdSchema } from '../global'
import { INTEGRATION_PROVIDERS, MESSAGE_REACTIONS } from '../../types/integration'
import {
	CONVERSATION_MESSAGE_CATEGORIES,
	SALES_STAGES,
} from '../../types/assistant/conversationMessage'

const integrationConfigSchema = Joi.object({
	intelligentResponseTime: Joi.boolean().required(),
	intelligentWritingTime: Joi.boolean().required(),
	activeLabels: Joi.array().items(Joi.string().required()).allow(null).max(10).optional(),
	excludeMessageDescriptions: Joi.array()
		.items(Joi.string().min(6).max(500).required())
		.allow(null)
		.max(5)
		.optional(),
})

const integrationReactions = Joi.object({
	instagramCommentConversation: Joi.object({
		onlyMentions: Joi.boolean().optional(),
		startConversationMessages: Joi.array()
			.items(Joi.string().max(500).min(6).required())
			.max(5)
			.optional(),
	}),
})

export const getIntegrationRequestSchema = {
	id: uuidIdSchema.required(),
}

export const filterUserIntegrationsRequestSchema = {
	providers: Joi.array()
		.items(
			Joi.string()
				.valid(...INTEGRATION_PROVIDERS)
				.required(),
		)
		.optional(),
	...usingUserIdSchema,
}

export const getIntegrationsForAssistantRoleRequestSchema = {
	assistantRoleId: uuidIdSchema.required(),
	...usingUserIdSchema,
}

export const createIntegrationRequestSchema = {
	name: Joi.string().min(3).max(120).required(),
	provider: Joi.string()
		.valid(...INTEGRATION_PROVIDERS)
		.required(),

	reactions: integrationReactions.optional(),
	config: integrationConfigSchema.required(),

	accessCode: Joi.string().optional(),
	...usingUserIdSchema,
}

export const createPlaygroundIntegrationRequestSchema = {
	name: Joi.string().min(3).max(120).required(),
	userId: uuidIdSchema.required(),
}

export const updateIntegrationRequestSchema = {
	id: uuidIdSchema.required(),
	name: Joi.string().min(3).max(120).optional(),
	provider: Joi.string()
		.valid(...INTEGRATION_PROVIDERS)
		.optional(),

	reactions: integrationReactions.optional(),
	config: integrationConfigSchema.optional(),

	accessCode: Joi.string().optional(),
	removeCredentials: Joi.boolean().optional(),
}

export const deleteIntegrationRequestSchema = {
	id: uuidIdSchema.required(),
}

export const retryProviderMessageSendRequestSchema = {
	conversationMessageId: uuidIdSchema.required(),
}

export const publishProviderMessageRequestSchema = {
	conversationMessageId: uuidIdSchema.required(),
}

export const triggerConversationResponseRequestSchema = {
	conversationId: uuidIdSchema.required(),
}

export const onIntegrationActionSchema = {
	integrationId: uuidIdSchema.required(),
	action: Joi.alternatives()
		.try(
			Joi.object({
				type: Joi.string().valid('newUserMessage', 'newAssistantMessage').required(),
				userId: uuidIdSchema.required(),
				content: Joi.string().required(),
				subject: Joi.string().allow(null).optional(),
				conversationId: uuidIdSchema.allow(null).optional(),
				providerConversationId: Joi.string().allow(null).optional(),
				providerMessageId: Joi.string().optional(),
				userProviderId: Joi.string().optional(),
				assistantProviderId: Joi.string().optional(),
				assistantRoleId: uuidIdSchema.optional(),
				manual: Joi.boolean().optional(),
			}),
			Joi.object({
				type: Joi.string().valid('startCommentConversation').required(),
				comment: Joi.string().max(2000).required(),
				userProviderId: Joi.string().required(),
				assistantProviderId: Joi.string().required(),
				startConversationMessage: Joi.string().max(500).min(6).optional(),
			}),
			Joi.object({
				type: Joi.string().valid('startLeadConversationAction').required(),
				content: Joi.string().max(2000).min(10).required(),
				reminderContent: Joi.string().max(2000).min(10).required(),
				lead: Joi.object({
					id: uuidIdSchema.required(),
					email: Joi.string().optional(),
					phone: Joi.string().optional(),
					firstname: Joi.string().max(50).allow('').optional(),
					gender: Joi.string().valid('male', 'female').allow('').optional(),
					note: Joi.string().allow('').optional(),
				}).required(),
				publishAt: Joi.date().optional(),

				// email
				signature: Joi.string().max(20000).optional(),
				subject: Joi.string().max(200).min(3).optional(),
			}),
			Joi.object({
				type: Joi.string().valid('typingResponse').required(),
				conversationId: uuidIdSchema.required(),
				markSeen: Joi.boolean().optional(),
			}),
			Joi.object({
				type: Joi.string().valid('response').required(),
				userId: uuidIdSchema.required(),
				conversationId: uuidIdSchema.required(),
				remind: Joi.boolean().optional(),
				manual: Joi.boolean().optional(),
			}),
			Joi.object({
				type: Joi.string().valid('setResponseTime').required(),
				conversationId: uuidIdSchema.required(),
				responseTime: Joi.string().required(),
			}),
			Joi.object({
				type: Joi.string().valid('userMessageReaction').required(),
				conversationId: uuidIdSchema.required(),
				messageId: uuidIdSchema.required(),
				reaction: Joi.string()
					.valid(...MESSAGE_REACTIONS)
					.required(),
			}),
			Joi.object({
				type: Joi.string().valid('updateAssistantMessage').required(),
				userId: uuidIdSchema.required(),
				conversationId: uuidIdSchema.required(),
				messageId: uuidIdSchema.required(),
				content: Joi.string().required(),
				redirectReason: Joi.string().allow(null).optional(),
				summary: Joi.string().allow(null).optional(),
				category: Joi.string()
					.valid(...CONVERSATION_MESSAGE_CATEGORIES)
					.optional(),
				salesStage: Joi.string()
					.valid(...SALES_STAGES)
					.optional(),
				error: Joi.object().optional(), // TODO: Type me
				processing: Joi.boolean().optional(),
			}),
		)
		.required(),
}
