import { FileData } from '../../../../../../../types/ChatContext'
import React, { useMemo } from 'react'
import styles from './AudioContent.module.scss'

type Props = {
	fileData: FileData
	scrollToBottom: () => void
}

const AudioContent = ({ fileData, scrollToBottom }: Props) =>
	useMemo(() => {
		return (
			<div className={styles.audioFile}>
				<audio onLoadedData={scrollToBottom} className={styles.audio} controls src={fileData.url} />
			</div>
		)
	}, [fileData.url])

export default AudioContent
