import React, { useState } from 'react'
import { PublicMessageUsage, UsedTool } from '@aiapp/types/usage'
import useAsyncEffect from '../../../../../hooks/useAsyncEffect'
import query from '../../../../../utils/query'
import Loading from '../../../../../components/Loading/Loading'
import { toolIdToName } from '@aiapp/utils/toolsPrompts'
import { App, Collapse, CollapseProps } from 'antd'
import useTryCatch from '../../../../../hooks/useTryCatch'
import Line from '../../../../../components/Line/Line'
import { LLMModelName } from '@aiapp/types/global'
import { PublicMessage } from '../../../../../types/Chat'
import { useChatContext } from '../../../../../hooks/context/ChatContext'
import { formatDate } from '@aiapp/utils/dates'
import { useAccountContext } from '../../../../../hooks/context/AccountContext'
import { getCostValue } from '@aiapp/utils'
import styles from './MessageInfo.module.scss'

type Props = {
	publicMessage: PublicMessage
}

type MessageInfo = {
	usedTools: Array<
		UsedTool & {
			usedLLM?: LLMModelName
			usedTokens: number
			generatedCharacters: number
			translatedCharacters: number
		}
	>
	totalGeneratedCharacters: number
	totalTranslatedCharacters: number
	totalUsedTokens: number
	totalCost: number
	realTotalCost?: number
	totalExecuteTime: number
}

const MessageInfo = ({ publicMessage }: Props) => {
	const { message } = App.useApp()
	const tryCatch = useTryCatch(message)

	const accountContext = useAccountContext()
	const isAdmin = accountContext.useSubscribe((context) => context.roles.includes('admin'))
	const isOnboarding = accountContext.useSubscribe((context) =>
		context.roles.includes('onboarding'),
	)

	const chatContext = useChatContext()
	const isAssistant = chatContext.useSubscribe((context) => context.chat?.isAssistant)
	const provider = chatContext.useSubscribe((context) => context.currentChatSession?.provider)

	const [messageInfo, setMessageInfo] = useState<MessageInfo>()

	useAsyncEffect(async () => {
		await tryCatch(async () => {
			const resUsages = await query<PublicMessageUsage[]>('/usage/forMessage', 'GET', {
				params: isAssistant
					? {
							conversationMessageId: publicMessage.id,
						}
					: {
							chatMessageId: publicMessage.id,
						},
				withCredentials: true,
			})

			setMessageInfo(getMessageInfo(resUsages))
		})
	}, [])

	const getMessageInfo = (messageUsages: PublicMessageUsage[]): MessageInfo => {
		const messageInfo: MessageInfo = {
			usedTools: [],
			totalCost: 0,
			totalGeneratedCharacters: 0,
			totalTranslatedCharacters: 0,
			totalExecuteTime: 0,
			totalUsedTokens: 0,
		}

		if (isAdmin) {
			messageInfo.realTotalCost = 0
		}

		for (const messageUsage of messageUsages) {
			if (messageUsage.usedTool) {
				let usedLLM: LLMModelName | undefined = undefined
				let usedTokens = 0
				let generatedCharacters = 0
				let translatedCharacters = 0

				if (messageUsage.usedLLM) {
					usedLLM = messageUsage.usedLLM
				}
				if (messageUsage.embeddingTokens) {
					usedTokens += messageUsage.embeddingTokens
				}
				if (messageUsage.promptTokens) {
					usedTokens += messageUsage.promptTokens
				}
				if (messageUsage.completionTokens) {
					usedTokens += messageUsage.completionTokens
				}
				if (messageUsage.generatedCharactersCount > 0) {
					generatedCharacters = messageUsage.generatedCharactersCount
				}
				if (messageUsage.translatedCharactersCount > 0) {
					translatedCharacters = messageUsage.translatedCharactersCount
				}

				messageInfo.usedTools.push({
					...messageUsage.usedTool,
					usedLLM,
					usedTokens,
					generatedCharacters,
					translatedCharacters,
				})
				messageInfo.totalExecuteTime += +getExecuteTime(messageUsage.usedTool.executeTimeMs)
			}
			messageInfo.totalCost += messageUsage.totalCost

			if (isAdmin) {
				messageInfo.realTotalCost! += messageUsage.realTotalCost ?? 0
			}
		}

		for (const usedTools of messageInfo.usedTools) {
			messageInfo.totalUsedTokens += usedTools.usedTokens
			messageInfo.totalGeneratedCharacters += usedTools.generatedCharacters
			messageInfo.totalTranslatedCharacters += usedTools.translatedCharacters
		}

		return messageInfo
	}

	if (!messageInfo) {
		return (
			<div className={styles.loading}>
				<Loading size={24} />
			</div>
		)
	}

	return (
		<div className={styles.messageInfoContainer}>
			{isAssistant && (
				<>
					<div className={styles.heading}>Conversation message:</div>
					<Line className={styles.line} />

					<div className={styles.infoWrapper}>
						<div className={styles.infoName}>Created by Persona:&nbsp;</div>
						<div>{!!publicMessage.category || !!publicMessage.error ? 'Yes' : 'No'}</div>
					</div>

					<div className={styles.infoWrapper}>
						<div className={styles.infoName}>Provider:&nbsp;</div>
						<div>{provider}</div>
					</div>

					{(isAdmin || isOnboarding) && !!publicMessage.category && (
						<div className={styles.infoWrapper}>
							<div className={styles.infoName}>Category:&nbsp;</div>
							<div>{publicMessage.category}</div>
						</div>
					)}

					{(isAdmin || isOnboarding) && !!publicMessage.salesStage && (
						<div className={styles.infoWrapper}>
							<div className={styles.infoName}>Sales stage:&nbsp;</div>
							<div>{publicMessage.salesStage}</div>
						</div>
					)}

					{(isAdmin || isOnboarding) && !!publicMessage.error && (
						<div className={styles.infoWrapper}>
							<div className={styles.infoName}>Message error:&nbsp;</div>
							<div>{publicMessage.error.message}</div>
						</div>
					)}

					{publicMessage.sentByProvider !== undefined && (
						<div className={styles.infoWrapper}>
							<div className={styles.infoName}>Sent by provider:&nbsp;</div>
							<div>{publicMessage.sentByProvider ? 'Yes' : 'No'}</div>
						</div>
					)}

					{!!publicMessage.sentAt && (
						<div className={styles.infoWrapper}>
							<div className={styles.infoName}>Sent date:&nbsp;</div>
							<div>{formatDate(publicMessage.sentAt, true)}</div>
						</div>
					)}

					<div className={styles.infoWrapper}>
						<div className={styles.infoName}>Created date:&nbsp;</div>
						<div>{formatDate(publicMessage.createdAt, true)}</div>
					</div>

					<br />
				</>
			)}

			{!!messageInfo.totalExecuteTime && (
				<>
					<div className={styles.heading}>Usage summary:</div>
					<Line className={styles.line} />

					{messageInfo.totalUsedTokens > 0 && (
						<div className={styles.infoWrapper}>
							<div className={styles.infoName}>Used tokens:&nbsp;</div>
							<div>{messageInfo.totalUsedTokens}</div>
						</div>
					)}
					{messageInfo.totalGeneratedCharacters > 0 && (
						<div className={styles.infoWrapper}>
							<div className={styles.infoName}>Generated characters:&nbsp;</div>
							<div>{messageInfo.totalGeneratedCharacters}</div>
						</div>
					)}
					{messageInfo.totalTranslatedCharacters > 0 && (
						<div className={styles.infoWrapper}>
							<div className={styles.infoName}>Translated characters:&nbsp;</div>
							<div>{messageInfo.totalTranslatedCharacters}</div>
						</div>
					)}
					<div className={styles.infoWrapper}>
						<div className={styles.infoName}>Generating time:&nbsp;</div>
						<div>{messageInfo.totalExecuteTime.toFixed(0)}s</div>
					</div>
					<div className={styles.infoWrapper}>
						<div className={styles.infoName}>Message cost:&nbsp;</div>
						<div>{getCostValue(messageInfo.totalCost)}</div>
					</div>

					{isAdmin && (
						<div className={styles.infoWrapper}>
							<div className={styles.infoName}>Real message cost:&nbsp;</div>
							<div>{getCostValue(messageInfo.realTotalCost!)}</div>
						</div>
					)}
				</>
			)}

			{(isAdmin || isOnboarding) && !!messageInfo.usedTools.length && (
				<>
					<div className={styles.heading}>Used tools:</div>
					<Line className={styles.line} />

					<div className={styles.usedTools}>
						<Collapse items={getUsedTools(messageInfo)} />
					</div>
				</>
			)}
		</div>
	)
}

const getUsedTools = (messageInfo: MessageInfo): CollapseProps['items'] => {
	const reversedUsedTools = messageInfo.usedTools.reverse()
	return reversedUsedTools.map((usedTool, index) => ({
		id: `${index}`,
		label: toolIdToName[usedTool.toolId],
		children: (
			<div>
				{usedTool.usedLLM && (
					<div className={styles.infoWrapper}>
						<div className={styles.infoName}>Used LLM:&nbsp;</div>
						<div>{usedTool.usedLLM}</div>
					</div>
				)}
				{usedTool.usedTokens > 0 && (
					<div className={styles.infoWrapper}>
						<div className={styles.infoName}>Used tokens:&nbsp;</div>
						<div>{usedTool.usedTokens}</div>
					</div>
				)}
				{usedTool.generatedCharacters > 0 && (
					<div className={styles.infoWrapper}>
						<div className={styles.infoName}>Generated characters:&nbsp;</div>
						<div>{usedTool.generatedCharacters}</div>
					</div>
				)}
				{usedTool.translatedCharacters > 0 && (
					<div className={styles.infoWrapper}>
						<div className={styles.infoName}>Translated characters:&nbsp;</div>
						<div>{usedTool.translatedCharacters}</div>
					</div>
				)}
				<div className={styles.infoWrapper}>
					<div className={styles.infoName}>Execute time:&nbsp;</div>
					<div>{getExecuteTime(usedTool.executeTimeMs)}s</div>
				</div>
				<div className={styles.infoWrapper}>
					<div className={styles.infoName}>Cost:&nbsp;</div>
					<div>{getCostValue(usedTool.cost)}</div>
				</div>
			</div>
		),
	}))
}

const getExecuteTime = (executeTime: number): string => {
	return (executeTime / 1000).toFixed(0)
}

export default MessageInfo
