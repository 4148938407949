import { Form } from 'antd'
import { AssistantRole } from '@aiapp/types/assistant/assistantRole'
import { useEffect } from 'react'
import Joi from 'joi'
import { prepareAssistantData } from '../../Role'
import { useAssistantRoleContext } from '../../../../../../hooks/context/AssistantRoleContext'
import { formValidate } from '../../../../../../utils/validation'
import { createHelpdeskRoleSchema } from '@aiapp/schemas/assistant/roles/helpdesk'
import RoleHeader from '../../components/RoleHeader/RoleHeader'
import { useParams } from 'react-router-dom'
import { prepareRoleFormValues } from '../../utils'
import FormHeading from '../../components/FormHeading/FormHeading'
import SystemName from '../../components/Forms/SystemName'
import AdditionalInformation from '../../components/Forms/AdditionalInformation'
import EmojiConfig from '../../components/Forms/EmojiConfig'
import ConversationConfig from '../../components/Forms/ConversationConfig'
import FollowupsConfig from '../../components/Forms/FollowupsConfig'
import NaturalizationBooster from '../../components/Forms/NaturalizationBooster'
import RedirectConditions from '../../components/Forms/RedirectConditions'
import { roles } from '../../../../../../utils/roles'
import styles from './Helpdesk.module.scss'

const DEFAULT_HELPDESK_ROLE_DATA: Partial<AssistantRole> = {
	type: 'helpdesk',
	disabledEmoji: false,
	conversationStyle: 'balanced',
	responseLength: 'balanced',
	followupInterval: 5,
	maxFollowups: 2,
	naturalizationBooster: false,
}

const HelpdeskRoleForm = () => {
	const [form] = Form.useForm()

	const { assistantRoleId } = useParams()

	const assistantRoleContext = useAssistantRoleContext()
	const assistantRoleData = assistantRoleContext.useSubscribe((context) => context.data)

	useEffect(() => {
		if (!assistantRoleId) {
			assistantRoleContext.updateData(DEFAULT_HELPDESK_ROLE_DATA)
		}
	}, [assistantRoleId])

	useEffect(() => {
		checkFormValidate()
	}, [assistantRoleData])

	useEffect(() => {
		form.resetFields()
	}, [
		assistantRoleData.id,
		assistantRoleData.type,
		'followupInterval' in assistantRoleData && !!assistantRoleData.followupInterval,
	])

	const updateAssistantRoleData = (value: Record<string, any>, values: any) => {
		prepareRoleFormValues(value, values)
		assistantRoleContext.updateData(values)
	}

	const checkFormValidate = () => {
		const validate = formValidate(prepareAssistantData(assistantRoleData), assistantRoleSchema)
		assistantRoleContext.setValidStep(validate ? !Object.keys(validate).length : true)
	}

	const roleInfo = roles.flat().find((role) => role.key === 'helpdesk')!

	return (
		<Form
			form={form}
			autoComplete='true'
			initialValues={assistantRoleData}
			onValuesChange={updateAssistantRoleData}
			labelCol={{ span: 4 }}
			wrapperCol={{ span: 14 }}
			className={styles.form}
			layout='vertical'
		>
			<RoleHeader
				avatar={roleInfo.avatar}
				name={roleInfo.name}
				description={roleInfo.description}
			/>

			<FormHeading title='Base information' />

			<SystemName />

			<AdditionalInformation />

			<EmojiConfig disabledEmoji={assistantRoleData.disabledEmoji} />

			<FormHeading title='Role settings' />

			<ConversationConfig />

			<FollowupsConfig
				followupInterval={
					'followupInterval' in assistantRoleData ? assistantRoleData.followupInterval : undefined
				}
			/>

			<FormHeading title='Advanced settings' />

			<NaturalizationBooster />
			<RedirectConditions />
		</Form>
	)
}

const assistantRoleSchema = Joi.object({
	...createHelpdeskRoleSchema,
	id: Joi.any().optional(),
}).required()

export default HelpdeskRoleForm
