import { Link } from 'react-router-dom'
import styles from './AboutUs.module.scss'

const AboutUs = () => {
	return (
		<div className={styles.container}>
			<div className={styles.wrapper}>
				<div>
					<Link to='/'>
						<h1 className={styles.title}>Quantum Neuron</h1>
					</Link>
					<p>
						<b>Quantum Neuron</b> is a company, which is a platform offering advanced technologies
						and solutions using artificial intelligence for business and private use.
					</p>

					<p>
						We offer product services in the field of intelligent <b>Chatbots AI</b> equipped with
						numerous tools to automate tasks such as: creating texts, graphics, editing photos,
						creating animations based on photos, replacing faces in photos, textual description of
						photos, creating speech based on text, creating speech transcription, creating music.
					</p>

					<p>
						We also offer <b>Persona AI</b> capable of natively and &quot;humanly&quot; automating
						sales and customer support processes. This functionality our platform clients are able
						to integrate with the most popular instant messengers such as Messenger, Instagram,
						Whatsapp, Telegram and Gmail. We store our clients data on servers located from the
						European Union through the cloud provider Amazon Web Services, Inc. more detailed
						information about privacy policy can be found <Link to='/privacy-policy'>HERE</Link>.
					</p>

					<p>
						Currently, our platform is in a closed test phase among trusted customers. If you are
						interested in cooperation or access to the platform please{' '}
						<a href='mailto:contact@quantumneuron.eu'>contact us by email</a>.
					</p>

					<p>
						<div>
							Support contact (e-mail):{' '}
							<a href='mailto:contact@quantumneuron.eu'>contact@quantumneuron.eu</a>
						</div>
						<div>
							Website:{' '}
							<a href='https://staging.quantumneuron.eu'>https://staging.quantumneuron.eu</a>
						</div>
					</p>
				</div>
				<div className={styles.footer}>© 2024 Quantum Neuron</div>
			</div>
		</div>
	)
}

export default AboutUs
