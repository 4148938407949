import { App, Button } from 'antd'
import Loading from '../../../../../components/Loading/Loading'
import React, { ReactNode, useEffect, useMemo, useState } from 'react'
import { ExportOutlined } from '@ant-design/icons'
import {
	CANCEL_CONTENT,
	CONTEXT_LOADED_CONTENT,
	COST_LIMIT_CONTENT,
	ERROR_CONTENT,
	LOADING_CONTENT,
	SKIP_MESSAGE_CONTENT,
	DELETED_MESSAGE_CONTENT,
	messageRole,
	NOT_SUPPORTED_MESSAGE_CONTENT,
} from '@aiapp/utils/consts'
import { isAudioFile, isImageFile, isVideoFile } from '@aiapp/utils'
import { downloadFileFromUrl, rawTextToStyledMessage } from '../../../../../utils/helpers'
import { useChatContext } from '../../../../../hooks/context/ChatContext'
import MessageSource from './components/MessageSource/MessageSource'
import FileContent from './components/FileContent/FileContent'
import VideoContent from './components/VideoContent/VideoContent'
import AudioContent from './components/AudioContent/AudioContent'
import ImageContent from './components/ImageContent/ImageContent'
import {
	attachedFilesToIds,
	getUrlsFromMessage,
	isMessageContentWithUrls,
	messageContentWithoutUrls,
} from '@aiapp/utils/assistant'
import { PublicMessage } from '../../../../../types/Chat'
import styles from './MessageContent.module.scss'

type Props = {
	chatMessage: PublicMessage
	scrollToBottom: () => void
	lastMessageChildren: PublicMessage | undefined
	resendMessage?: (retryMessage: PublicMessage) => void
}

const MessageContent = ({
	chatMessage,
	resendMessage,
	scrollToBottom,
	lastMessageChildren,
}: Props) => {
	const { message } = App.useApp()

	const chatContext = useChatContext()
	const isAssistant = chatContext.useSubscribe((context) => context.chat?.isAssistant)
	const chatFiles = chatContext.useSubscribe((context) => context.chatFiles)

	const [downloadLoading, setDownloadLoading] = useState<boolean>(false)
	const [hasError, setHasError] = useState<boolean>(false)

	useEffect(scrollToBottom, [chatMessage.content, lastMessageChildren?.content])

	const downloadFile = async (fileId: string) => {
		const findFileData = chatFiles.find((fileData) => fileData.id === fileId)

		if (!findFileData) {
			message.open({
				type: 'error',
				content: 'Wait until the file is uploaded or try to refresh page.',
			})
			return
		}

		setDownloadLoading(true)
		downloadFileFromUrl(findFileData.url, findFileData.name)
		setDownloadLoading(false)
	}

	const messageTextToStyledMessage = (message: PublicMessage): ReactNode => {
		let content = message.content
		if (message.isComment) {
			content = `*User comment written under post*:\n${message.content}`
		}

		const styledMessage = rawTextToStyledMessage(content)
		if (!styledMessage.length) {
			return <span />
		}

		return <span dangerouslySetInnerHTML={{ __html: styledMessage }} />
	}

	const getMessageContent = (messageOrChildren: PublicMessage): ReactNode => {
		if (messageOrChildren.role !== messageRole.USER) {
			if (messageOrChildren.content.trim() === LOADING_CONTENT) {
				return <Loading size={22} />
			}
			if (messageOrChildren.content.trim() === CONTEXT_LOADED_CONTENT) {
				return (
					<span>
						The chat context has been successfully expanded with the <b>indicated data sources</b>.
					</span>
				)
			}
			if (messageOrChildren.content.trim() === ERROR_CONTENT || hasError) {
				return (
					<span>
						Error while generate message.{' '}
						{!isAssistant && !!resendMessage && (
							<Button
								onClick={() => {
									resendMessage(chatMessage)
									setHasError(false)
								}}
								className={`${styles.tryAgainButton} ${styles.error}`}
								type='text'
							>
								Try again
							</Button>
						)}
					</span>
				)
			}
			if (messageOrChildren.content.trim() === CANCEL_CONTENT) {
				return (
					<span>
						This message has been canceled.{' '}
						{!isAssistant && !!resendMessage && (
							<Button
								onClick={() => resendMessage(chatMessage)}
								className={`${styles.tryAgainButton} ${styles.stop}`}
								type='text'
							>
								Try again
							</Button>
						)}
					</span>
				)
			}
			if (messageOrChildren.content.trim() === COST_LIMIT_CONTENT) {
				return (
					<span>
						Cost limit error.{' '}
						<a
							className={`${styles.tryAgainButton} ${styles.error}`}
							href='mailto:mateuszpijanowski0@gmail.com'
						>
							Try to contact with administrator
						</a>{' '}
						to increase trial limit.
					</span>
				)
			}
		}

		if (messageOrChildren.content.trim() === NOT_SUPPORTED_MESSAGE_CONTENT) {
			return (
				<span>
					This message is currently <b>not supported</b>, to view this content{' '}
					<b>go to provider chat</b>.
				</span>
			)
		}
		if (messageOrChildren.content.trim() === SKIP_MESSAGE_CONTENT) {
			return (
				<span>
					This {messageOrChildren.role === messageRole.USER ? 'user' : 'Persona'} message has been{' '}
					<b>omitted</b>.
				</span>
			)
		}
		if (messageOrChildren.content.trim() === DELETED_MESSAGE_CONTENT) {
			return (
				<span>
					This message has been <b>deleted</b>.
				</span>
			)
		}

		if (messageOrChildren.content.trim() === SKIP_MESSAGE_CONTENT) {
			return (
				<span>
					This message has been <b>omitted</b>.
				</span>
			)
		}
		if (!isAssistant && isMessageContentWithUrls(messageOrChildren.content)) {
			return (
				<div>
					{getUrlsFromMessage(messageOrChildren.content).map((url, index) => (
						<div key={`${url}.${index}`}>
							<a target='_blank' href={url} rel='noreferrer'>
								<ExportOutlined className={styles.openLinkIcon} />
								{url}
							</a>
						</div>
					))}
					{messageContentWithoutUrls(messageOrChildren.content).length > 2 && (
						<div>{messageContentWithoutUrls(messageOrChildren.content)}</div>
					)}
				</div>
			)
		}
		if (messageOrChildren.attachedFileIds) {
			const fileIds: string[] = attachedFilesToIds(messageOrChildren.attachedFileIds)

			if (
				!fileIds.length ||
				!fileIds.every((fileId) => chatFiles.some((fileData) => fileId === fileData.id))
			) {
				return (
					<div className={styles.messageWithFiles}>
						<Loading size={22} />
						<div>
							{messageOrChildren.role !== messageRole.USER
								? ''
								: messageTextToStyledMessage(messageOrChildren)}
						</div>
					</div>
				)
			}

			return (
				<div className={styles.messageWithFiles}>
					{fileIds.map((fileId) => {
						const findFileData = chatFiles.find((fileData) => fileData.id === fileId)

						if (!findFileData) {
							return <></>
						}

						if (isImageFile(findFileData!.type)) {
							return (
								<ImageContent
									key={fileId}
									fileData={findFileData}
									scrollToBottom={scrollToBottom}
								/>
							)
						}
						if (isAudioFile(findFileData!.type)) {
							return (
								<AudioContent
									key={fileId}
									fileData={findFileData}
									scrollToBottom={scrollToBottom}
								/>
							)
						}
						if (isVideoFile(findFileData!.type)) {
							return (
								<VideoContent
									key={fileId}
									fileData={findFileData}
									scrollToBottom={scrollToBottom}
								/>
							)
						}
						return (
							<FileContent
								key={fileId}
								fileData={findFileData}
								downloadFile={downloadFile}
								downloadLoading={downloadLoading}
							/>
						)
					})}
					<div>{messageTextToStyledMessage(messageOrChildren)}</div>
				</div>
			)
		}
		if (messageOrChildren.sources?.length) {
			return (
				<div>
					<div>{messageTextToStyledMessage(messageOrChildren)}</div>
					<div className={styles.messageSources}>
						{messageOrChildren.sources.map((source, index) => (
							<MessageSource key={`${messageOrChildren.id}.${index}`} source={source} />
						))}
					</div>
				</div>
			)
		}

		return messageTextToStyledMessage(messageOrChildren)
	}

	return useMemo(() => {
		const messageOrChildren = lastMessageChildren ?? chatMessage
		return (
			<div
				className={`
				${styles.message} 
				${chatMessage.role === messageRole.USER ? styles.userMessage : styles.assistantMessage}
			`}
			>
				{getMessageContent(messageOrChildren)}
			</div>
		)
	}, [chatMessage, lastMessageChildren, downloadLoading, chatFiles, isAssistant, hasError])
}

export default MessageContent
