import axios, { AxiosRequestConfig, Method } from 'axios'
import { delay, getCookies } from '@aiapp/utils'
import { setupCache } from 'axios-cache-interceptor'

const instance = axios.create()
const cachedAxios = setupCache(instance)

const query = async <T>(
	path: string,
	method: Method,
	options: Partial<Omit<AxiosRequestConfig, 'url' | 'method'>> & {
		attempt?: number
		retryAttempts?: number
		useCache?: boolean
		timeout?: number
	} = {},
): Promise<T> => {
	const reqOptions: AxiosRequestConfig = {
		url: `/api${path}`,
		method,
		timeout: 1000 * 60,
		...options,
	}

	try {
		if (options.useCache) {
			const response = await cachedAxios.request(reqOptions)
			return response.data
		}

		const response = await axios.request(reqOptions)
		return response.data
	} catch (err: any) {
		const errorData = err.response?.data ?? err
		if (axios.isAxiosError(err)) {
			if (err.response && err.response.status === 401 && options.withCredentials) {
				const cookies = getCookies()
				if (cookies['hasRefresh'] === '1') {
					if ((options.attempt ?? 0) >= 3) {
						throw new Error('Authorization error. Try to log in again.')
					}

					try {
						await axios.post(`/api/account/refresh`, {}, { timeout: 5000, withCredentials: true })
					} catch (err: any) {
						throw new Error('Unexpected server error')
					}

					options.attempt = (options.attempt ?? 0) + 1
					return await query(path, method, options)
				}
			}
		}

		if (options.retryAttempts && options.retryAttempts > 0) {
			await delay(100)
			options.retryAttempts -= 1
			return await query(path, method, options)
		}

		throw new Error(errorData.message ?? 'Unexpected server error')
	}
}

export default query
