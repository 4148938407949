import React, { useRef, useState } from 'react'
import { Tag } from 'antd'
import styles from './MessageSource.module.scss'

type Props = {
	source: string
}

const MessageSource = ({ source }: Props) => {
	const [expandSource, setExpandSource] = useState<boolean>(false)
	const clickStartTime = useRef<number | null>(null)

	const onCLickStart = () => {
		clickStartTime.current = Date.now()
	}

	const onClickEnd = () => {
		// we want to check if user selecting text from expand source text
		if (clickStartTime.current !== null) {
			const clickDuration = Date.now() - clickStartTime.current

			if (clickDuration < 300) {
				setExpandSource((expand) => !expand)
			}
		}

		clickStartTime.current = null
	}

	return (
		<Tag
			onMouseDown={onCLickStart}
			onMouseUp={onClickEnd}
			className={`${styles.messageSource} ${expandSource ? styles.expand : ''}`}
		>
			&quot;{source}&quot;
		</Tag>
	)
}

export default MessageSource
