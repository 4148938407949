import {
	ConversationStyle,
	FollowupInterval,
	GoalUrl,
	MaxFollowups,
	ResponseLength,
	WarmupLevel,
} from '../assistantRole'

export type SellerOffersRole = {
	type: 'sellerOffers'
	companyOffer: string
	promotionsDescription?: string
	disabledEmoji: boolean
	preferredEmoji?: string
	followupInterval?: FollowupInterval
	maxFollowups?: MaxFollowups
	conversationStyle: ConversationStyle
	responseLength: ResponseLength
	warmupLevel: WarmupLevel

	goal: SellerOffersGoal
	offersUrls?: GoalUrl[]
}

export const SELLER_OFFERS_GOALS = ['offersUrls'] as const
export type SellerOffersGoal = (typeof SELLER_OFFERS_GOALS)[number]
