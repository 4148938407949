import { Navigate } from 'react-router-dom'
import { useAccountContext } from '../hooks/context/AccountContext'
import React, { ReactElement, useEffect, useRef, useState } from 'react'
import Loading from '../components/Loading/Loading'

type Props = {
	children: ReactElement
	roles?: string[]
}

const AuthRoute = ({ children, roles }: Props) => {
	const [isAuth, setIsAuth] = useState<boolean | null>(null)
	const [checkAccountAttempts, setCheckAccountAttempts] = useState<number>(0)

	const accountContext = useAccountContext()
	const accountId = accountContext.useSubscribe((account) => account.id)
	const accountRoles = accountContext.useSubscribe((account) => account.roles)
	const accountLoaded = accountContext.useSubscribe((account) => account.loaded)

	const timeoutRef = useRef<any>()

	useEffect(() => {
		if (timeoutRef.current) {
			clearTimeout(timeoutRef.current)
		}

		if (!accountLoaded) {
			if (checkAccountAttempts > 10) {
				setIsAuth(false)
			} else {
				timeoutRef.current = setTimeout(
					() => setCheckAccountAttempts((attempts) => attempts + 1),
					100,
				)
			}
			return
		}

		if (roles?.length && accountRoles.some((role) => roles.includes(role))) {
			setIsAuth(true)
		} else if (!roles?.length && !!accountId) {
			setIsAuth(true)
		} else {
			setIsAuth(false)
		}
	}, [accountLoaded, accountId, accountRoles, checkAccountAttempts])

	if (isAuth === null) {
		return (
			<div style={{ height: '100vh' }}>
				<Loading size={32} />
			</div>
		)
	}

	return isAuth ? children : <Navigate to='/login' replace={true} />
}

export default AuthRoute
