import { LLMModelName, LLMName } from '../types/global'

export const contentTypeToExtension: Record<string, string[]> = {
	'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
	'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
	'application/vnd.ms-excel': ['.xls'],
	'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
	'application/pdf': ['.pdf'],
	'text/html': ['.html', '.htm'],
	'text/csv': ['.csv'],
	'application/json': ['.json'],
	'text/plain': ['.txt'],
	'text/markdown': ['.md'],
	'image/png': ['.png'],
	'image/jpeg': ['.jpg', '.jpeg'],
	'image/gif': ['.gif'],
	'audio/mpeg': ['.mp3'],
	'audio/wav': ['.wav'],
	'video/mp4': ['.mp4'],
}

export const getContentTypeFromExtension = (fileExtension: string): string | undefined => {
	for (const [mimetype, extensions] of Object.entries(contentTypeToExtension)) {
		if (extensions.includes(fileExtension)) {
			return mimetype
		}
	}
	return undefined
}

export const isImageFile = (type: string) => {
	const regex = /image\//
	return regex.test(type)
}

export const isAudioFile = (type: string) => {
	const regex = /audio\//
	return regex.test(type)
}

export const isVideoFile = (type: string) => {
	const regex = /video\//
	return regex.test(type)
}

export const getCookies = (cookies?: string): Record<string, string> => {
	try {
		return (cookies ?? document.cookie).split(';').reduce((res, c) => {
			const [key, val] = c.trim().split('=').map(decodeURIComponent)
			return Object.assign(res, { [key]: val })
		}, {})
	} catch (err) {
		// ignore 'Access is denied for this document'
		return {}
	}
}

export const omit = <T, K extends keyof T>({ ...object }: T, ...fields: K[]): Omit<T, K> => {
	fields.forEach((field) => {
		delete object[field]
	})
	return object
}

export const removeNonValueKeys = <T>(obj: object): T => {
	const result: any = {}
	for (const [key, value] of Object.entries(obj)) {
		if (value !== undefined && value !== null) {
			result[key] = value
		}
	}
	return result
}

export const uniqArray = (arr: any[]) => [...new Set(arr)]

export const deepCompare = (obj1: any, obj2: any) => {
	if (typeof obj1 !== 'object' || typeof obj2 !== 'object') {
		return obj1 === obj2
	}

	const keys1 = Object.keys(obj1)
	const keys2 = Object.keys(obj2)

	if (keys1.length !== keys2.length) {
		return false
	}

	for (const key of keys1) {
		if (!(key in obj2) || !deepCompare(obj1[key], obj2[key])) {
			return false
		}
	}

	return true
}

export const llmNameToModel: Record<LLMName, LLMModelName> = {
	'gpt-4o': 'gpt-4o-2024-08-06',
	'gpt-4o mini': 'gpt-4o-mini-2024-07-18',
	'gpt-3.5-turbo': 'gpt-3.5-turbo-0125',
}

export const delay = async (ms: number) => {
	await new Promise((resolve) => {
		setTimeout(resolve, ms)
	})
}

export const parseEnv = (value: any, defaultValue?: string | number) => {
	if (!value || value === '') {
		if (defaultValue) {
			return defaultValue
		}
		return ''
	}
	if (isNaN(+value)) {
		return value
	}
	return +value
}

export const getRandomArrayItem = <T>(array: any[]): T | undefined => {
	if (array.length === 0) {
		return
	}
	const randomIndex = Math.floor(Math.random() * array.length)
	return array[randomIndex]
}

export const roundNumber = (value: number | undefined, precision: number): number => {
	if (!value) {
		return 0
	}
	const roundedValue = value.toFixed(precision)
	return +roundedValue
}

export const getCostValue = (rawCost: number, approximation = true): string => {
	if (!rawCost || rawCost < 0.01) {
		return `<${0.01}$`
	}

	const parsedCost = rawCost.toFixed(2)
	if (approximation) {
		return `~${parsedCost}$`
	}
	return `${parsedCost}$`
}
