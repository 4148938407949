import one from '../assets/roles/1.webp'
import two from '../assets/roles/2.webp'
import three from '../assets/roles/3.webp'
import four from '../assets/roles/4.webp'
import five from '../assets/roles/5.webp'
import six from '../assets/roles/6.webp'
import seven from '../assets/roles/7.webp'
import eight from '../assets/roles/8.webp'
import nine from '../assets/roles/9.webp'
import ten from '../assets/roles/10.webp'
import eleven from '../assets/roles/11.webp'
import twelve from '../assets/roles/12.webp'

export type RoleItem = {
	key: string
	avatar: string
	name: string
	description: string
	soon?: true
}

export const roles: RoleItem[][] = [
	[
		{
			key: 'sellerOffers',
			avatar: one,
			name: 'Sprzedawca Ofertowy',
			description:
				'Rola odpowiedzialna za nawiązywanie i utrzymywanie relacji z klientami. Uważnie słucha, aby zrozumieć ich potrzeby i wyzwania, a następnie przedstawia spersonalizowane oferty firmy, które najlepiej odpowiadają ich oczekiwaniom. Jej celem jest budowanie zaufania i dostarczanie rozwiązań, które przynoszą realną wartość klientom i prowadzą do ostatecznej sprzedaży oferty.',
		},
		{
			key: 'leadGeneration',
			avatar: two,
			name: 'Generator leadów',
			description:
				'Buduje relację z klientami, poznaje ich potrzeby i problemy, a następnie proponuje rozwiązanie w postaci oferty firmy. Jej celem jest zachęcenie klienta do pozostawienia danych kontaktowych, takich jak numer telefonu lub adres e-mail, aby umożliwić dalszą współpracę. Działa jako pierwszy punkt kontaktu, nawiązując autentyczną i wartościową interakcję z potencjalnymi klientami.',
		},
		{
			key: 'appointmentSetter',
			avatar: three,
			name: 'Appointment Setter',
			description:
				'Rola skupiona na nawiązywaniu relacji z klientem i klasyfikacji klienta w ramach oferty firmy, w celu zrozumienia potrzeb klienta i umówieniu spotkania online. Dzięki profesjonalnemu podejściu i umiejętności budowania zaufania, efektywnie umawia klientów na spotkania omawiające szczegóły oferty, dbając o płynność komunikacji i satysfakcję klientów.',
		},
		{
			key: 'helpdesk',
			avatar: four,
			name: 'Helpdesk',
			description:
				'Rola zaprojektowana, aby wspierać klientów w rozwiązywaniu problemów oraz odpowiadać na pytania związane z obsługą klienta. Dostosowana do specyfiki firmy, rola rozwiązująca problemy użytkowników i odpowiadająća na pytania powiązane z firmą i jej zakresem usług.',
		},
	],
	[
		{
			key: 'helpdeskUpsell',
			avatar: five,
			name: 'Helpdesk Upsell & Cross-sell',
			description:
				'Wszechstronny doradca, który nie tylko efektywnie rozwiązuje problemy i odpowiada na pytania klientów, ale również aktywnie proponuje im dodatkowe produkty lub usługi, idealnie dopasowane do ich potrzeb. Rola ta nie tylko zwiększa satysfakcję klienta, ale także wspiera rozwój biznesu poprzez skuteczny upselling i cross-selling.',
		},
		{
			key: 'externalAssistant',
			avatar: six,
			name: 'Zewnętrzna Asystentka Zarządu',
			description:
				'Asystentka koncentrująca się na zarządzaniu kalendarzem spotkań zarządu. Jej głównym zadaniem jest sprawne umawianie klientów i partnerów biznesowych na spotkania z członkami zarządu, dbając o to, by harmonogram i spotkania były efektywnie zorganizowane.',
		},
		{
			key: 'internalAssistant',
			avatar: seven,
			name: 'Wewnętrzna Asystentka Zarządu',
			description:
				'Kluczowy członek zespołu wspierający codzienne funkcjonowanie firmy. Jej zadaniem jest efektywne zarządzanie dokumentacją, w tym przygotowywanie kluczowych dokumentów takich jak NDA, polityki prywatności i regulaminów. Dzięki swojej niezawodności i dbałości o szczegóły, zapewnia płynne działanie procesów administracyjnych oraz wspiera zarząd w podejmowaniu strategicznych decyzji.',
		},
		{
			key: 'screenwriter',
			avatar: eight,
			name: 'Scenarzysta Rolek',
			description:
				'Rola odpowiedzialna za tworzenie angażujących scenariuszy do krótkich treści wideo na platformy społecznościowe takie jak YouTube, Instagram czy TikTok. Z pełnym zrozumieniem marki i jej odbiorców, rola ta planuje i opracowuje dynamiczne scenariusze, które przyciągają uwagę i zwiększają zainteresowanie użytkowników produktami danej firmy.',
			soon: true,
		},
	],
	[
		{
			key: 'communicationsAuditor',
			avatar: nine,
			name: 'Audytor Komunikacji',
			description:
				'Specjalista ds. analizy i optymalizacji interakcji z klientami. Na podstawie danych firmowych oraz konkretnych przykładów bieżącej komunikacji, Audytor doradza najlepsze strategie i podejścia w dalszym kontakcie z klientem. Jego celem jest usprawnienie relacji z klientami poprzez precyzyjne rekomendacje komunikacyjne, które zwiększają efektywność i jakość obsługi i pracy z klientem.',
			soon: true,
		},
		{
			key: 'productivityCoach',
			avatar: ten,
			name: 'Trener Produktywności',
			description:
				'Ekspert w dziedzinie efektywnego zarządzania czasem i motywacji. Pomaga Ci w tworzeniu spersonalizowanych planów działania, wspiera w wyznaczaniu priorytetów oraz monitoruje postępy, byś mógł skutecznie realizować swoje cele. Dzięki swojej wiedzy i wsparciu, pozwala osiągnąć maksymalną produktywność w codziennych zadaniach.',
			soon: true,
		},
		{
			key: 'emailMarketer',
			avatar: eleven,
			name: 'E-mail Marketer',
			description:
				'Rola odpowiedzialna za tworzenie angażujących kampanii mailowych i newsletterów. Jej celem jest budowanie treści mailowych o charakterze markietingowych i budowanie newsletterów dla klientów danej firmy. Wspiera procesy kreacji mailingowych.',
			soon: true,
		},
		{
			key: 'relationshipManagement',
			avatar: twelve,
			name: 'Zarządzanie relacjami z klientami',
			description:
				'Specjalista ds. zarządzania relacjami z klientami, Jego zadaniem jest regularne kontaktowanie się z klientami w celu utrzymania i wzmocnienia długoterminowych relacji. Skupia się na monitorowaniu ich potrzeb i problemów, aktywnie słucha i buduje jakościową relacje z klientami. Utrzymuje i rozwija stały kontakt z wybranymi klientami.',
			soon: true,
		},
	],
	[
		{
			key: 'journalist',
			avatar: six,
			name: 'Dziennikarka',
			description:
				'Rola polegająca na prowadzeniu wnikliwych i angażujących wywiadów dziennikarskich. Specjalizuje się w przygotowywaniu i zadawaniu przemyślanych pytań, które są precyzyjnie dopasowane do charakteru i tematu rozmowy. Jej celem jest sformułowanie odpowiednich pytań skierowanych do użytkownika w celu stworzenia pełnego wywiadu o danym charakterze i temacie preferowanym przez użytkownika.',
			soon: true,
		},
	],
]
