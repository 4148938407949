import React, { useEffect, useMemo, useState } from 'react'
import useAsyncEffect from '../../../hooks/useAsyncEffect'
import query from '../../../utils/query'
import { App, Button, Layout, Modal, Tooltip } from 'antd'
import Loading from '../../../components/Loading/Loading'
import useTryCatch from '../../../hooks/useTryCatch'
import {
	CreateLeadRequest,
	GetLeadsResponse,
	Lead as LeadInterface,
	LeadsFilters,
	LeadsSortParams,
	UpdateLeadRequest,
} from '@aiapp/types/leadsGroup/lead'
import {
	SyncOutlined,
	PlusCircleOutlined,
	GoogleOutlined,
	WhatsAppOutlined,
	DeleteOutlined,
} from '@ant-design/icons'
import useQuery from '../../../hooks/useQuery'
import Lead from './Lead/Lead'
import LeadsTable from './LeadsTable/LeadsTable'
import { socket } from '../../../global/SocketManager'
import StartLeadsConversationsForm from './StartLeadsConversationsForm/StartLeadsConversationsForm'
import { useAccountContext } from '../../../hooks/context/AccountContext'
import styles from './Leads.module.scss'

const { Header } = Layout

const Leads = () => {
	const { message } = App.useApp()
	const tryCatch = useTryCatch(message)

	const groupId = useQuery().get('groupId')

	const accountContext = useAccountContext()
	const usingUserId = accountContext.useSubscribe((account) => account.usingUserId)

	const [leads, setLeads] = useState<LeadInterface[]>([])
	const [leadsCount, setLeadsCount] = useState<number>(0)

	const [filters, setFilters] = useState<LeadsFilters>({ page: 1, perPage: 20 })
	const [sortParams, setSortParams] = useState<LeadsSortParams | undefined>()

	const [openLeadModal, setOpenLeadModal] = useState<boolean>(false)

	const [selectedProvider, setSelectedProvider] = useState<'gmail' | 'whatsapp' | undefined>()
	const [openStartConversationsModal, setOpenStartConversationsModal] = useState<boolean>(false)

	const [leadToUpdate, setLeadToUpdate] = useState<Partial<LeadInterface>>()
	const [selectedLeadsIds, setSelectedLeadsIds] = useState<string[]>([])

	const [loading, setLoading] = useState<boolean>(true)
	const [startingConversationsLoading, setStartingConversationsLoading] = useState<boolean>(false)

	const onLoadLeads = async (filters: LeadsFilters, sortParams?: LeadsSortParams) => {
		if (!groupId) {
			return
		}

		setLoading(true)
		await tryCatch(async () => {
			const { data, count, restIds, page, perPage } = await query<GetLeadsResponse>(
				'/leadsGroup/leads/filter',
				'POST',
				{
					withCredentials: true,
					data: {
						groupId,
						filters,
						sortParams,
					},
				},
			)

			if (page === 1) {
				setLeads([...data, ...(restIds as LeadInterface[])])
			} else if (Math.ceil(count / perPage) === page) {
				setLeads([...(restIds as LeadInterface[]), ...data])
			} else {
				const firstRestIds = page * perPage - perPage
				const lastRestIds = firstRestIds + perPage
				const preparedLeads: LeadInterface[] = [
					...restIds.slice(0, firstRestIds),
					...data,
					...restIds.slice(lastRestIds),
				] as LeadInterface[]
				setLeads(preparedLeads)
			}

			setLeadsCount(count)
			setFilters(filters)
			setSortParams(sortParams)
		})
		setLoading(false)
	}

	const updateLeadState = (updatedLead: LeadInterface) => {
		if (!updatedLead || updatedLead.groupId !== groupId) {
			return
		}
		setLeads((leads) =>
			leads.map((lead) => {
				if ('createdAt' in lead) {
					if (lead.id === updatedLead.id) {
						return updatedLead
					}
				}
				return lead
			}),
		)
	}

	useAsyncEffect(() => onLoadLeads(filters, sortParams), [groupId])

	useEffect(() => {
		socket.on('leadUpdated', updateLeadState)
		return () => {
			socket.off('leadUpdated', updateLeadState)
		}
	}, [leads])

	const setPagination = (newPage?: number, newPerPage?: number) => {
		let needRefresh = false
		const newFilters: LeadsFilters = { ...filters }

		if (newPage && newPage !== newFilters.page) {
			newFilters.page = newPage
			needRefresh = true
		}
		if (newPerPage && newPerPage !== newFilters.perPage) {
			newFilters.perPage = newPerPage
			needRefresh = true
		}

		if (needRefresh) {
			onLoadLeads(newFilters, sortParams).then(() => {})
		}
	}

	const deleteLeads = async (leadsIds: string[]) => {
		await tryCatch(
			async () => {
				await query('/leadsGroup/leads/delete', 'POST', {
					data: { ids: leadsIds, usingUserId },
					withCredentials: true,
				})
				setSelectedLeadsIds([])
			},
			undefined,
			{ message: 'Error when deleting leads. Some leads may not have been deleted.' },
		)

		message.open({
			type: 'success',
			content: `${leadsIds.length} leads was deleted`,
		})

		await onLoadLeads(filters, sortParams)
	}

	const updateLead = async (leadId: string, data: Partial<LeadInterface>) => {
		await tryCatch(async () => {
			const newData: UpdateLeadRequest = {
				id: leadId,
				...data,
			}

			const updatedLead = await query<LeadInterface>('/leadsGroup/lead/update', 'POST', {
				data: newData,
				withCredentials: true,
			})

			setLeads((leads) => leads.map((lead) => (lead.id === leadId ? updatedLead : lead)))

			message.open({
				type: 'success',
				content: `Lead was updated`,
			})
			await closeLeadsModal()
		})
	}

	const onOpenStartConversationsModal = (provider: 'gmail' | 'whatsapp') => {
		setSelectedProvider(provider)
		setOpenStartConversationsModal(true)
	}

	const onCloseStartConversationsModal = () => {
		setOpenStartConversationsModal(false)
		setSelectedProvider(undefined)
	}

	const startLeadsConversations = async (
		startConversationsData: StartConversationsFormData,
		selectedAssistantRoleId: string,
	) => {
		setStartingConversationsLoading(true)
		await tryCatch(
			async () => {
				await query('/leadsGroup/leads/startConversations', 'POST', {
					data: {
						leadsGroupId: groupId,
						assistantRoleId: selectedAssistantRoleId,
						leadsIds: selectedLeadsIds,
						...startConversationsData,
					},
					withCredentials: true,
				})

				message.open({
					type: 'loading',
					content: `New ${startConversationsData.provider} conversations are being started for selected leads...`,
				})
				setTimeout(() => setStartingConversationsLoading(false), 1000)
			},
			() => {
				setStartingConversationsLoading(false)
			},
		)

		onCloseStartConversationsModal()
	}

	const createNewLead = async (newLeadData: Partial<LeadInterface>) => {
		if (!groupId) {
			return
		}

		await tryCatch(async () => {
			const newData: CreateLeadRequest = {
				...newLeadData,
				groupId,
			}

			await query<LeadInterface>('/leadsGroup/lead/create', 'POST', {
				data: {
					...newData,
					usingUserId,
				},
				withCredentials: true,
			})

			message.open({
				type: 'success',
				content: `New lead was created`,
			})
			await closeLeadsModal()
			await onLoadLeads(filters, sortParams)
		})
	}

	const createLeadsFromCsv = async (csvFile: File) => {
		if (!groupId) {
			return
		}

		return await tryCatch<string>(
			async () => {
				const leadsCsv = new FormData()
				leadsCsv.append('groupId', groupId)
				leadsCsv.append('file', csvFile)

				if (usingUserId) {
					leadsCsv.append('usingUserId', usingUserId)
				}

				return await query<string>('/leadsGroup/leads/create/csv', 'POST', {
					headers: {
						'Content-Type': 'multipart/form-data',
					},
					data: leadsCsv,
					withCredentials: true,
				})
			},
			() => {
				closeLeadsModal()
			},
		)
	}

	const openCreateOrUpdateLeadsModal = (lead?: Partial<LeadInterface>) => {
		setOpenLeadModal(true)
		setLeadToUpdate(lead)
	}

	const closeLeadsModal = async (shouldRefresh?: boolean) => {
		setOpenLeadModal(false)
		setLeadToUpdate(undefined)

		if (shouldRefresh) {
			await onLoadLeads(filters, sortParams)
		}
	}

	const leadsModal = useMemo(
		() => (
			<Modal
				destroyOnClose
				footer={null}
				className={styles.leadModal}
				title={leadToUpdate ? 'Edit lead' : 'Add new leads'}
				open={openLeadModal}
				onCancel={() => closeLeadsModal()}
			>
				<div className={styles.modalContent}>
					<Lead
						leadData={leadToUpdate}
						onCreateNewLead={createNewLead}
						onUpdateLead={updateLead}
						onSubmitCsv={createLeadsFromCsv}
						onClose={closeLeadsModal}
					/>
				</div>
			</Modal>
		),
		[leadToUpdate, openLeadModal],
	)

	const startConversationsModal = useMemo(() => {
		if (!selectedProvider || !groupId) {
			return <></>
		}

		return (
			<Modal
				destroyOnClose
				footer={null}
				className={styles.leadModal}
				title={`Start new ${selectedProvider} conversations`}
				open={openStartConversationsModal}
				onCancel={onCloseStartConversationsModal}
			>
				<div className={styles.modalContent}>
					<StartLeadsConversationsForm
						groupId={groupId}
						provider={selectedProvider}
						onSubmit={startLeadsConversations}
						onCancel={onCloseStartConversationsModal}
					/>
				</div>
			</Modal>
		)
	}, [openStartConversationsModal, groupId, selectedProvider])

	if (loading) {
		return <Loading />
	}

	return (
		<div className={styles.container}>
			{leadsModal}
			{startConversationsModal}

			<Header className={styles.header}>
				<div className={styles.headerElements}>
					<Button icon={<PlusCircleOutlined />} onClick={() => openCreateOrUpdateLeadsModal()}>
						Add leads
					</Button>
					{!!selectedLeadsIds.length && (
						<>
							<Button
								icon={<GoogleOutlined />}
								loading={startingConversationsLoading}
								disabled={startingConversationsLoading}
								onClick={() => onOpenStartConversationsModal('gmail')}
								className={styles.conversationsButton}
							>
								Start Gmail conversations ({selectedLeadsIds.length})
							</Button>

							<Button
								icon={<WhatsAppOutlined />}
								loading={startingConversationsLoading}
								disabled={startingConversationsLoading}
								onClick={() => onOpenStartConversationsModal('whatsapp')}
								className={styles.conversationsButton}
							>
								Start Whatsapp conversations ({selectedLeadsIds.length})
							</Button>
						</>
					)}
				</div>

				<div className={styles.headerElements}>
					{!!selectedLeadsIds.length && (
						<div className={styles.headerElements}>
							<Button
								icon={<DeleteOutlined />}
								danger
								onClick={() => deleteLeads(selectedLeadsIds)}
							>
								Delete selected leads ({selectedLeadsIds.length})
							</Button>
						</div>
					)}

					<Tooltip trigger={['hover', 'focus']} title='Refresh'>
						<Button
							className={styles.headerRefresh}
							onClick={() => onLoadLeads(filters, sortParams)}
							shape='circle'
							icon={<SyncOutlined />}
						/>
					</Tooltip>
				</div>
			</Header>

			<div className={styles.leadsTable}>
				<LeadsTable
					filters={filters}
					total={leadsCount}
					leads={leads}
					deleteLeads={deleteLeads}
					setPagination={setPagination}
					openUpdateLeadModal={openCreateOrUpdateLeadsModal}
					selectedLeadsIds={selectedLeadsIds}
					setSelectedLeads={(leadIds) => setSelectedLeadsIds(leadIds)}
				/>
			</div>
		</div>
	)
}

export type StartConversationsFormData = {
	provider: 'gmail' | 'whatsapp'
	messageContent: string
	reminderMessageContent: string
	subject?: string
	signature?: string
}

export default Leads
