import React, { useEffect } from 'react'

const CalendlyWidget = () => {
	useEffect(() => {
		const script = document.createElement('script')
		script.src = 'https://assets.calendly.com/assets/external/widget.js'
		script.async = true
		document.body.appendChild(script)

		return () => {
			// Cleanup, jeśli skrypt powinien być usunięty przy demontażu komponentu
			document.body.removeChild(script)
		}
	}, [])

	return (
		<div
			className='calendly-inline-widget'
			data-url='https://calendly.com/lukasz-quantumneuron/30min'
			style={{ minWidth: '320px', height: '700px' }}
		/>
	)
}

export default CalendlyWidget
