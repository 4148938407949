import CompanyDetailsFrom from '../../Billing/CompanyDetails/CompanyDetails'

const CompanyDetails = () => {
	return (
		<div>
			<CompanyDetailsFrom />
		</div>
	)
}

export default CompanyDetails
