import React from 'react'
import styles from './Line.module.scss'

type Props = {
	className?: string
}

const Line = ({ className = '' }: Props) => {
	return <div className={`${styles.line} ${className}`} />
}

export default Line
