import React, { useEffect, useRef } from 'react'
import { App, Layout, Tour } from 'antd'
import query from '../../utils/query'
import Loading from '../../components/Loading/Loading'
import ChatHeader from './ChatHeader/ChatHeader'
import ChatFooter from './ChatFooter/ChatFooter'
import SessionList from './ChatSessions/SessionList'
import useTryCatch from '../../hooks/useTryCatch'
import AiMessages from './AiMessages/AiMessages'
import ScrollTo from '../../components/ScrollTo/ScrollTo'
import { useChatContext } from '../../hooks/context/ChatContext'
import { useEditorContext } from '../../hooks/context/EditorContext'
import getSteps from './tourSteps'
import { PublicMessage } from '../../types/Chat'
import ConversationSession from './ConversationSession/ConversationSession'
import styles from './Chat.module.scss'
import { useGlobalContext } from '../../hooks/context/GlobalContext'

const HEADER_HEIGHT = 40
const CONTENT_WITHOUT_MESSAGES_HEIGHT = 232
export const PX_TO_SHOW_SCROLL_DOWN_BUTTON = 320

type Props = {
	messages: PublicMessage[]
	sessionId: string | null
	sendNewMessage: (messageContent: string) => Promise<void>
	scrollDownVisible: boolean
	setScrollDownVisible: (show: boolean) => void
	newSession?: () => void
	moveToSession?: (sessionId: string) => void
	deleteSession?: (selectedSessionId: string) => Promise<void>
	cancelMessage?: (messageId?: string) => Promise<void>
	deleteAssistantMessage?: (deleteMessage: PublicMessage) => Promise<void>
	resendMessage?: (retryMessage: PublicMessage) => Promise<void>
	onOpenEditChatModal?: () => void
	contentCustomHeight?: number
}

const Chat = ({
	messages,
	sessionId,
	sendNewMessage,
	newSession,
	onOpenEditChatModal,
	moveToSession,
	cancelMessage,
	deleteSession,
	resendMessage,
	deleteAssistantMessage,
	scrollDownVisible,
	setScrollDownVisible,
	contentCustomHeight,
}: Props) => {
	const { message } = App.useApp()
	const tryCatch = useTryCatch(message)

	const chatContext = useChatContext()
	const chat = chatContext.useSubscribe((context) => context.chat)
	const progressSteps = chatContext.useSubscribe((context) => context.progressSteps)
	const attachedSessionIds = chatContext.useSubscribe((context) => context.attachedSessionIds)
	const disableNewMessage = chatContext.useSubscribe((context) => context.disableNewMessage)
	const blurMessages = chatContext.useSubscribe((context) => context.blurMessages)
	const footerHeight = chatContext.useSubscribe((context) => context.footerHeight)
	const userScrolling = chatContext.useSubscribe((context) => context.userScrolling)
	const provider = chatContext.useSubscribe((context) => context.currentChatSession?.provider)

	const globalContext = useGlobalContext()
	const showChatTour = globalContext.useSubscribe((context) => context.showChatTour)

	const editorContext = useEditorContext()
	const editorFiles = editorContext.useSubscribe((context) => context.editorFiles)

	const scrollableContainerRef = useRef<any>(null)

	const chatHeaderRef = useRef<any>(null)
	const chatSessionsRef = useRef<any>(null)
	const chatToolsRef = useRef<any>(null)
	const chatChatAttachmentRef = useRef<any>(null)

	useEffect(() => {
		scrollToBottom()
	}, [messages.length, progressSteps])

	const scrollToBottom = (force?: boolean) => {
		if ((force === true || !userScrolling) && scrollableContainerRef.current) {
			scrollableContainerRef.current.scrollTop = scrollableContainerRef.current.scrollHeight
		}
	}

	const handleScroll = (e?: any) => {
		const element = e?.target ?? scrollableContainerRef.current
		const pxToBottom = element.scrollHeight - element.scrollTop - element.clientHeight

		chatContext.setUserScrolling(pxToBottom > 5)

		if (pxToBottom > PX_TO_SHOW_SCROLL_DOWN_BUTTON) {
			setScrollDownVisible(true)
		} else {
			setScrollDownVisible(false)
		}
	}

	const removeAttachedFile = (fileId: string) => {
		chatContext.removeAttachedFileId(fileId)
		chatContext.removeChatFile(fileId)
		for (const inputId of Object.keys(editorFiles)) {
			editorContext.removeEditorFiles(inputId, [fileId])
		}
	}

	const onResendMessage = async (retryMessage: PublicMessage) => {
		if (resendMessage) {
			await resendMessage(retryMessage)
			handleScroll()
		}
	}

	const onSetAttachedSessionIds = (sessionId: string) => {
		const sessionIdIsAdded = attachedSessionIds.some(
			(attachedSessionId) => attachedSessionId === sessionId,
		)

		if (sessionIdIsAdded) {
			chatContext.setChatContext({
				attachedSessionIds: attachedSessionIds.filter(
					(attachedSessionId) => attachedSessionId !== sessionId,
				),
			})
		} else {
			chatContext.setChatContext({
				attachedSessionIds: [...attachedSessionIds, sessionId],
			})
		}
	}

	const onCloseTour = async () => {
		globalContext.setShowChatTour(false)

		await tryCatch(async () => {
			await query('/account/update/modal', 'POST', {
				withCredentials: true,
				data: {
					modalName: 'chatTour',
					show: false,
				},
			})
		})
	}

	if (!chat) {
		return <Loading size={48} />
	}

	const messagesIsBlocked = disableNewMessage || chat.status !== 'active'

	return (
		<div className={styles.container}>
			<Layout className={styles.mainContent}>
				<div
					className={styles.mainContentWrapper}
					style={{ gridAutoRows: `${HEADER_HEIGHT}px 1fr ${footerHeight}px` }}
				>
					<ChatHeader chatHeaderRef={chatHeaderRef} onOpenEditChatModal={onOpenEditChatModal} />

					<div
						style={{
							height:
								window.innerHeight -
								footerHeight -
								(contentCustomHeight ?? CONTENT_WITHOUT_MESSAGES_HEIGHT),
						}}
						className={styles.messagesContainerWrapper}
					>
						{!chat.isAssistant && (
							<div
								className={`${styles.blurBackground} ${blurMessages ? styles.blur : styles.clear}`}
							/>
						)}

						<AiMessages
							messages={messages}
							scrollToBottom={scrollToBottom}
							handleScroll={handleScroll}
							scrollableContainerRef={scrollableContainerRef}
							resendMessage={resendMessage ? onResendMessage : undefined}
							cancelMessage={cancelMessage}
							deleteAssistantMessage={deleteAssistantMessage}
							chatStatus={chat?.status}
						/>
						<ScrollTo
							scrollTo={() => scrollToBottom(true)}
							scrollToVisible={scrollDownVisible}
							direction='down'
						/>
					</div>

					<ChatFooter
						chatToolsRef={chatToolsRef}
						chatChatAttachmentRef={chatChatAttachmentRef}
						cancelMessage={cancelMessage}
						sendNewMessage={sendNewMessage}
						removeAttachedFile={removeAttachedFile}
						messagesIsBlocked={messagesIsBlocked}
					/>
				</div>

				{!!provider && provider !== 'playground' && <ConversationSession />}

				{!!deleteSession && !!moveToSession && !!newSession && (
					<SessionList
						chatSessionsRef={chatSessionsRef}
						sessionId={sessionId}
						deleteSession={deleteSession}
						moveToSession={moveToSession}
						newSession={newSession}
						onSetAttachedSessionIds={onSetAttachedSessionIds}
					/>
				)}
			</Layout>

			{!chat.isAssistant && (
				<Tour
					open={showChatTour}
					onClose={onCloseTour}
					steps={getSteps(chatHeaderRef, chatSessionsRef, chatToolsRef, chatChatAttachmentRef)}
				/>
			)}
		</div>
	)
}

export default Chat
