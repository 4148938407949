import { Button, Card, Carousel } from 'antd'
import { useNavigate, useParams } from 'react-router-dom'
import { RoleItem, roles } from '../../../../../utils/roles'
import { useAccountContext } from '../../../../../hooks/context/AccountContext'
import { AssistantRoleType } from '@aiapp/types/assistant/assistantRole'
import styles from './RolesList.module.scss'

const RolesList = () => {
	const navigate = useNavigate()
	const { assistantId } = useParams()

	const accountContext = useAccountContext()
	const accountOffer = accountContext.useSubscribe((account) => account.offer)

	const selectRoleButtonContent = (roleItem: RoleItem): string => {
		if (roleItem.soon) {
			return 'Soon...'
		}
		if (!accountOffer.availableRoles.includes(roleItem.key as AssistantRoleType)) {
			return 'Not available'
		}
		return 'Select'
	}

	return (
		<Carousel className={styles.carousel} dots={{ className: styles.carouselDots }} arrows>
			{roles.map((rolePage, index) => (
				<div key={index} className={styles.rolesWrapper}>
					<div className={styles.roles}>
						{rolePage.map((roleItem, index) => (
							<Card
								hoverable
								key={`${roleItem.name}-${index}`}
								style={{ width: 300 }}
								styles={{
									body: { padding: '12px 20px' },
								}}
								cover={<img className={styles.avatar} alt='Role avatar' src={roleItem.avatar} />}
							>
								<div className={styles.roleName}>{roleItem.name}</div>
								<div className={styles.roleDescription}>{roleItem.description}</div>

								<div className={styles.selectButtonWrapper}>
									<Button
										disabled={
											roleItem.soon ||
											!accountOffer.availableRoles.includes(roleItem.key as AssistantRoleType)
										}
										className={styles.selectButton}
										onClick={() => navigate(`/persona/${assistantId}/role?role=${roleItem.key}`)}
									>
										{selectRoleButtonContent(roleItem)}
									</Button>
								</div>
							</Card>
						))}
					</div>
				</div>
			))}
		</Carousel>
	)
}

export default RolesList
