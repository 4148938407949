import { Form, Input } from 'antd'
import ShadowForm from '../../components/ShadowForm/ShadowForm'
import { useAssistantRoleContext } from '../../../../../../hooks/context/AssistantRoleContext'
import { MAX_CONTEXT_NAME_LENGTH } from '@aiapp/types/context'
import styles from './KnowledgeLink.module.scss'

const KnowledgeLink = () => {
	const assistantRoleContext = useAssistantRoleContext()
	const contextUrl = assistantRoleContext.useSubscribe((context) => context.contextUrl)

	const updateContextUrl = (value: Record<string, any>) => {
		assistantRoleContext.setContextUrl(Object.values(value)[0])
	}

	return (
		<ShadowForm
			initialValues={{
				contextUrl,
			}}
			onValuesChange={updateContextUrl}
		>
			<Form.Item
				name='contextUrl'
				label='Website URL'
				className={styles.formItem}
				rules={[
					{ message: 'Website URL is invalid', type: 'url' },
					{ message: 'Website URL must have a minimum of 6 characters', type: 'string', min: 6 },
					{
						message: `Website URL can have a maximum of ${MAX_CONTEXT_NAME_LENGTH} characters`,
						type: 'string',
						max: MAX_CONTEXT_NAME_LENGTH,
					},
				]}
			>
				<Input
					minLength={6}
					maxLength={MAX_CONTEXT_NAME_LENGTH}
					className={styles.formInput}
					placeholder='https://quantumneuron.eu'
				/>
			</Form.Item>
		</ShadowForm>
	)
}

export default KnowledgeLink
