import React from 'react'
import styles from './FileFormats.module.scss'

import docxIcon from '../../assets/fileFormats/DOCX.svg'
import pdfIcon from '../../assets/fileFormats/PDF.svg'
import pptxIcon from '../../assets/fileFormats/PPTX.svg'
import xlsxIcon from '../../assets/fileFormats/XSLX.svg'
import mdIcon from '../../assets/fileFormats/MD.svg'
import jsonIcon from '../../assets/fileFormats/JSON.svg'
import htmlIcon from '../../assets/fileFormats/HTML.svg'
import txtIcon from '../../assets/fileFormats/TXT.svg'
import csvIcon from '../../assets/fileFormats/CSV.svg'
import gifIcon from '../../assets/fileFormats/GIF.svg'
import pngIcon from '../../assets/fileFormats/PNG.svg'
import jpegIcon from '../../assets/fileFormats/JPEG.svg'
import mp3Icon from '../../assets/fileFormats/MP3.svg'
import mp4Icon from '../../assets/fileFormats/MP4.svg'

type Props = {
	onClick?: (types: string | string[]) => void
}

const FileFormats = ({ onClick = () => {} }: Props) => {
	return (
		<div className={styles.iconContainer}>
			<img
				onClick={(e) => {
					e.stopPropagation()
					onClick('application/vnd.openxmlformats-officedocument.wordprocessingml.document')
				}}
				className={styles.icon}
				src={docxIcon}
				alt='.docx icon'
			/>
			<img
				onClick={(e) => {
					e.stopPropagation()
					onClick('application/vnd.openxmlformats-officedocument.presentationml.presentation')
				}}
				className={styles.icon}
				src={pptxIcon}
				alt='.pptx icon'
			/>
			<img
				onClick={(e) => {
					e.stopPropagation()
					onClick('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
				}}
				className={styles.icon}
				src={xlsxIcon}
				alt='.xlsx icon'
			/>
			<img
				onClick={(e) => {
					e.stopPropagation()
					onClick('text/csv')
				}}
				className={styles.icon}
				src={csvIcon}
				alt='.csv icon'
			/>
			<img
				onClick={(e) => {
					e.stopPropagation()
					onClick('application/pdf')
				}}
				className={styles.icon}
				src={pdfIcon}
				alt='.pdf icon'
			/>
			<img
				onClick={(e) => {
					e.stopPropagation()
					onClick(['text/markdown'])
				}}
				className={styles.icon}
				src={mdIcon}
				alt='.md icon'
			/>
			<img
				onClick={(e) => {
					e.stopPropagation()
					onClick(['application/xhtml+xml', 'text/html'])
				}}
				className={styles.icon}
				src={htmlIcon}
				alt='.html icon'
			/>
			<img
				onClick={(e) => {
					e.stopPropagation()
					onClick('text/plain')
				}}
				className={styles.icon}
				src={txtIcon}
				alt='.txt icon'
			/>
			<img
				onClick={(e) => {
					e.stopPropagation()
					onClick('application/json')
				}}
				className={styles.icon}
				src={jsonIcon}
				alt='.json icon'
			/>
			<img
				onClick={(e) => {
					e.stopPropagation()
					onClick('image/gif')
				}}
				className={styles.icon}
				src={gifIcon}
				alt='.gif icon'
			/>
			<img
				onClick={(e) => {
					e.stopPropagation()
					onClick('image/png')
				}}
				className={styles.icon}
				src={pngIcon}
				alt='.png icon'
			/>
			<img
				onClick={(e) => {
					e.stopPropagation()
					onClick('image/jpeg')
				}}
				className={styles.icon}
				src={jpegIcon}
				alt='.jpeg icon'
			/>
			<img
				onClick={(e) => {
					e.stopPropagation()
					onClick('audio/mpeg')
				}}
				className={styles.icon}
				src={mp3Icon}
				alt='.mp3 icon'
			/>
			<img
				onClick={(e) => {
					e.stopPropagation()
					onClick('video/mp4')
				}}
				className={styles.icon}
				src={mp4Icon}
				alt='.mp4 icon'
			/>
		</div>
	)
}

export default FileFormats
