import { Statistic } from 'antd'
import React from 'react'
import { PublicAnalytics } from '@aiapp/types/analytics'
import styles from './MessagesStatistics.module.scss'

type Props = {
	analytics: PublicAnalytics | undefined
	loading: boolean
}

const MessagesStatistics = ({ analytics, loading }: Props) => {
	return (
		<div className={styles.statistics}>
			<Statistic
				title='Total generated messages'
				value={analytics?.generatedAssistantMessages}
				loading={loading}
			/>
			<Statistic
				title='Generated messages in last 24h'
				value={analytics?.generatedAssistantMessagesInLastDay}
				loading={loading}
			/>
			<Statistic
				title='Total generated characters'
				value={analytics?.generatedCharacters}
				loading={loading}
			/>
		</div>
	)
}

export default MessagesStatistics
