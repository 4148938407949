import { LogoutOutlined, UserOutlined } from '@ant-design/icons'
import { Layout, Button, Avatar, App } from 'antd'
import React, { ReactNode, useMemo } from 'react'
import { useNavigate, useLocation, Link } from 'react-router-dom'
import { Typography } from 'antd'
import query from '../../utils/query'
import { useAccountContext } from '../../hooks/context/AccountContext'
import useTryCatch from '../../hooks/useTryCatch'
import env from '../../boot/env'
import styles from './SimpleLayout.module.scss'

import quantumLogo from '../../assets/blackLogoTransparent.webp'

const { Title } = Typography
const { Header, Content } = Layout

type Props = {
	children: ReactNode
}

const SimpleLayout = ({ children }: Props) => {
	const navigate = useNavigate()
	const location = useLocation()

	const { message } = App.useApp()
	const tryCatch = useTryCatch(message)

	const accountContext = useAccountContext()
	const emailAddress = accountContext.useSubscribe((context) => context.email)

	const getCurrentPageTitle = useMemo(() => {
		const pathname = location.pathname

		if (pathname === '/login') {
			return 'Login'
		}
		if (pathname === '/signup') {
			return 'Signup'
		}
		if (pathname.startsWith('/onboarding')) {
			return 'Onboarding'
		}
	}, [location.pathname])

	const onLogout = async () => {
		await tryCatch(async () => {
			await query('/account/logout', 'POST', {
				withCredentials: true,
				useCache: true,
			})
		})

		accountContext.logout()
		navigate('/')
	}

	return (
		<div className={styles.layout}>
			<Layout>
				<Header className={styles.header}>
					<div className={styles.leftSize}>
						<img className={styles.logo} src={quantumLogo} alt='Quantum Neuron logo' />
						<Title className={styles.navTitle} level={4}>
							{getCurrentPageTitle}
						</Title>
					</div>

					<div className={styles.rightSide}>
						{emailAddress ? (
							<>
								<Avatar className={styles.userAvatar} size={32} icon={<UserOutlined />} />
								<Button className={styles.logoutButton} type='text' onClick={onLogout}>
									Logout <LogoutOutlined className={styles.logoutIcon} />
								</Button>
							</>
						) : (
							<div className={styles.links}>
								<Link to='/privacy-policy'>Privacy policy</Link>
								<Link to='/about-us'>About Us</Link>
								<a href='mailto:contenct@quantumneuron.eu'>Contact</a>
							</div>
						)}
					</div>
				</Header>

				<Content className={styles.content}>{children}</Content>

				<div className={styles.pageFooter}>
					<div className={styles.buildVersion}>version: {env.version}</div>
					<div>
						Support e-mail: <a href='mailto:contact@quantumneuron.eu'>contact@quantumneuron.eu</a>
					</div>
					<div>© 2024 Quantum Neuron</div>
				</div>
			</Layout>
		</div>
	)
}

export default SimpleLayout
