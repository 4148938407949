import React, { useMemo } from 'react'
import { Fade } from 'react-awesome-reveal'
import { Tooltip } from 'antd'
import FilePreview from '../../../../components/FilePriview/FilePriview'
import { CloseOutlined } from '@ant-design/icons'
import { FileData } from '../../../../types/ChatContext'
import styles from './ChatAttachedFile.module.scss'

type Props = {
	fileId: string
	chatFiles: FileData[]
	removeAttachedFile: (fileId: string) => void
}

const ChatAttachedFile = ({ fileId, chatFiles, removeAttachedFile }: Props) => {
	return useMemo(() => {
		const fileData = chatFiles.find((chatFile) => chatFile.id === fileId)
		if (!fileData) {
			return <></>
		}

		return (
			<Fade direction='up' triggerOnce duration={250} fraction={0}>
				<Tooltip
					mouseEnterDelay={0.3}
					destroyTooltipOnHide
					placement='right'
					title={<FilePreview fileData={fileData} />}
				>
					<div className={styles.file}>
						<div className={styles.filename}>{fileData.name}</div>
						<CloseOutlined
							onClick={() => removeAttachedFile(fileId)}
							className={styles.removeFile}
						/>
					</div>
				</Tooltip>
			</Fade>
		)
	}, [chatFiles])
}

export default ChatAttachedFile
