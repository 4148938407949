import React from 'react'
import { Descriptions, DescriptionsProps } from 'antd'
import { BrokerAccount } from '@aiapp/types/account'

type Props = {
	account: BrokerAccount
}

const UsersDetails = ({ account }: Props) => {
	const accountDetails: DescriptionsProps['items'] = [
		{
			key: '1',
			label: 'First name',
			children: <span>{account.firstName ?? '-'}</span>,
		},
		{
			key: '2',
			label: 'Last name',
			children: <span>{account.lastName ?? '-'}</span>,
		},
		{
			key: '3',
			label: 'Phone',
			children: <span>{account.phone ?? '-'}</span>,
		},
		{
			key: '4',
			label: 'Company name',
			children: <span>{account.company ? account.company.name : '-'}</span>,
		},
		{
			key: '5',
			label: 'Tax ID',
			children: <span>{account.company ? account.company.taxId : '-'}</span>,
		},
		{
			key: '6',
			label: 'Company Address',
			children: <span>{account.company ? account.company.address : '-'}</span>,
		},
	]

	return <Descriptions bordered items={accountDetails} />
}

export default UsersDetails
