import { uuidIdSchema } from '../../global'
import Joi from 'joi'

export const billingAddressSchema = {
	street: Joi.string().max(100).required(),
	country: Joi.string().min(2).max(2).required(),
	city: Joi.string().max(50).required(),
	postalCode: Joi.string().max(10).required(),
	houseNumberOrName: Joi.string().max(10).required(),
}

export const encryptedCardDetailsSchema = {
	encryptedCardNumber: Joi.string().required(),
	encryptedExpiryMonth: Joi.string().required(),
	encryptedExpiryYear: Joi.string().required(),
	encryptedSecurityCode: Joi.string().required(),
}

export const addPaymentMethodRequestSchema = {
	userId: uuidIdSchema.required(),
	holderName: Joi.string().min(3).max(50).required(),
	billingAddress: Joi.object(billingAddressSchema).required(),
	encryptedCardDetails: Joi.object(encryptedCardDetailsSchema).required(),
}
