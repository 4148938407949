import React, { ReactNode, useEffect, useMemo, useRef } from 'react'
import TextArea from 'antd/es/input/TextArea'
import { Accept } from 'react-dropzone'
import UploadFileWrapper from '../../../../components/UploadFileWrapper/UploadFileWrapper'
import { useEditorContext } from '../../../../hooks/context/EditorContext'
import { promptsTemplates } from '@aiapp/utils/toolsPrompts'
import { DEFAULT_INPUT_ID, EditorContent } from '../../../../types/EditorContext'
import { useChatContext } from '../../../../hooks/context/ChatContext'
import { ATTACHED_CONTEXT_LABEL, MessageAttachedFileIds } from '@aiapp/types/chat/chatMessage'
import { MAX_CHAT_FOOTER_HEIGHT, MIN_CHAT_FOOTER_HEIGHT } from '../../../../utils/consts'
import { FileData } from '../../../../types/ChatContext'
import { styledMessageToRawText } from '../../../../utils/helpers'
import { attachedFilesToIds } from '@aiapp/utils/assistant'
import { App } from 'antd'
import { useAttachFiles } from '../../hooks'
import { PromptTemplate, TemplateTool } from '@aiapp/types/global/tools'
import styles from './MessageEditor.module.scss'

const ASSISTANT_CONTEXT_EDITOR_SPACE = 96
const CHAT_CONTEXT_EDITOR_SPACE = 144

const MessageEditor = () => {
	const { message } = App.useApp()
	const attachFiles = useAttachFiles()

	const chatContext = useChatContext()
	const isAssistant = chatContext.useSubscribe((context) => context.chat?.isAssistant)
	const attachedChatFileIds = chatContext.useSubscribe((context) => context.attachedFileIds)
	const chatFiles = chatContext.useSubscribe((context) => context.chatFiles)

	const editorContext = useEditorContext()
	const editorContent = editorContext.useSubscribe((context) => context.editorContent)
	const editorFiles = editorContext.useSubscribe((context) => context.editorFiles)
	const toolType = editorContext.useSubscribe((context) => context.toolType)

	const editorRef = useRef<any>()

	useEffect(() => {
		if (!isAssistant) {
			attachMessageFilesToEditor()
		}
	}, [toolType, attachedChatFileIds, isAssistant])

	useEffect(() => {
		checkMessageValidation(editorContent, editorFiles)
	}, [editorContent, editorFiles])

	useEffect(() => {
		if (!editorRef.current) {
			return
		}
		setTimeout(() => checkTextareaSize(editorRef.current))
	}, [editorContent])

	const attachMessageFilesToEditor = () => {
		let notAttachedFiles = attachedChatFileIds
			.filter(
				(fileId) =>
					!attachedFilesToIds(editorContext.storeRef.current?.editorFiles).includes(fileId),
			)
			.map((id) => chatFiles.find((chatFile) => chatFile.id === id))
			.filter((attachedFile) => !!attachedFile) as FileData[]

		if (toolType === undefined) {
			editorContext.addEditorFiles(
				ATTACHED_CONTEXT_LABEL,
				notAttachedFiles.map(({ id }) => id),
			)
			return
		}

		const promptTemplate = promptsTemplates[toolType]
		for (const input of promptTemplate.inputs) {
			if (!input.dropzone) {
				continue
			}

			const acceptedFiles: FileData[] = []
			if (
				input.acceptFiles &&
				input.limit > attachedFilesToIds(editorContext.storeRef.current!.editorFiles).length
			) {
				for (const notAttachedFile of notAttachedFiles) {
					if (acceptedFiles.length >= input.limit) {
						break
					}
					if (Object.keys(input.acceptFiles).includes(notAttachedFile.type)) {
						acceptedFiles.push(notAttachedFile)
					}
				}
			}

			// add matched files to dropzone
			editorContext.addEditorFiles(
				input.id,
				acceptedFiles.map(({ id }) => id),
			)

			// remove used files
			notAttachedFiles = notAttachedFiles.filter(
				(file) => !acceptedFiles.some((usedFile) => usedFile.id === file.id),
			)
		}
	}

	const updateEditorContent = (inputId: string, inputValue: string) => {
		editorContext.setEditorContent(inputId, inputValue)
		checkMessageContent(inputValue)
	}

	const attachMessageFiles = async (inputId: string, filesToAttach: File[]) => {
		try {
			await attachFiles.attachFilesToEditor(inputId, filesToAttach)
		} catch (err: any) {
			message.open({
				type: 'info',
				content: err.message,
			})
		}
	}

	const checkMessageValidation = (content: EditorContent, files: MessageAttachedFileIds) => {
		if (toolType === undefined) {
			if (content[DEFAULT_INPUT_ID]?.trim().length > 1 || files[ATTACHED_CONTEXT_LABEL]?.length) {
				editorContext.setCanSubmit(true)
				editorContext.setValidationCode(undefined)
			} else {
				editorContext.setValidationCode('EDIT-1')
				editorContext.setCanSubmit(false)
			}
			return
		}

		const promptTemplate = promptsTemplates[toolType]

		for (const input of promptTemplate.inputs) {
			if (!input.required) {
				continue
			}

			if (!input.dropzone) {
				if (!content[input.id] || content[input.id].trim().length <= 1) {
					editorContext.setValidationCode('EDIT-2')
					editorContext.setCanSubmit(false)
					return
				}
			} else {
				if (!files[input.id]?.length) {
					editorContext.setValidationCode('EDIT-3')
					editorContext.setCanSubmit(false)
					return
				}
			}
		}

		editorContext.setCanSubmit(true)
		editorContext.setValidationCode(undefined)
	}

	const checkMessageContent = (message: string) => {
		if (isAssistant || message.length < 10) {
			return
		}

		const textPerLine = message.split('\n**').map((text) => styledMessageToRawText(text).trimEnd())
		const toolName = textPerLine[0].split(': ')[1]

		if (!toolName || toolName.length < 5) {
			return // invalid tool name
		}

		const promptsTemplatesValues = Object.values(promptsTemplates)

		const toolTemplateIndex = promptsTemplatesValues.findIndex(
			({ name }) => name === toolName.trim(),
		)
		if (toolTemplateIndex === -1) {
			return // missing tool template
		}

		const toolKey = Object.keys(promptsTemplates)[toolTemplateIndex] as TemplateTool
		const toolTemplate = promptsTemplatesValues[toolTemplateIndex] as PromptTemplate

		if (textPerLine.length === 1) {
			editorContext.setToolType(toolKey)
			return
		}

		const initEditorContent: EditorContent = {}
		for (const templateInput of toolTemplate.inputs) {
			const findTextLine = textPerLine.find(
				(text) => text.split(':')[0].trim() === templateInput.text,
			)
			if (findTextLine) {
				const inputValuesWithTemplateText = findTextLine.split(': ')
				const inputValue = inputValuesWithTemplateText.slice(1).join(' ')
				if (inputValue.length) {
					initEditorContent[templateInput.id] = inputValue
				}
			}
		}

		editorContext.setToolType(toolKey, initEditorContent)

		if (toolKey === toolType) {
			attachMessageFilesToEditor()
		}
	}

	const setupTextareaHeight = (textarea: HTMLTextAreaElement) => {
		textarea.style['height'] = 'auto'
		textarea.style['height'] = `${textarea.scrollHeight + 1}px`
	}

	const checkTextareaSize = (editor: HTMLDivElement) => {
		const editorChildren = editor.children
		let contentHeight = 0

		if (editorChildren[0].tagName === 'TEXTAREA') {
			setupTextareaHeight(editorChildren[0] as HTMLTextAreaElement)
			contentHeight += editorChildren[0].scrollHeight
		}

		for (const templateContainer of editorChildren) {
			if (templateContainer.children[1]?.tagName === 'TEXTAREA') {
				setupTextareaHeight(templateContainer.children[1] as HTMLTextAreaElement)
				contentHeight += templateContainer.children[1].scrollHeight
			}
		}

		if (contentHeight >= MAX_CHAT_FOOTER_HEIGHT - MIN_CHAT_FOOTER_HEIGHT) {
			editor.style.maxHeight = `${MAX_CHAT_FOOTER_HEIGHT}px`
		}

		if (contentHeight < editor.scrollHeight) {
			contentHeight = editor.scrollHeight
		}
		editorContext.setEditorHeight(contentHeight)
	}

	const getToolTextarea = (
		inputId: string,
		placeholder: string,
		limit: number,
		isContextTextarea?: boolean,
	) => {
		const getEditorRightSpace = () => {
			if (isContextTextarea) {
				if (isAssistant) {
					return ASSISTANT_CONTEXT_EDITOR_SPACE
				}
				return CHAT_CONTEXT_EDITOR_SPACE
			}
			return 0
		}

		return (
			<TextArea
				maxLength={limit}
				autoSize={false}
				rows={1}
				style={{ paddingRight: getEditorRightSpace() }}
				className={styles.textarea}
				value={editorContent[inputId]}
				onChange={(e) => updateEditorContent(inputId, e.target.value)}
				placeholder={placeholder}
			/>
		)
	}

	const getToolDropzone = (inputId: string, placeholder: string, acceptFiles?: Accept) => {
		const getContent = () => {
			const editorFileIds = editorFiles[inputId] ? Object.values(editorFiles[inputId]) : []

			if (editorFileIds.length) {
				const filesData = chatFiles.filter((chatFile) => editorFileIds.includes(chatFile.id))
				return (
					<div className={styles.attachedFiles}>
						{filesData.map((fileData) => (
							<img
								key={fileData.id}
								className={styles.attachedFilePreview}
								src={fileData.url}
								alt={fileData.name}
							/>
						))}
					</div>
				)
			} else {
				return <div className={styles.templateDropzoneText}>{placeholder}</div>
			}
		}

		return (
			<div className={styles.templateDropzone}>
				<UploadFileWrapper
					acceptTypes={acceptFiles}
					onUpload={(files) => attachMessageFiles(inputId, files)}
				>
					{getContent()}
				</UploadFileWrapper>
			</div>
		)
	}

	return useMemo(() => {
		if (toolType === undefined) {
			return (
				<div ref={editorRef} className={styles.editor}>
					{getToolTextarea(DEFAULT_INPUT_ID, 'Enter a message', 5000, true)}
					<div
						className={`${styles.editorBorder} ${
							isAssistant ? styles.assistantBoardWidth : styles.chatBoardWidth
						}`}
					/>
				</div>
			)
		}

		const promptTemplate = promptsTemplates[toolType]

		const toolEditor: ReactNode = (
			<div ref={editorRef} className={styles.editor}>
				<div className={`${styles.templateText} ${styles.templateToolName}`}>
					Tool: {promptTemplate.name}
				</div>

				{promptTemplate.inputs.map((input) => (
					<div className={styles.templateTool} key={input.id}>
						<div className={styles.templateText}>
							{input.text}
							{input.required ? <span className={styles.required}>*</span> : <></>}:
						</div>
						{input.dropzone
							? getToolDropzone(input.id, input.placeholder, input.acceptFiles)
							: getToolTextarea(input.id, input.placeholder, input.limit)}
					</div>
				))}
			</div>
		)

		return toolEditor
	}, [toolType, editorFiles, editorContent])
}

export default MessageEditor
