import docxIcon from '../assets/fileFormats/DOCX.svg'
import pptxIcon from '../assets/fileFormats/PPTX.svg'
import xlsxIcon from '../assets/fileFormats/XSLX.svg'
import xlsIcon from '../assets/fileFormats/XSL.svg'
import pdfIcon from '../assets/fileFormats/PDF.svg'
import mdIcon from '../assets/fileFormats/MD.svg'
import htmlIcon from '../assets/fileFormats/HTML.svg'
import jsonIcon from '../assets/fileFormats/JSON.svg'
import txtIcon from '../assets/fileFormats/TXT.svg'
import csvIcon from '../assets/fileFormats/CSV.svg'
import pngIcon from '../assets/fileFormats/PNG.svg'
import jpegIcon from '../assets/fileFormats/JPEG.svg'
import gifIcon from '../assets/fileFormats/GIF.svg'
import mp3Icon from '../assets/fileFormats/MP3.svg'
import mp4Icon from '../assets/fileFormats/MP4.svg'

export type FileFormatData = {
	icon: any
	extension: string
}

const fileFormatMapper = (fileFormat: string | undefined): FileFormatData | undefined => {
	switch (fileFormat) {
		case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
			return {
				icon: docxIcon,
				extension: '.docx',
			}
		case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
			return {
				icon: pptxIcon,
				extension: '.pptx',
			}
		case 'application/vnd.ms-excel':
			return {
				icon: xlsIcon,
				extension: '.xls',
			}
		case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
			return {
				icon: xlsxIcon,
				extension: '.xlsx',
			}
		case 'text/csv':
			return {
				icon: csvIcon,
				extension: '.csv',
			}
		case 'application/pdf':
			return {
				icon: pdfIcon,
				extension: '.pdf',
			}
		case 'text/markdown':
			return {
				icon: mdIcon,
				extension: '.md',
			}
		case 'application/xhtml+xml':
		case 'text/html':
			return {
				icon: htmlIcon,
				extension: '.html',
			}
		case 'text/plain':
			return {
				icon: txtIcon,
				extension: '.txt',
			}
		case 'application/json':
			return {
				icon: jsonIcon,
				extension: '.JSON',
			}
		case 'image/png':
			return {
				icon: pngIcon,
				extension: '.png',
			}
		case 'image/jpeg':
			return {
				icon: jpegIcon,
				extension: '.jpeg',
			}
		case 'image/gif':
			return {
				icon: gifIcon,
				extension: '.gif',
			}
		case 'audio/mpeg':
			return {
				icon: mp3Icon,
				extension: '.mp3',
			}
		case 'video/mp4':
			return {
				icon: mp4Icon,
				extension: '.mp4',
			}
		default:
			return undefined
	}
}

export default fileFormatMapper
