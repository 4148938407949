import { Button, Tooltip } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import { ReactNode } from 'react'
import styles from './CardTitle.module.scss'

type Props = {
	id?: number | string
	name: string | ReactNode
	headerIcon?: string
	headerButton?: {
		text: string
		onClick: (id: any) => void
		tooltip?: string
		size?: 'small' | 'middle' | 'large'
		className?: string
		disabled?: boolean
	}
	disabled?: boolean
	size?: 'default' | 'large'
	onDelete?: (id: any) => void
}

const CardTitle = ({ id, name, headerIcon, headerButton, size, disabled, onDelete }: Props) => {
	const getHeaderButton = (): ReactNode => {
		if (!headerButton) {
			return <></>
		}

		const headerButtonContent = (
			<Button
				className={`${styles.headerButton} ${headerButton.className ?? ''}`}
				size={headerButton.size ?? 'small'}
				disabled={headerButton.disabled || disabled}
				onClick={(e) => {
					e.stopPropagation()
					headerButton.onClick(id)
				}}
			>
				{headerButton.text}
			</Button>
		)

		if (headerButton.tooltip) {
			return (
				<Tooltip trigger={['hover', 'focus']} mouseEnterDelay={0.3} title={headerButton.tooltip}>
					{headerButtonContent}
				</Tooltip>
			)
		}

		return headerButtonContent
	}

	return (
		<div>
			{!!headerIcon && (
				<div className={styles.logoIconWrapper}>
					<img className={styles.logoIcon} src={headerIcon} alt='Header icon' />
				</div>
			)}

			<div className={`${styles.cardTitle} ${headerIcon ? styles.titleMargin : ''}`}>
				<div className={`${styles.title} ${size === 'large' ? styles.largeTitle : ''}`}>
					<Tooltip placement='topLeft' mouseEnterDelay={0.3} title={name}>
						{name}
					</Tooltip>
				</div>
				<div className={styles.buttons}>
					{!!headerButton && getHeaderButton()}
					{!!onDelete && (
						<Button
							className={styles.deleteButton}
							type='text'
							disabled={disabled}
							onClick={(e) => {
								e.stopPropagation()
								onDelete(id)
							}}
						>
							<DeleteOutlined />
						</Button>
					)}
				</div>
			</div>
		</div>
	)
}

export default CardTitle
