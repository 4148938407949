import { Button, Form, Input } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import { AssistantRole } from '@aiapp/types/assistant/assistantRole'
import { useEffect } from 'react'
import Joi from 'joi'
import { prepareAssistantData } from '../../Role'
import { useAssistantRoleContext } from '../../../../../../hooks/context/AssistantRoleContext'
import { formValidate } from '../../../../../../utils/validation'
import RoleHeader from '../../components/RoleHeader/RoleHeader'
import { useParams } from 'react-router-dom'
import { prepareRoleFormValues } from '../../utils'
import RedirectConditions from '../../components/Forms/RedirectConditions'
import ConversationConfig from '../../components/Forms/ConversationConfig'
import EmojiConfig from '../../components/Forms/EmojiConfig'
import NaturalizationBooster from '../../components/Forms/NaturalizationBooster'
import FormHeading from '../../components/FormHeading/FormHeading'
import AdditionalInformation from '../../components/Forms/AdditionalInformation'
import SystemName from '../../components/Forms/SystemName'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { createInternalAssistantRoleSchema } from '@aiapp/schemas/assistant/roles/internalAssistant'
import FormRow from '../../../../../../components/FormRow/FormRow'
import { roles } from '../../../../../../utils/roles'
import styles from './InternalAssistant.module.scss'

const DEFAULT_INTERNAL_ASSISTANT_DATA: Partial<AssistantRole> = {
	type: 'internalAssistant',
	disabledEmoji: false,
	conversationStyle: 'balanced',
	responseLength: 'balanced',
	naturalizationBooster: false,
}

const InternalAssistantRoleForm = () => {
	const [form] = Form.useForm()

	const { assistantRoleId } = useParams()

	const assistantRoleContext = useAssistantRoleContext()
	const assistantRoleData = assistantRoleContext.useSubscribe((context) => context.data)

	useEffect(() => {
		if (!assistantRoleId) {
			assistantRoleContext.updateData(DEFAULT_INTERNAL_ASSISTANT_DATA)
		}
	}, [assistantRoleId])

	useEffect(() => {
		checkFormValidate()
	}, [assistantRoleData])

	useEffect(() => {
		form.resetFields()
	}, [assistantRoleData.id, assistantRoleData.type])

	const updateAssistantRoleData = (value: Record<string, any>, values: any) => {
		prepareRoleFormValues(value, values)
		assistantRoleContext.updateData(values)
	}

	const checkFormValidate = () => {
		const validate = formValidate(prepareAssistantData(assistantRoleData), assistantRoleSchema)
		assistantRoleContext.setValidStep(validate ? !Object.keys(validate).length : true)
	}

	const roleInfo = roles.flat().find((role) => role.key === 'internalAssistant')!

	return (
		<Form
			form={form}
			autoComplete='true'
			initialValues={assistantRoleData}
			onValuesChange={updateAssistantRoleData}
			labelCol={{ span: 4 }}
			wrapperCol={{ span: 14 }}
			className={styles.form}
			layout='vertical'
		>
			<RoleHeader
				avatar={roleInfo.avatar}
				name={roleInfo.name}
				description={roleInfo.description}
			/>

			<FormHeading title='Base information' />

			<SystemName />

			<Form.Item
				rules={[
					{
						message: 'Company details can have a maximum of 3000 characters',
						max: 3000,
					},
				]}
				className={styles.formItem}
				label='Company details'
				name='companyDetails'
			>
				<TextArea
					className={styles.formTextarea}
					size='large'
					allowClear
					count={{
						show: true,
					}}
					maxLength={3000}
					autoSize={{ minRows: 2, maxRows: 30 }}
					placeholder='Company details'
				/>
			</Form.Item>

			<AdditionalInformation />

			<FormRow className={styles.internalDataRow}>
				<Form.Item
					required
					tooltip='Enter the internal numbers of employees who will contact Persona after whatsapp.'
					className={styles.formItem}
					label='Internal phone numbers'
				>
					<Form.List initialValue={['']} name='internalPhoneNumbers'>
						{(fields, { add, remove }) => (
							<>
								{fields.map(({ key, name, ...restField }, index) => (
									<div key={key} className={styles.fieldList}>
										<Form.Item
											{...restField}
											rules={[
												{ required: true, message: 'Please fill internal phone number' },
												{
													message: 'Phone number must have a minimum of 9 characters',
													min: 9,
												},
												{
													message: 'Phone number can have a maximum of 20 characters',
													max: 20,
												},
											]}
											className={styles.formItem}
											name={[name]}
										>
											<Input
												maxLength={20}
												size='large'
												className={styles.formInput}
												placeholder='+48100200300'
											/>
										</Form.Item>

										{index !== 0 && (
											<MinusCircleOutlined
												className={styles.listRemoveIcon}
												onClick={() => remove(name)}
											/>
										)}
									</div>
								))}
								<Form.Item className={styles.formItem}>
									<Button
										size='large'
										type='dashed'
										disabled={fields.length === 5}
										onClick={() => {
											fields.length < 5 && add()
										}}
										block
										icon={<PlusOutlined />}
									>
										Add new internal number
									</Button>
								</Form.Item>
							</>
						)}
					</Form.List>
				</Form.Item>

				<Form.Item
					required
					tooltip='Enter the internal e-mail addresses of employees who will contact Persona by e-mail.'
					className={styles.formItem}
					label='Internal e-mail addresses'
				>
					<Form.List initialValue={['']} name='internalEmails'>
						{(fields, { add, remove }) => (
							<>
								{fields.map(({ key, name, ...restField }, index) => (
									<div key={key} className={styles.fieldList}>
										<Form.Item
											{...restField}
											rules={[
												{ required: true, message: 'Please fill internal e-mail address' },
												{ type: 'email', message: 'Invalid e-mail address format' },
												{ message: 'Email address must have a minimum of 3 characters', min: 3 },
												{
													message: 'Email address can have a maximum of 250 characters',
													max: 250,
												},
											]}
											className={styles.formItem}
											name={[name]}
										>
											<Input
												maxLength={250}
												size='large'
												placeholder='employee@quantumneuron.eu'
												className={styles.formInput}
											/>
										</Form.Item>

										{index !== 0 && (
											<MinusCircleOutlined
												className={styles.listRemoveIcon}
												onClick={() => remove(name)}
											/>
										)}
									</div>
								))}
								<Form.Item className={styles.formItem}>
									<Button
										size='large'
										type='dashed'
										disabled={fields.length === 5}
										onClick={() => {
											fields.length < 5 && add()
										}}
										block
										icon={<PlusOutlined />}
									>
										Add new internal e-mail
									</Button>
								</Form.Item>
							</>
						)}
					</Form.List>
				</Form.Item>
			</FormRow>

			<EmojiConfig disabledEmoji={assistantRoleData.disabledEmoji} />

			<FormHeading title='Role settings' />

			<ConversationConfig />

			<FormHeading title='Advanced settings' />

			<NaturalizationBooster />
			<RedirectConditions />
		</Form>
	)
}

const assistantRoleSchema = Joi.object({
	...createInternalAssistantRoleSchema,
	id: Joi.any().optional(),
}).required()

export default InternalAssistantRoleForm
