import { Form, Input } from 'antd'
import styles from './Forms.module.scss'

const SystemName = () => {
	return (
		<Form.Item
			rules={[
				{ required: true, message: 'Please fill system name' },
				{ message: 'System name must have a minimum of 3 characters', min: 3 },
				{ message: 'System name can have a maximum of 120 characters', max: 120 },
			]}
			className={styles.formItem}
			label='System name'
			name='name'
		>
			<Input
				count={{
					show: true,
				}}
				maxLength={120}
				size='large'
				className={styles.formInput}
				placeholder='Persona role'
			/>
		</Form.Item>
	)
}

export default SystemName
