import { FileData } from '../../../../../../../types/ChatContext'
import React, { useMemo, useState } from 'react'
import { Image, Space } from 'antd'
import {
	DownloadOutlined,
	LeftOutlined,
	RightOutlined,
	RotateLeftOutlined,
	RotateRightOutlined,
	SwapOutlined,
	ZoomInOutlined,
	ZoomOutOutlined,
} from '@ant-design/icons'
import { downloadFileFromUrl } from '../../../../../../../utils/helpers'
import { useChatContext } from '../../../../../../../hooks/context/ChatContext'
import styles from './ImageContent.module.scss'

type Props = {
	fileData: FileData
	scrollToBottom: () => void
}

const ImageContent = ({ fileData, scrollToBottom }: Props) => {
	const chatContext = useChatContext()
	const chatFiles = chatContext.useSubscribe((context) => context.chatFiles)

	const chatImages = chatFiles.filter((chatFile) => chatFile.type.split('/')[0] === 'image')
	const defaultIndex = chatImages.findIndex((chatImage) => chatImage.url === fileData.url)

	const [currentIndex, setCurrentIndex] = useState<number>(defaultIndex)

	const nextImage = () => {
		if (chatImages[currentIndex + 1]) {
			setCurrentIndex((index) => index + 1)
		} else {
			setCurrentIndex(0)
		}
	}

	const prevImage = () => {
		if (chatImages[currentIndex - 1]) {
			setCurrentIndex((index) => index - 1)
		} else {
			setCurrentIndex(chatImages.length - 1)
		}
	}

	return useMemo(
		() => (
			<div className={styles.imageFile}>
				<Image.PreviewGroup
					items={chatImages.map((chatImage) => chatImage.url)}
					preview={{
						countRender: () => <></>,
						current: currentIndex,
						onVisibleChange: (value: boolean) => {
							if (!value) {
								setCurrentIndex(defaultIndex)
							}
						},
						onChange: (current: number) => setCurrentIndex(current),
						imageRender: (originalNode: React.ReactElement) => (
							<div className={styles.imageRender}>
								<LeftOutlined
									onClick={prevImage}
									className={`${styles.imageArrow} ${styles.left}`}
								/>
								{originalNode}
								<RightOutlined
									onClick={nextImage}
									className={`${styles.imageArrow} ${styles.right}`}
								/>
							</div>
						),
						toolbarRender: (
							_,
							{
								transform: { scale },
								actions: { onFlipY, onFlipX, onRotateLeft, onRotateRight, onZoomOut, onZoomIn },
							},
						) => (
							<Space size={12} className={styles.imageToolbarWrapper}>
								<DownloadOutlined
									className={styles.icon}
									onClick={() => downloadFileFromUrl(fileData.url, fileData.name)}
								/>
								<SwapOutlined className={styles.icon} rotate={90} onClick={onFlipY} />
								<SwapOutlined className={styles.icon} onClick={onFlipX} />
								<RotateLeftOutlined className={styles.icon} onClick={onRotateLeft} />
								<RotateRightOutlined className={styles.icon} onClick={onRotateRight} />
								<ZoomOutOutlined
									className={styles.icon}
									disabled={scale === 1}
									onClick={onZoomOut}
								/>
								<ZoomInOutlined
									className={styles.icon}
									disabled={scale === 50}
									onClick={onZoomIn}
								/>
							</Space>
						),
					}}
				>
					<Image className={styles.image} onLoad={() => scrollToBottom()} src={fileData.url} />
				</Image.PreviewGroup>
			</div>
		),
		[chatImages, currentIndex],
	)
}

export default ImageContent
